import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { OrdersSuccessful, OrdersFailed, OrderActionTypes } from '../actions/Orders.actions';
import { TradeService } from 'src/app/_services/trade.service';

@Injectable()
export class OrdersEffects {
  @Effect()
  loadOrders$ = this.dataPersistence.fetch(OrderActionTypes.Orders_Loading, {
    run: (action: any, state) => {
      return this._service.getAllOrderDetails(action.payload).pipe(
        map((res: any) => {
          if (res.data && res.data.result) {
            return new OrdersSuccessful(res.data.result);
          }
          return new OrdersFailed();
        })
      );
    },
    onError: (action, error) => {
      return new OrdersFailed();
    },
  });
  constructor(private dataPersistence: DataPersistence<any>, private _service: TradeService) {}
}
