import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init } from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

init({
  dsn: 'https://4c56a13aa8ea4acb825f4afd66482240@o1290829.ingest.sentry.io/6730235',
  environment: environment.sentry_env,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://dev1-trader.ninjacart.in/dummy',
        'https://qa.ninjacart.in/dummy',
        'https://freewheel.ninjacart.in/dummy',
        'https://traders.ninjacart.in/trader',
        'https://traders.trafyn.site/trader',
      ],
      beforeNavigate: (context) => context,
    }),
  ],
  tracesSampleRate: environment.production ? 0.2 : 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
