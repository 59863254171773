import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'iframe-track',
  templateUrl: './iframe.component.html',
})
export class IFrameComponent {
  ninjaTrackIFrameUrl: SafeResourceUrl;
  resource: any;

  constructor(private sanitize: DomSanitizer, private _translate: TranslateService, public dialog: MatDialog) {
    this._translate.get('resource')?.subscribe((resource) => (this.resource = resource));
  }

  ngOnInit() {
    let url = `${environment.ninjaTrack.url}`;
    this.ninjaTrackIFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(url);
  }
}
