import { DashboardEffects } from '../effetcs/Dashboard.effects';
import { PartiesEffects } from '../effetcs/parties.effects';
import { OrdersEffects } from './Orders.effects';
import { AddMoneyEffects } from './add-money.effects';
import { WithdrawEffects } from './withdraw.effects';
import { TransactionEffects } from './transactions.effects';
import { AmountEffects } from './amount.effects';
export const Effects = [
  DashboardEffects,
  PartiesEffects,
  OrdersEffects,
  AddMoneyEffects,
  WithdrawEffects,
  TransactionEffects,
  AmountEffects,
];
