import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaTextViewComponent } from './ninja-text-view.component';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaTextViewComponent],
  imports: [
    CommonModule
  ],
  exports: [NinjaTextViewComponent]
})
export class NinjaTextViewModule { }
