import { Injectable } from '@angular/core';
import { NinjaAlertCustomClass, NinjaAlertOptions, NinjaNotificationOptions } from './ninja-notification.modal';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NinjaNotificationService {

  constructor() { }

  // mobileAlert(message: string | NinjaNotificationOptions, options?: NinjaNotificationOptions) {
  //   return ons.notification.alert(message, options);
  // }

  // mobileConfirm(message: string | NinjaNotificationOptions, options?: NinjaNotificationOptions) {
  //   return ons.notification.confirm(message, options);
  // }

  // mobilePrompt(message: string | NinjaNotificationOptions, options?: NinjaNotificationOptions) {
  //   return ons.notification.prompt(message, options);
  // }

  // mobileToast(message: string, args?: { timeout: number }) {
  //   return ons.notification.toast(message, args);
  // }

  get instance() {
    return Swal;
  }

  fire(options: NinjaAlertOptions, applyNinjaCss: boolean = false) {
    if (applyNinjaCss) {
      options.customClass = {
        title: NinjaAlertCustomClass.title,
        content: NinjaAlertCustomClass.content,
        actions: NinjaAlertCustomClass.actions,
        confirmButton: NinjaAlertCustomClass.confirmButton,
        cancelButton: NinjaAlertCustomClass.cancelButton,
        denyButton: NinjaAlertCustomClass.denyButton,
        icon: NinjaAlertCustomClass.icon
      };
      switch (options.type) {
        case 'success': {
          options.customClass.header = NinjaAlertCustomClass.successHeader;
          break;
        }
        case 'failure': {
          options.customClass.header = NinjaAlertCustomClass.failureHeader;
          break;
        }
        case 'warning': {
          options.customClass.header = NinjaAlertCustomClass.warningHeader;
          break;
        }
        default: {
          options.customClass.header = NinjaAlertCustomClass.successHeader;
          break;
        }
      }
    }
    return Swal.fire(options);
  }

  toast(options: NinjaAlertOptions) {
    options.toast = true;
    options.customClass = { title: 'ninja-toast-title' };
    const Toast = Swal.mixin(options);
    return Toast.fire();
  }
}
