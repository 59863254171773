import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NinjaTabbarVM } from './ninja-tabbar.modal';
import { NinjaTabbarService } from './ninja-tabbar.service';
@Component({
  selector: 'ninja-tabbar',
  templateUrl: './ninja-tabbar.component.html',
  styleUrls: ['./ninja-tabbar.component.scss']
})
export class NinjaTabbarComponent implements OnInit {

  $active;
  @Input() name;
  @Input() tabList: { text: string, active: boolean }[] = [];
  @Input() showBorder: boolean = true;
  @Input() rounded: boolean = false;
  @Input() containerCss;
  @Input() tabCss;
  @Input() highlightCss;
  @Input() textCss;
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(private ninjaTabbarService: NinjaTabbarService) { }

  ngOnInit(): void {
    this.$active = this.ninjaTabbarService.activeSubject.subscribe(res => {
      this.tabList.forEach(m => m.active = m.text === res.text);
    });
  }

  setActive(tab: NinjaTabbarVM) {
    this.tabList.forEach(m => m.active = m.text === tab.text);
  }

  ngOnDestroy() {
    this.$active?.unsubscribe();
  }

  changeTab(tab) {
    this.tabList.forEach(m => m.active = m.text === tab.text);
    this.change.emit(tab);
  }
}
