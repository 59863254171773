import { Injectable } from '@angular/core';
import { UserDetail } from '../models/user-detail.modal';

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  constructor() { }

  set userDetail(value: UserDetail) {
    window.localStorage.setItem('userDetail', JSON.stringify(value));
  }

  get userDetail(): UserDetail {
    return JSON.parse(window.localStorage.getItem('userDetail'));
  }

  setSampleJsonCustomerForm(value: any) {
    window.localStorage.setItem('sampleJsonCustomerForm', JSON.stringify(value));
  }

  getSampleJsonCustomerForm(): any {
    return JSON.parse(window.localStorage.getItem('sampleJsonCustomerForm'));
  }

  setSampleCustomerFormElementsList(value: any) {
    window.localStorage.setItem('sampleCustomerFormElementsList', JSON.stringify(value));
  }

  getSampleCustomerFormElementsList(): any {
    return JSON.parse(window.localStorage.getItem('sampleCustomerFormElementsList'));
  }

  setSampleJsonDriverForm(value: any) {
    window.localStorage.setItem('sampleJsonDriverForm', JSON.stringify(value));
  }

  getSampleJsonDriverForm(): any {
    return JSON.parse(window.localStorage.getItem('sampleJsonDriverForm'));
  }

  setSampleDriverFormElementsList(value: any) {
    window.localStorage.setItem('sampleDriverFormElementsList', JSON.stringify(value));
  }

  getSampleDriverFormElementsList(): any {
    return JSON.parse(window.localStorage.getItem('sampleDriverFormElementsList'));
  }

  setWizardPayload(value: any) {
    window.localStorage.setItem('wizardPayload', JSON.stringify(value));
  }

  getWizardPayload() {
    return JSON.parse(window.localStorage.getItem('wizardPayload'));
  }

  remove(key: string) {
    window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
  }
}
