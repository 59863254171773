import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { WorkflowService } from './workflow.service';
import { Observable } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DataFilterService {


  constructor(private workflow: WorkflowService) { }

  getFormControlKeyByBinding(bindingValue: string, formData: any): string {
    let result = bindingValue;
    if (bindingValue.includes('.')) {
      const key = bindingValue.split('.')[0];
      if (formData && formData[key]) {
        result = key;
      }
    }
    return result;
  }

  getformDataModalObjBinding(bindingValue: string, formDataModel: any) {
    let result;
    if (bindingValue?.includes('.')) {
      let splittedBinding = bindingValue.split('.');
      splittedBinding.splice(1, 0, 'properties');
      bindingValue = splittedBinding.join('.');
    }
    const modalProps = formDataModel?.modelDefinition?.properties;
    if (modalProps) {
      result = get(modalProps, bindingValue);
    }
    return result;
  }

  getFormElementPayloadFormat(formElementsList): any {
    let result = formElementsList.map(m => {
      return {
        name: m.name,
        version: m.version
      }
    });
    return result;
  }

  getFormElement(formElementsList): Observable<any> {
    let result = this.getFormElementPayloadFormat(formElementsList);
    return this.workflow.getFormElement(result);
  }

}
