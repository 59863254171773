import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaButtonComponent } from './ninja-button.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaButtonComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [NinjaButtonComponent]
})
export class NinjaButtonModule { }
