import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NinjaAutocompleteVM } from './ninja-autocomplete.modal';

@Component({
  selector: 'ninja-autocomplete',
  templateUrl: './ninja-autocomplete.component.html',
  styleUrls: ['./ninja-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NinjaAutocompleteComponent),
      multi: true
    }
  ]
})
export class NinjaAutocompleteComponent implements OnInit, ControlValueAccessor {

  @Input() id;
  @Input() cssClass;
  @Input() label: string = '';
  @Input() placeholder: string = 'Search here';
  @Input() required = false;
  @Input() disabled = false;
  @Input() options: NinjaAutocompleteVM[];
  @Input() multiple: boolean = false;
  @Input() showBorder: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  filteredOptions: NinjaAutocompleteVM[] = [];
  selectedOptions: NinjaAutocompleteVM[] = [];
  searchValue = '';
  showList: boolean = false;

  constructor() { }

  onChange: any = () => { };
  onTouched: any = () => { };
  value: any;

  ngOnInit(): void {

  }

  _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.options.filter(m => m.text.toLowerCase().includes(filterValue));
  }

  onSelectItem(o: NinjaAutocompleteVM) {
    this.searchValue = '';
    this.showList = false;
    if (this.multiple) {
      this.selectedOptions.push(o);
      this._filterBySelectedOptions();
      this.onChange(this.selectedOptions);
      this.change.emit(this.selectedOptions);
    } else {
      this.searchValue = o?.text;
      this.onChange(o);
      this.change.emit(o);
    }
  }

  onSearchInput(e) {
    this.filteredOptions = this._filter(e?.target?.value);
    if (this.multiple) {
      this._filterBySelectedOptions();
    }
  }

  _filterBySelectedOptions() {
    if (this.selectedOptions?.length > 0) {
      const texts = this.selectedOptions.map(m => m.text);
      this.filteredOptions = this.filteredOptions.filter(m => !texts.includes(m.text));
    }
  }

  clearSelectedOption(item: NinjaAutocompleteVM) {
    const index = this.selectedOptions.findIndex(m => m.text === item.text);
    this.selectedOptions.splice(index, 1);
    this.onChange(this.selectedOptions);
    this.change.emit(this.selectedOptions);
  }

  clearSearch() {
    this.searchValue = '';
    if (!this.multiple) {
      this.onChange(null);
      this.change.emit(null);
    }
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
