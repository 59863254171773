import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ninja-text-area',
  templateUrl: './ninja-text-area.component.html',
  styleUrls: ['./ninja-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NinjaTextAreaComponent),
      multi: true
    }
  ]
})
export class NinjaTextAreaComponent implements OnInit, ControlValueAccessor {

  @Input() id;
  @Input() cssClass;
  @Input() label = '';
  @Input() cssProperties;
  @Input() placeholder = '';
  @Input() rows;
  @Input() cols;
  @Input() showCross = false;
  @Input() disabled = false;
  @Input() crossCssProperties;
  @Input() required = false;
  @Output('change') change: EventEmitter<any> = new EventEmitter();
  crossCssProps = {
    "font-size": "24px",
    "position": "absolute",
    "top": "6.5vw",
    "right": "8vw",
    "color": "#569b40"
  };
  onChange: any = () => { };
  onTouched: any = () => { };
  value: any;

  constructor() { }

  ngOnInit(): void {
    if (this.showCross) {
      this.crossCssProps = { ...this.crossCssProps, ...this.crossCssProperties };
    }
  }

  inputValue(e) {
    this.onChange(e.target.value);
    this.change.emit({
      event: e,
      value: e.target.value
    });
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
