import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { AuthActionsTypes } from '../actions/auth.actions';
import { AppState } from './index';

export const hydrationMetaReducer = (reducer: ActionReducer<AppState>): ActionReducer<AppState> => {
  return (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = localStorage.getItem('state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue);
        } catch {
          localStorage.removeItem('state');
        }
      }
    } else if (action.type === AuthActionsTypes.Logout) {
      const state = undefined;
      const nextState = reducer(state, action);
      localStorage.setItem('state', JSON.stringify(nextState));
      return nextState;
    }
    const nextState = reducer(state, action);
    localStorage.setItem('state', JSON.stringify(nextState));
    return nextState;
  };
};
