import { Action } from '@ngrx/store';

export enum AddTradeParentActionTypes {
  SetParentPage = 'SetParentPage',
}

export class SetParentPage implements Action {
  readonly type = AddTradeParentActionTypes.SetParentPage;
  constructor(public parentPage: string) {}
}

export type AddTradeParentActions = SetParentPage;
