import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NinjaTabbarVM } from './ninja-tabbar.modal';

@Injectable({
  providedIn: 'root'
})
export class NinjaTabbarService {

  activeSubject: Subject<NinjaTabbarVM> = new Subject<NinjaTabbarVM>();

  constructor() { }

  setActive(tab: NinjaTabbarVM) {
    this.activeSubject.next(tab);
  }

}
