import { TransactionActionTypes } from '../actions/transactions.actions';

const transactionsHistory: any = {
  isLoading: false,
  isSuccessful: false,
  isFailed: false,
  data: null,
};

export const initialState: any = {
  transactionsHistory,
  selectedTransaction: null,
  reloadTransactions: false,
};

export const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TransactionActionTypes.Get_Transactions:
      return {
        ...state,
        transactionsHistory: {
          isLoading: true,
          isSuccessful: false,
          isFailed: false,
          data: null,
        },
        reloadTransactions: false,
      };
    case TransactionActionTypes.Transactions_Successful:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: true,
          isFailed: false,
          data: action.payload,
        },
        reloadTransactions: false,
      };
    case TransactionActionTypes.Transactions_Failed:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: false,
          isFailed: true,
          data: null,
        },
        reloadTransactions: false,
      };
    case TransactionActionTypes.Set_Selected_Transaction:
      return {
        ...state,
        selectedTransaction: action.payload,
      };
    case TransactionActionTypes.Reload_Transactions:
      return {
        ...state,
        reloadTransactions: true,
      };
    default:
      return state;
  }
};
