import { HttpClient, HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { StorageService } from 'src/app/_handlers/storage.service';
import { Injectable } from '@angular/core';
import { HttpApiResponseVM } from 'src/app/_modals/http-api-response';
import { NinjaNotificationService, NinjaSpinnerService } from 'ninja-modules';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from './error-handling.service';
@Injectable({
  providedIn: 'root',
})
export class WithdrawMoneyService {
  resource: any;
  private workflow = environment.apiUrl.workflow;
  private vanUrl = environment.apiUrl.payment_van;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private storage: StorageService,
    private handler: HttpBackend,
    private spinner: NinjaSpinnerService,
    private notify: NinjaNotificationService,
    private error: ErrorHandlingService
  ) {}

  withDrawMoney(payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/payments/withdraw`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  withDrawMoneyVerification(payload): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let withdrawOTPVerifyHttp: HttpClient = new HttpClient(this.handler);
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/withdraw-amount`;
    return withdrawOTPVerifyHttp
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  getUserCurrentAccount() {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/current-account-fetch`;
    return this.http.post(url, {}).pipe(map((m: any) => m));
  }
  getTransactionStatus(payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.vanUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/collections/provider/fetchAndUpdateTransactionStatus`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  async catchError(error: HttpErrorResponse | any) {
    // this.resource = await this.getResourceAsync();
    // this.spinner.end();
    // const err = error?.error;
    // let text = this.resource?.somethingWentWrongNote;
    // if (err?.code && err?.message) { text = err?.message }
    // if (err?.errorCode && err?.errorMessage) { text = err?.errorMessage }
    // this.error(this.resource?.errorMsg, text);
    // return EMPTY;
    this.error.catchErrorHandling(error);
  }

  // async error(title?: string, text?: string) {
  //   this.resource = await this.getResourceAsync();
  //   let config: any = {
  //     icon: 'error',
  //     type: 'failure',
  //     title,
  //     text,
  //     confirmButtonText: this.resource?.ok
  //   }
  //   return this.notify.fire(config);
  // }
  async getResourceAsync() {
    return await this.translate.get('resource').toPromise();
  }
}
