import { environment } from 'src/environments/environment';

const QA_CONFIG = [
  1650732, 1646655, 1646685, 1647540, 1649473, 1647030, 1647348, 1648077, 1648029, 1650777, 1100, 1520928, 1520945,
  1650849, 1648796, 1647725,
];
const PROD_CONFIG = [
  1337149, 1712268, 1636188, 1756199, 1776954, 1752613, 1100, 1789169, 1797837, 1768016, 1768360, 1593550, 1731663,
  1786386, 1727482, 1753126, 1796269, 1767969, 1753035, 1649317, 1221695, 1736394, 1744697, 1746783, 1749358, 1752131,
  1758187, 1763974, 1766670, 1768363, 1769414, 1772059, 1772711, 1773564, 1773973, 1775558, 1776890, 1777079, 1778512,
  1796364, 1802606, 1805976, 1736426, 1740085, 1746405, 1750877, 1758579, 1760296, 1762928, 1764196, 1731982, 1763934,
  1768401, 1800079, 1732894, 1734377, 1734856, 1735930, 1758153, 1763630, 1765117, 1766366, 1771320, 1773342, 1775301,
  1802449, 1806809, 1761208, 1424596, 1541588, 1732658, 1735978, 1736728, 1736735, 1743213, 1750828, 1758021, 1764082,
  1764479, 1764698, 1768041, 1768713, 1768784, 1771084, 1772066, 1774831, 1790111, 1792058, 1797317, 1800221, 1801838,
  1750844, 1755215, 1758462, 1751520, 1751633, 1752972, 1756290, 1764664, 1765390, 1766152, 1766505, 1766515, 1766934,
  1767890, 1768625, 1768653, 1768807, 1768999, 1769145, 1769616, 1770088, 1770457, 1770575, 1771049, 1771382, 1771478,
  1773119, 1774169, 1775961, 1776328, 1777639, 1778615, 1781343, 1782452, 1790850, 1797883, 1799971, 1803744, 1746041,
  1748318, 1759397, 1762369, 1764247, 1726743, 1736758, 1737253, 1737299, 1743608, 1749955, 1750434, 1751619, 1780556,
  1787791, 1765624, 1766862, 1807848, 209730, 1754042, 1758463, 1787608, 1758545, 1759028, 1759134, 1771615, 1782054,
  1786994, 1802493, 1399479, 1729437, 1736837, 1737060, 1757678, 1760691, 1778296, 1775979, 1810591, 1805903, 1805978,
  1805994, 1807344, 1805941, 1806477, 1807062, 1806024, 1806634, 1806898, 1809884, 1804750, 1806912, 1806637, 1805969,
  1808926, 1809307, 1809331, 1810221, 1806444, 1807321, 1811109, 1810614, 1809373, 1809956, 1810019, 1810599, 1810047,
  1811876, 1809574, 1810579, 1811065, 1811085, 1811124, 1811284, 1813367, 1805964, 1809338, 1809962, 1810604, 1809997,
  1806008, 1806867, 1806410, 1809416, 1810025, 1810061, 1810527, 1810624, 1809364, 1809950, 1811087, 1809571, 1811371,
  1813256, 1808952, 1810477, 1805933, 1807238, 1811816, 1811915, 1806423, 1809045, 1809848, 1806813, 1807161, 1808821,
  1810686, 1810767, 1808908, 1809623, 1811008, 1811137, 1811212, 1811357, 1809388, 1810874, 1810192, 1810838, 1811350,
  1813309, 1806011, 1811248, 1811387, 1811919, 1806324, 1806663, 1806846, 1807180, 1806364, 1809423, 1811303, 1805881,
  1810861, 1810240, 1809925, 1810710, 1806656, 1806338, 1806935, 1806944, 1805923, 1805972, 1811397, 1810867, 1810487,
  1812168, 1812570, 1812663, 1810572, 1810746, 1811208, 1808676, 1813326,
];

export const ReleasePGToAll = true;
export const PG_ENABLED_USERS = environment.production ? PROD_CONFIG : QA_CONFIG;
