import { Action } from '@ngrx/store';
import { Order } from '../reducers/Orders.reducer';

export enum OrderActionTypes {
  Orders_Loading = 'Orders_Loading',
  Orders_Successful = 'Orders_Successful',
  Orders_Failed = 'Orders_Failed',
  Selected_Order = 'Selected_Order',
  ReloadOrders = 'Reload_Orders',
}

export class LoadOrders implements Action {
  readonly type = OrderActionTypes.Orders_Loading;
  constructor(public payload: any) {}
}

export class OrdersSuccessful implements Action {
  readonly type = OrderActionTypes.Orders_Successful;
  constructor(public payload: any) {}
}

export class OrdersFailed implements Action {
  readonly type = OrderActionTypes.Orders_Failed;
  constructor() {}
}

export class SelectedOrder implements Action {
  readonly type = OrderActionTypes.Selected_Order;
  constructor(public payload: any) {}
}

export class ReloadOrders implements Action {
  readonly type = OrderActionTypes.ReloadOrders;
  constructor() {}
}

export type OrderActions = LoadOrders | OrdersSuccessful | OrdersFailed | SelectedOrder | ReloadOrders;
