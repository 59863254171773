import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ninja-contact-picker',
  templateUrl: './ninja-contact-picker.component.html',
  styleUrls: ['./ninja-contact-picker.component.css']
})
export class NinjaContactPickerComponent implements OnInit {

  @ViewChild('searchText') searchText; // accessing the reference element

  selectedContactList = null;
  // contactList = [
  //   {
  //     "name": "Sheriff",
  //     "contactNumber": "9629601733"
  //   },
  //   {
  //     "name": "Kiran",
  //     "contactNumber": "9629601733"
  //   }
  // ];
  contactList = [];
  searchedContactList = [];

  constructor() { }

  ngOnInit(): void {
    this.contactList = JSON.parse(window.localStorage.getItem("ninja-trader-contact-list"));
  }

  getSelectedContact(contact) {
    this.selectedContactList = contact;
  }

  onKeyName(event) {
    this.searchedContactList = [];
    if (event.target.value != "") {
      for (let i = 0; i < this.contactList.length; i++) {
        if (this.contactList[i].name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 || this.contactList[i].contactNumber.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) {
          this.searchedContactList.push({ ...this.contactList[i] });
          if (i == 4) {
            break;
          }
        }
      }
    }
  }

  removeSelectedContact() {
    this.selectedContactList = null;
    this.searchText.nativeElement.value = "";
  }

}
