import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ninja-local-contact-list',
  templateUrl: './ninja-local-contact-list.component.html',
  styleUrls: ['./ninja-local-contact-list.component.css']
})
export class NinjaLocalContactListComponent implements OnInit {

  @Input() apiUrl;
  selectedContactList = null;
  contactList = [];

  constructor() { }

  ngOnInit(): void {
    if (this.apiUrl) {
      /* Api should be called */
      this.contactList = JSON.parse(window.localStorage.getItem("ninja-trader-contact-list"));
    }
    else {
      this.contactList = JSON.parse(window.localStorage.getItem("ninja-trader-contact-list"));
    }
  }

  getSelectedContact(contact) {
    this.selectedContactList = contact;
  }

}
