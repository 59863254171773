import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ninja-contact-list',
  templateUrl: './ninja-contact-list.component.html',
  styleUrls: ['./ninja-contact-list.component.css']
})
export class NinjaContactListComponent implements OnInit {

  @Input() search;
  @Input() searchedContactList;
  @Input() slicedSearchedContactList;
  @Input() offset; @Input() limit;
  @Output('getSelectedContact') getSelectedContact: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectCard(contact) {
    if (this.search) {
      this.searchedContactList = [];
    }
    this.getSelectedContact.emit(contact);
  }
}
