import { Injectable } from '@angular/core';
import { StorageService } from '../_handlers/storage.service';

declare var clevertap: any;

@Injectable({
  providedIn: 'root',
})
export class ClevertapService {
  constructor(private storage: StorageService) {}

  updateUserIdToHansel(userId) {
    if (userId) {
      try {
        window.Hansel?.getUser().setUserID(userId);
        window.smartech?.('identify', userId);
      } catch (error) {
        console.log('Hansel Id update error', error);
      }
    }
  }

  getPhoneNumberWithCountryCode(phNumber: any) {
    const countryCode = phNumber.slice(0, 3);
    if (countryCode === '+91') {
      return phNumber;
    } else {
      return '+91' + phNumber;
    }
  }

  userLoginEvent() {
    const phoneNumber = this.getPhoneNumberWithCountryCode(this.storage?.realmOwnerData?.contactNumber);
    clevertap?.onUserLogin.push({
      Site: {
        Name: this.storage?.realmOwnerData?.name,
        Identity: this.storage?.realmOwnerData?.asgardUserId,
        Phone: phoneNumber,
        'MSG-email': false,
        'MSG-push': true,
        'MSG-sms': true,
        'MSG-whatsapp': true,
        NinjaUserId: this.storage?.realmOwnerData?.asgardUserId,
        App_language: this.storage?.languageCode,
      },
    });
    this.updateUserIdToHansel(this.storage?.realmOwnerData?.asgardUserId);
  }

  userProfileUpdate(data) {
    const phoneNumber = this.getPhoneNumberWithCountryCode(this.storage?.realmOwnerData?.contactNumber);
    const siteData: any = {
      Name: data?.businessName?.traderDTO?.name,
      Identity: data?.userId,
      Phone: phoneNumber,
      'MSG-email': false,
      'MSG-push': true,
      'MSG-sms': true,
      'MSG-whatsapp': true,
      NinjaUserId: data?.userId,
      connectedBankingUser: data?.connectedBankingUser ? 'Yes' : 'No',
      App_language: data?.selectedLang,
    };
    if (data?.isInternalUser) {
      siteData.employeeType = data.isInternalUser;
    }
    if (data?.organizationFacilityId) {
      siteData.Market = data.organizationFacilityId;
    }
    clevertap?.profile.push({
      Site: siteData,
    });
    this.updateUserIdToHansel(data?.userId);
  }
  userKycLocationUpdate(location) {
    clevertap?.profile.push({
      Site: {
        KYC_location: location,
      },
    });
  }
  //clevertap.pushProfile(profileUpdate);

  pushCustomEvent(traderEventType, params, customEventDetails) {
    let hanselData;
    let mergedProperties = {};
    if (!customEventDetails) {
      customEventDetails = {};
    }
    const sendEventName = (params && params.eventName) || traderEventType;
    hanselData = window.Hansel?.logEvent?.(sendEventName, 'ctp', customEventDetails);
    mergedProperties = Object.assign(params, hanselData);
    clevertap?.event?.push(sendEventName, mergedProperties);
  }
}
