import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { PartiesResponseVM } from '../../app/_modals/parties-response.modal';
import { PartiesActionTypes, PartiesFailed, PartiesFulfilled } from '../actions/parties.actions';
import { map } from 'rxjs/operators';
import { ChoosePartyService } from '../../app/main/content/onboarding/choose-party/choose-party.service';
import { forkJoin } from 'rxjs';
import { StorageService } from 'src/app/_handlers/storage.service';
import { PartiesService } from 'src/app/_services/parties.service';

@Injectable()
export class PartiesEffects {
  // contactList = this.getNativeContactList();
  recentContactList;

  constructor(
    private dataPersistence: DataPersistence<PartiesResponseVM>,
    private _choosePartyService: ChoosePartyService,
    private storage: StorageService,
    private partiesService: PartiesService
  ) {}

  @Effect()
  loadPartiesData$ = this.dataPersistence.fetch(PartiesActionTypes.Parties_Loading, {
    run: (action: any) => {
      return forkJoin([
        action?.useESApi
          ? this._choosePartyService.getPartiesES()
          : this._choosePartyService.getRecentContacts(action?.userId),
        // this.partiesService.getNetReceivableAmount()
      ]).pipe(
        map((res) => {
          this.recentContactList = res[0] && res[0].data;
          if (this.recentContactList?.length > 0) {
            this.recentContactList = this.recentContactMapping(this.recentContactList);
          }

          // if (res && res[1] && res[1]) {
          //   this.recentContactList = this.setAmountVariables(res[1]?.data);
          // }
          return new PartiesFulfilled(this.recentContactList);
        })
      );
    },
    onError: () => {
      return new PartiesFailed();
    },
  });

  recentContactMapping(contacts): PartiesResponseVM[] {
    return contacts.map(
      (party) =>
        <PartiesResponseVM>{
          address: party.address,
          balance: party.balance,
          contactNumber: party.contactNumber,
          gstNo: party.gstNo,
          id: +party.id,
          partyName: party.partyName,
          partyOfUserId: party.partyOfUserId,
          partyUserId: party.partyUserId,
          contactName: party.partyName,
          userId: party.partyUserId,
          userName: party.partyName,
          userContactNumber: party.contactNumber,
          sellerName: party.partyName,
          searchContactName: party.partyName.toLowerCase(),
          cityId: party?.cityId,
          facilityId: party?.facilityId,
          uniqueInfos: party?.uniqueInfos,
          userCpvStatus: party?.userCpvStatus,
          dataMap: party?.dataMap,
          tier: party?.additionalDetails?.find((v) => v?.refType?.toUpperCase() === 'BUCKET')?.refValue?.toUpperCase(),
          pgStatus: party?.additionalDetails
            ?.find((v) => v?.refType?.toUpperCase() === 'PGSTATUS')
            ?.refValue?.toUpperCase(),
        }
    );
  }

  // setAmountVariables(data) {
  //   this.recentContactList?.forEach((m) => {
  //     if (m.partyUserId) {
  //       let netReceivableAmount,
  //         isAmountNegative = false;
  //       if (data?.ordersGroupedBy && Object.keys(data?.ordersGroupedBy).length !== 0) {
  //         let obj = data?.ordersGroupedBy[this.storage.authData.userId];
  //         let _obj = obj ? obj[m.partyUserId] : null;
  //         if (_obj) {
  //           netReceivableAmount = _obj?.orderOutstandingDetails?.netReceivable;
  //         }
  //         if (netReceivableAmount < 0) {
  //           isAmountNegative = true;
  //           netReceivableAmount = Math.abs(netReceivableAmount);
  //         } else {
  //           isAmountNegative = false;
  //         }
  //       }
  //       m.netReceivableAmount = netReceivableAmount;
  //       m.receivableAmount = netReceivableAmount ? netReceivableAmount : 0;
  //       m.isAmountNegative = isAmountNegative;
  //     }
  //   });
  //   return this.recentContactList;
  // }
}
