import { Action } from '@ngrx/store';

export enum PaymentModuleTypes {
  Show_Party_Account_Details = 'Show_Party_Account_Details',
}

export class ShowPartyAccountDetails implements Action {
  readonly type = PaymentModuleTypes.Show_Party_Account_Details;
  constructor(public payload: any) {}
}

export type PaymentModuleActions = ShowPartyAccountDetails;
