import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaRadioComponent } from './ninja-radio.component';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaRadioComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule
  ],
  exports: [NinjaRadioComponent]
})
export class NinjaRadioModule { }
