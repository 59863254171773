import { SweetAlertOptions } from "sweetalert2";

export interface NinjaNotificationOptions {
    title?: string;
    primaryButtonIndex?: number;
    message?: string;
    messageHTML?: string;
    buttonLabel?: string;
    buttonLabels?: string[];
    cancelable?: boolean;
    callback?: any;
}

export interface NinjaAlertOptions extends SweetAlertOptions {
    type?: 'success' | 'failure' | 'warning'
}

export enum NinjaAlertCustomClass {
    title = 'ninja-dialog-title',
    content = 'ninja-dialog-content',
    successHeader = 'ninja-dialog-header header-success',
    failureHeader = 'ninja-dialog-header header-failure',
    warningHeader = 'ninja-dialog-header header-warning',
    actions = 'ninja-dialog-actions',
    confirmButton = 'ninja-dialog-confirm',
    cancelButton = 'ninja-dialog-cancel',
    denyButton = 'ninja-dialog-deny',
    icon = 'ninja-dialog-icon'
}