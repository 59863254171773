import { Pipe, PipeTransform } from '@angular/core';
import { DataFilterService } from '../services/data-filter.service';

@Pipe({
  name: 'formControlKey'
})
export class FormControlKeyPipe implements PipeTransform {

  constructor(private dataFilter: DataFilterService) { }

  transform(bindingValue: string, formData: any): string {
    let result = this.dataFilter.getFormControlKeyByBinding(bindingValue, formData);
    return result;
  }

}
