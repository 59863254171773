import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaSwitchComponent } from './ninja-switch.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaSwitchComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [NinjaSwitchComponent]
})
export class NinjaSwitchModule { }
