import { CurrentAccountVM } from '../../app/_modals/current-account.modal';
import { WithdrawActionTypes } from '../actions/withdraw.actions';

export interface WithdrawInitialState {
  accountDetails: CurrentAccountVM[];
  isLoading: boolean;
  isFulfilled: boolean;
  isFailed: boolean;
  reloadDetails: boolean;
}

export type WithdrawState = WithdrawInitialState;

const initialState: WithdrawInitialState = {
  accountDetails: [] as CurrentAccountVM[],
  isFailed: false,
  isFulfilled: false,
  isLoading: false,
  reloadDetails: false,
};

export const withdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case WithdrawActionTypes.CurrentAccount_Loading: {
      return {
        ...state,
        accountDetails: [],
        isLoading: true,
        isFulfilled: false,
        isFailed: false,
        reloadDetails: false,
      };
    }
    case WithdrawActionTypes.CurrentAccount_Fulfilled: {
      return {
        ...state,
        accountDetails: action.payload,
        isLoading: false,
        isFulfilled: true,
        isFailed: false,
        reloadDetails: false,
      };
    }
    case WithdrawActionTypes.CurrentAccount_Failed: {
      return {
        ...state,
        accountDetails: [],
        isLoading: false,
        isFulfilled: false,
        isFailed: true,
        reloadDetails: false,
      };
    }
    case WithdrawActionTypes.reloadCurrentAccount:
      return {
        ...state,
        reloadDetails: true,
      };
    default: {
      return state;
    }
  }
};
