import { PartiesResponseVM } from '../../app/_modals/parties-response.modal';
import { PartiesActionTypes } from '../actions/parties.actions';

export interface PartiesInitialState {
  partiesData: PartiesResponseVM[];
  isLoading: boolean;
  isFulfilled: boolean;
  isFailed: boolean;
  reloadParties: boolean;
}

export type PartiesState = PartiesInitialState;

const initialState: PartiesInitialState = {
  partiesData: [],
  isFailed: false,
  isFulfilled: false,
  isLoading: false,
  reloadParties: false,
};

export const partiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PartiesActionTypes.Parties_Loading: {
      return {
        ...state,
        partiesData: {},
        isLoading: true,
        isFulfilled: false,
        isFailed: false,
        reloadParties: false,
      };
    }
    case PartiesActionTypes.Parties_Fulfilled: {
      return {
        ...state,
        partiesData: action.payload,
        isLoading: false,
        isFulfilled: true,
        isFailed: false,
        reloadParties: false,
      };
    }
    case PartiesActionTypes.Parties_Failed: {
      return {
        ...state,
        partiesData: {},
        isLoading: false,
        isFulfilled: false,
        isFailed: true,
        reloadParties: false,
      };
    }
    case PartiesActionTypes.Reload_Parties:
      return {
        ...state,
        reloadParties: true,
      };
    default: {
      return state;
    }
  }
};
