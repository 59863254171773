import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { CurrentAccountVM } from '../../app/_modals/current-account.modal';
import { CurrentAccountFailed, CurrentAccountFulfilled, WithdrawActionTypes } from '../actions/withdraw.actions';
import { WithdrawMoneyService } from '../../app/_services/withdraw-money.service';

@Injectable()
export class WithdrawEffects {
  @Effect()
  loadAccountDetail$ = this.dataPersistence.fetch(WithdrawActionTypes.CurrentAccount_Loading, {
    run: (action: any, state) => {
      return this.withMoneyDrawService
        .getUserCurrentAccount()
        .pipe(map((res) => new CurrentAccountFulfilled(res?.data?._SELF)));
    },
    onError: (action, error) => {
      return new CurrentAccountFailed();
    },
  });
  constructor(
    private dataPersistence: DataPersistence<CurrentAccountVM>,
    private withMoneyDrawService: WithdrawMoneyService
  ) {}
}
