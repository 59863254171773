import { AmountActionsTypes } from '../actions/amount.actions';
const initialState = {
  isLoading: false,
  isFailed: false,
  isSuccess: false,
  data: 0,
};

export const amountReducer = (state = initialState, action) => {
  switch (action.type) {
    case AmountActionsTypes.Get_Amount:
      return {
        ...state,
        isLoading: true,
        isFailed: false,
        isSuccess: false,
        data: null,
      };
    case AmountActionsTypes.Amount_Reset:
      return {
        ...state,
        isLoading: true,
        isFailed: false,
        isSuccess: false,
        data: null,
      };
    case AmountActionsTypes.Amount_Successful:
      return {
        ...state,
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        data: action.payload ? action.payload.balance : 0,
      };
    case AmountActionsTypes.Amount_Failure:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        isSuccess: false,
        data: null,
      };
    case AmountActionsTypes.Set_Amount:
      return {
        ...state,
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        data: action.payload,
      };
    default:
      return state;
  }
};
