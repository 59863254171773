import { HttpClient, HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { StorageService } from '../_handlers/storage.service';
import { HttpApiResponseVM } from '../_modals/http-api-response';
import { AddMoneyService } from './add-money.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from './error-handling.service';
import { SplitService } from '@splitsoftware/splitio-angular';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private workflow = environment.apiUrl.workflow;
  private contactApi = environment.apiUrl.contact;
  private vanUrl = environment.apiUrl.van;
  private fintrans = environment.apiUrl.fintrans;
  private profileUrl = environment.apiUrl.profile;
  resource: any;
  createPgPopUpShown = false;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private addmoneyService: AddMoneyService,
    private handler: HttpBackend,
    private translate: TranslateService,
    private error: ErrorHandlingService,
    private splitService: SplitService
  ) {}

  async initiateSplitIO() {
    if (this.splitService.isSDKReady) return;
    return new Promise<void>((resolve) => {
      let userId = JSON.stringify(this.storage.authData?.systemUserId);
      if (this.storage.isStaffView) {
        userId = String(this.storage.authData.realms.find((m) => m.role.toLowerCase() === 'owner')?.systemUserId);
      }
      let sdkConfig: SplitIO.IBrowserSettings = {
        core: {
          authorizationKey: environment.splitIO.authorizationKey,
          key: userId,
        },
      };
      if (this.storage?.isTwa) {
        sdkConfig = {
          ...sdkConfig,
          scheduler: {
            featuresRefreshRate: 300,
            segmentsRefreshRate: 300,
          },
        };
      }
      // init method returns an observable for sdk readiness
      this.splitService.init(sdkConfig).subscribe(() => {
        resolve();
      });
    });
  }

  setUserRealmIdentifierInfo(payload): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/create-or-update-user-realm-identifier-info`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  getStatus(params): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/trader-user-status-service-config`;
    return this.http.post(url, params).pipe(map((m: any) => m));
  }

  getCommerceProfile(payload): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-fetch-multiple-profile`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  getDashboardData(userId, input): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/trader-app-home-screen-v1`;
    return this.http.post(url, input).pipe(map((m: any) => m));
  }

  saveDeviceInfo(key, value): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    const authData = this.storage.authData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/create-userAdditionalDetails`;
    return this.http
      .post(
        url,
        {
          input: {
            data: [
              {
                key: key,
                details: value,
              },
            ],
          },
        },
        {
          headers: {
            Authorization: `${authData?.token_type} ${authData?.token}`,
          },
        }
      )
      .pipe(map((m: any) => m));
  }

  getUserRealmDetails(userId): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/fetch-user-realm-details-sc`;
    return this.http.post(url, {}).pipe(map((m: any) => m));
  }

  /* getTodaysPayment(){
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/trader-todays-payment-collection`;
    return this.http.post(url, {input: {fcmId:window.localStorage.getItem('ninja-trader-fcm-token')}}).pipe(map((m: any) => m));
  } */

  getTransHistory(userId, payload): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    let url;
    if (this.storage.authData.userId && payload?.input?.paymentType == 'PAY_LATER') {
      url = `${this.fintrans}/${realmObj?.realmIdentifier}/${this.storage?.authData?.systemUserId}/cashTransactions?fromDate=${payload?.input?.fromDate}&toDate=${payload?.input?.toDate}&ignoreOwnerTypesIn=QR&pageNo=1&pageSize=100&includePartyDetails=true&latestFirst=true&sendExtraDetails=true&sendAdditionalChargesInfo=true&paymentType=${payload?.input?.paymentType}&otherUserId=${payload?.input?.otherUserId}`;
    } else {
      url = `${this.fintrans}/${realmObj?.realmIdentifier}/${this.storage?.authData?.systemUserId}/cashTransactions?fromDate=${payload?.input?.fromDate}&toDate=${payload?.input?.toDate}&ignoreOwnerTypesIn=QR&pageNo=1&pageSize=100&includePartyDetails=true&latestFirst=true&sendExtraDetails=true&sendAdditionalChargesInfo=true`;
    }
    return this.http.get(url).pipe(map((m: any) => m));
  }

  /* getConnectedBankAccounts(): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.vanUrl}/${realmObj?.realmIdentifier}/${authData?.systemUserId}/connected-account/getAccounts?mobile=${this.storage?.realmOwnerData?.contactNumber}`;
    return this.http.get(url).pipe(map((m: any) => m))
  } */

  getConnectedBankAccountsV2(fetchBalance = false): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const contact = this.storage?.realmOwnerData?.contactNumber;
    let url = `${this.vanUrl}/${realmObj?.realmIdentifier}/${authData?.systemUserId}/connected-account/v2/getAccounts?mobile=${contact}&fetchBalance=${fetchBalance}`;
    return this.http.get(url).pipe(map((m: any) => m));
  }

  unlinkAccount(payload): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.vanUrl}/${realmObj?.realmIdentifier}/${authData?.systemUserId}/connected-account/unlinkAccount`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  requestPayment(payload: any): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/request-payment`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  postRefferalCode(payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/referral-code-service-config`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  clearPaymentDetails() {
    this.addmoneyService.clearPaymentDetails();
  }

  postBusinessName(payload) {
    const businessHttp: HttpClient = new HttpClient(this.handler);
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/trader-referral`;
    let headers: any = {
      Accept: 'application/json, text/plain, */*',
    };
    if (this.storage.languageCode) {
      headers['language'] = this.storage.languageCode;
    }
    if (authData?.token) {
      headers.Authorization = `${authData?.token_type} ${authData?.token}`;
      headers['Auth-Type'] = 'token';
    }
    return businessHttp
      .post(url, payload, {
        headers: headers,
      })
      .pipe(map((m: any) => m));
  }

  postBusinessNameBeta(payload) {
    const businessHttp: HttpClient = new HttpClient(this.handler);
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/trader-referral-beta`;
    let headers: any = {
      Accept: 'application/json, text/plain, */*',
    };
    if (this.storage.languageCode) {
      headers['language'] = this.storage.languageCode;
    }
    if (authData?.token) {
      headers.Authorization = `${authData?.token_type} ${authData?.token}`;
      headers['Auth-Type'] = 'token';
    }
    return businessHttp
      .post(url, payload, {
        headers: headers,
      })
      .pipe(map((m: any) => m));
  }

  postProfileInfo(url: string, payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let verifyUserAccount: HttpClient = new HttpClient(this.handler);
    return verifyUserAccount
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  /*getAccountBalance(payload): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    if (this.storage.authData.systemUserId) {
      let url = `${this.vanUrl}/${realmObj?.realmIdentifier}/${this.storage.authData.systemUserId}/connected-account/balance`;
      return this.http.post(url, payload).pipe(map((m: any) => m));
    }
  }*/

  getAccountBalanceV2(accountId): Observable<HttpApiResponseVM> {
    const realmObj = this.storage.realmOwnerData;
    const contactNumber = this.storage.realmOwnerData.contactNumber;
    const httpClient: HttpClient = new HttpClient(this.handler);
    const authData = this.storage.authData;

    let url = `${this.vanUrl}/${realmObj?.realmIdentifier}/${this.storage.authData.systemUserId}/account/v2/accountId/${accountId}/balance?mobile=${contactNumber}`;
    return httpClient
      .get(url, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(map((m: any) => m));
  }

  fetchContactList(): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.contactApi}/${realmObj?.realmIdentifier}/${authData?.systemUserId}/contactMinimalInfo`;
    let contactHttp = new HttpClient(this.handler);
    return contactHttp
      .get(url, {
        headers: {
          Authorization: `Basic bWF5YW5rOmNyQHp5bUBydmVs`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  fetchTransactionbyId(transactionId): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.fintrans}/${realmObj.realmIdentifier}/${authData.systemUserId}/cashTransactions/${transactionId}?sendExtraDetails=true&sendAdditionalChargesInfo=true`;
    let contactHttp = new HttpClient(this.handler);
    return contactHttp
      .get(url, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  getUserCategory(showBusinessProfileCategory = true) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const orgId = this.storage.realmOwnerData.userRealmId;
    let httpClient = new HttpClient(this.handler);
    let url = `${this.profileUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/sn-org-profile/organization/${orgId}?showBusinessProfileCategory=${showBusinessProfileCategory}`;
    return httpClient
      .get(url, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError((error) => {
          if (error?.error?.errorCode === 400 && error?.error?.success === false) {
            return of([]);
          } else {
            return throwError(error);
          }
        })
      );
  }

  getTraderInfo() {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/trader-info-fetch`;
    return this.http.post(url, {}).pipe(map((m: any) => m));
  }

  async catchError(error: HttpErrorResponse | any) {
    // this.resource = await this.getResourceAsync();
    // this.spinner.end();
    // const err = error?.error;
    // let text = this.resource?.somethingWentWrongNote;
    // if (err?.code && err?.message) { text = err?.message }
    // if (err?.errorCode && err?.errorMessage) { text = err?.errorMessage }
    // this.error(this.resource?.errorMsg, text);
    // return EMPTY;
    this.error.catchErrorHandling(error);
  }

  // async error(title?: string, text?: string) {
  //   this.resource = await this.getResourceAsync();
  //   let config: any = {
  //     icon: 'error',
  //     type: 'failure',
  //     title,
  //     text,
  //     confirmButtonText: this.resource?.ok
  //   }
  //   return this.notify.fire(config);
  // }
  async getResourceAsync() {
    return await this.translate.get('resource').toPromise();
  }

  async checkForBusinessProfileComplete() {
    const primaryUserRealmId = [this.storage?.realmOwnerData?.userRealmId];

    const payload = {
      input: {
        ids: primaryUserRealmId,
      },
    };
    let res = await this.getCommerceProfile(payload).toPromise();
    if (!res.success || !res.data?.details?.length) return false;
    const [{ profile_detail }] = res.data?.details;
    return profile_detail ? true : false;
  }

  getQrSettlementAccountDetail() {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.vanUrl}/${realmObj?.realmIdentifier}/${authData?.systemUserId}/autoSweep/settlementDetails?settlementReference=QR`;
    return this.http.get(url).pipe(map((m: any) => m));
  }
}
