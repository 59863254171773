import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { NinjaWidgetVM } from './ninja-widgets.modal';

@Component({
  selector: 'ninja-widgets',
  templateUrl: './ninja-widgets.component.html',
  styleUrls: ['./ninja-widgets.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NinjaWidgetsComponent implements OnInit {

  @Input() widgets: NinjaWidgetVM[] = [];
  @Output() select: EventEmitter<{ event: any, widget: NinjaWidgetVM }> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(e, widget: NinjaWidgetVM) {
    this.select.emit({
      event: e,
      widget: widget
    });
  }
}
