import { DashboardActionTypes } from '../actions/Dashboard.actions';
import { OnboardDashboardResponseVM } from '../../app/_modals/onboard-dashboard-response';

export interface HomeState {
  dashboardData: OnboardDashboardResponseVM;
}

export const initialState: any = {
  contactNumber: null,
  dashboardData: {},
  selectedLanguage: 'en',
  isLoading: false,
  isFulfilled: false,
  isFailed: false,
  reloadDashboard: false,
  isCollectAssure: false,
  isKnowMyTrader: false,
};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case DashboardActionTypes.Dashboard_Fulfilled:
      return {
        ...state,
        dashboardData: action.payload,
        isLoading: false,
        isFulfilled: true,
        isFailed: false,
        reloadDashboard: false,
      };
    case DashboardActionTypes.Dashboard_Loading:
      return {
        ...state,
        dashboardData: {},
        isLoading: true,
        isFulfilled: false,
        isFailed: false,
        reloadDashboard: false,
      };
    case DashboardActionTypes.Dashboard_Failed:
      return {
        ...state,
        dashboardData: {},
        isLoading: false,
        isFulfilled: false,
        isFailed: true,
        reloadDashboard: false,
      };
    case DashboardActionTypes.Contact:
      return {
        ...state,
        contactNumber: action.payload,
      };
    case DashboardActionTypes.Language:
      return {
        ...state,
        selectedLanguage: action.payload,
        dashboardData: {},
        isLoading: false,
        isFulfilled: false,
        isFailed: false,
        reloadDashboard: false,
      };
    case DashboardActionTypes.UserRealmDetails:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case DashboardActionTypes.Reload_Dashboard:
      return {
        ...state,
        reloadDashboard: true,
      };
    case DashboardActionTypes.Reset_Dashboard:
      return {
        ...state,
        dashboardData: {},
        isLoading: false,
        isFulfilled: false,
        isFailed: false,
        reloadDashboard: false,
      };
    case DashboardActionTypes.Is_CollectAssure:
      return {
        ...state,
        isCollectAssure: action.payload.isCollectAssure,
        collectAssureProduct: action.payload.collectAssureProduct,
        isSellingTrade: action.payload.isSellingTrade,
        backNavSellingTrade: action.payload.backNavSellingTrade,
        sellingTradeData: action.payload.sellingTradeData,
        contractData: action.payload.contractData,
        placeholderToggle: action.payload.placeholderToggle,
        isKnowMyTrader: action.payload.isKnowMyTrader,
        knowMyTraderProduct: action.payload.knowMyTraderProduct,
      };
    default:
      return state;
  }
};
