import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { DashboardFulfilled, DashboardActionTypes } from '../actions/Dashboard.actions';
import { OnboardDashboardResponseVM } from 'src/app/_modals/onboard-dashboard-response';
import { UserService } from 'src/app/_services/user.service';
import { DashboardFailed } from '../actions/Dashboard.actions';
import { StorageService } from 'src/app/_handlers/storage.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/_common/constants';
import { ClevertapService } from 'src/app/_services/clevertap.service';
import { FeatureManagementService } from 'src/app/_services/feature-management.service';

@Injectable()
export class DashboardEffects {
  constructor(
    private dataPersistence: DataPersistence<OnboardDashboardResponseVM>,
    private _user: UserService,
    private storage: StorageService,
    private _appConstants: AppConstants,
    private cleverTapService: ClevertapService,
    private featureManagementService: FeatureManagementService
  ) {}

  @Effect()
  loadDashboardData$ = this.dataPersistence.fetch(DashboardActionTypes.Dashboard_Loading, {
    run: (action: any, state) => {
      return this._user
        .getDashboardData(action.payload, {
          input: {
            fcmId: window.localStorage.getItem('ninja-trader-fcm-token'),
            userTypeId: environment.userTypeId,
            mobile: this.storage.realmOwnerData.contactNumber,
          },
        })
        .pipe(
          map((res: any) => {
            let dashboardData = res?.data;
            let authData = this.storage.authData;
            authData = { ...authData, businessName: dashboardData.traderDTO };
            this.storage.authData = authData;
            if (dashboardData && !dashboardData.accountNumber && this.storage.realmOwnerData?.realmName) {
              this.storage.isVanRequest = true;
            }
            if (this.storage.paymentsConfig && !this.storage.paymentsConfig.name) {
              this.storage.paymentsConfig = {
                ...this.storage.paymentsConfig,
                name: this.storage.authData?.businessName?.traderDTO?.name,
              };
            }
            localStorage.setItem(this._appConstants.dashboardExpiryKey, new Date().getTime().toString());

            this.cleverTapService.userProfileUpdate({
              ...this.storage.authData,
              selectedLang: this.storage?.languageCode,
              isInternalUser: dashboardData?.aggregatorData?.data?.userRealm?.userType,
              organizationFacilityId:
                dashboardData?.aggregatorData?.data?.userRealmIdentifierInfo?.organizationFacilityId,
            });

            this.featureManagementService.userProfileUpdate(this.storage.authData);
            return new DashboardFulfilled(dashboardData);
          })
        );
    },
    onError: () => {
      return new DashboardFailed();
    },
  });
}
