import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaSpinnerComponent } from './ninja-spinner.component';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaSpinnerComponent],
  imports: [
    CommonModule
  ],
  exports: [NinjaSpinnerComponent]
})
export class NinjaSpinnerModule {
 
}
