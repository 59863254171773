import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NinjaWhatsappService } from './ninja-whatsapp.service';

@Component({
  selector: 'ninja-whatsapp',
  templateUrl: './ninja-whatsapp.component.html',
  styleUrls: ['./ninja-whatsapp.component.scss'],
})
export class NinjaWhatsappComponent implements OnInit {
  wapMobileLink: SafeUrl;
  wapDesktopLink;

  constructor(
    private _ninjaWhatsapp: NinjaWhatsappService,
    private _device: DeviceDetectorService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this._ninjaWhatsapp.SendSubject.subscribe((res) => {
      let _mobileLink = `whatsapp://send?text=${res.messageBody}`;
      this.wapDesktopLink = `https://web.whatsapp.com/send?text=${res.messageBody}`;
      if (res.to) {
        _mobileLink = `${_mobileLink}&phone=${res.to}`;
        this.wapDesktopLink = `${this.wapDesktopLink}&phone=${res.to}`;
      }
      this.wapMobileLink = this.sanitizer.bypassSecurityTrustUrl(_mobileLink);
      setTimeout(() => {
        if (this._device.isMobile()) {
          document.getElementById('mobile-link')?.click();
        }
        // if (this._device.isDesktop() && res.desktop) {
        //   document.getElementById('desktop-link')?.click();
        // }
      }, 500);
    });
  }
}
