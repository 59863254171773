import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from "ngx-avatar";
import { NinjaContactPickerComponent } from './ninja-contact-picker.component';
import { NinjaContactListModule } from '../ninja-contact-list/ninja-contact-list.module';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaContactPickerComponent],
  imports: [
    CommonModule,
    AvatarModule,
    NinjaContactListModule
  ],
  exports: [NinjaContactPickerComponent]
})
export class NinjaContactPickerModule { }
