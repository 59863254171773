const api = {
  host: {
    qa: 'api.trafyn.site',
    prod: 'dev1-trader.ninjacart.in',
    traderazure: 'traders-az.ninjacart.in',
    staging: 'staging.ninjacart.in',
    retailerApp: 'qa-retail-banking.ninjacart.in',
    agnet: 'agnet.ninjacart.in',
  },
  port: {
    qaLogin: 8095,
    qaTrader: 8255,
    qaLogistics: 8292,
    qaContacts: 9150,
    workflow: 7205,
    payment: 8225,
    scratchCard: 9185,
  },
};
export const environment = {
  apiPoints: api,
  production: false,
  apiUrl: {
    login: `https://${api.host.qa}/grant/alpha`,
    asgard: `https://${api.host.qa}/asgard`,
    trader: `https://${api.host.qa}/trader`,
    logistics: `https://${api.host.qa}/logisticstracking`,
    recentContacts: `https://${api.host.qa}/contacts-service`,
    onboard: `https://${api.host.qa}/workflow-engine`,
    workflow: `https://${api.host.qa}/workflow-engine`,
    workflowAzure: `https://${api.host.traderazure}/workflow-engine`,
    payment: `https://${api.host.qa}/payment-service`,
    fintrans: `https://${api.host.qa}/fin-trans`,
    campaign: `https://${api.host.qa}/campaign-service`,
    scratchCard: `https://${api.host.qa}:/scratch-card`,
    van: `https://${api.host.qa}/van`,
    payment_van: `https://${api.host.qa}/payment-service`,
    appUrl: `https://${api.host.prod}/dummy`,
    orderManagement: `https://${api.host.qa}/ordermanagement`,
    contact: `https://${api.host.qa}/contacts-service`,
    stagingOdin: `https://${api.host.staging}/asgard/odin`,
    kyc: `https://${api.host.qa}/kyc`,
    ncpay: `https://${api.host.qa}/ncpay`,
    retailerApp: `https://${api.host.retailerApp}`,
    firebaseLogin: `https://${api.host.qa}/iam/firebase/token`,
    agnet: `https://${api.host.agnet}`,
    pg: `https://${api.host.qa}/payment-assurance`,
    profile: `https://${api.host.qa}/profile-service`,
    document_generator: `https://${api.host.qa}/document-generator`,
  },
  resourcePath: 'trader/assets/i18n/',
  appVersion: '1002',
  appName: 'Skadi',
  appType: 'daWebApp',
  assetsPath: 'trader/assets',
  awsSqsEntity: {
    region: 'eu-west-1',
    awsAccessKeyId: 'AKIAI626ZR46A7CFXQJA',
    awsSecretAccessKey: '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',
    queueUrl: 'https://sqs.eu-west-1.amazonaws.com/472272028171/qa-events-new',
  },
  payment: {
    paytm: {
      mid: 'ninjac24994565372733',
      mkey: 'VhrObPIL8PFzH56k',
      website: 'WEBSTAGING',
      industryTypeId: 'Retail',
      channelId: 'WEB',
      stagingUrl: `https://securegw-stage.paytm.in`,
      productionUrl: `https://securegw.paytm.in`,
      paytmServer: `http://144.76.57.75:4040`,
    },
  },
  whatsapp: {
    desktop: 'https://web.whatsapp.com/send',
    mobile: 'whatsapp://send',
  },
  fintech: {
    url: 'https://traders.trafyn.info/loans/',
    env: 'qa',
  },
  collectAssure: {
    url: 'https://traders.trafyn.info/collect-assure',
    env: 'qa',
  },
  brandLive: {
    url: 'https://vendor.brands.live/',
    env: 'qa',
  },
  mandiNews: {
    url: 'https://traders.trafyn.info/mandi-news/',
    env: 'qa',
  },
  ninjaTrack: {
    url: 'https://traders.trafyn.info/track/',
    env: 'qa',
  },
  enableUsers: {
    collectAssure: [
      1100, 1593550, 1747318, 1765525, 1772064, 1786386, 1221695, 1646896, 1647704, 1221569, 1782897, 1646654,
    ],
    trade: [1100, 1593550, 1747318, 1765525, 1772064, 1786386, 1221695, 1646896, 1647704, 1221569, 1782897, 1646654],
    kyc_unregistered: [1646654, 1647030, 1646896, 1646673, 1646655, 1647144],
  },
  //digioReturnUrl: 'http://95.217.201.187:3838/dummy/e-kyc/select',
  digioReturnUrl: 'https://traders.trafyn.info/trader/e-kyc/select',
  digioReturnUrlKycLite: 'https://traders.trafyn.info/trader/e-kyc/kyc-lite',
  checkCibilDigioUrl: 'https://traders.trafyn.info/trader/pending-kyc-score',
  digioBaseUrl: `https://ext.digio.in/#/gateway/login`,
  submitAccessUserId: 0,
  defaultRealmIdentifier: 'dd180bca-465a-470a-abe4-9d5a15ded551',
  userTypeId: 3,
  userTypeIdLite: 5,
  providerId: 2,
  featureFlips: {
    multiBanking: {
      url: 'https://qa.ninjacart.in/feature-flip/features/multibanking/actions/check-feature',
      tagId: 656,
    },
    genericPay: {
      url: 'http://195.201.129.119:3000/feature-flip/features/multibanking/actions/check-feature',
      tagId: 656,
    },
  },
  splitIO: {
    authorizationKey: 'kqqgqie1qd320au7qstc66n75qtkj8lbv806',
  },
  features: {
    newPayFlow: true,
  },
  kyc: {
    bankStatment: 17,
    cancelledCheque: 2,
    unregisterType: 'PRIVATE/PUBLIC_LTD-test',
  },
  defaultUserImage: 'https://s3.amazonaws.com/qa-workflow-check-docs/profile-service/_download.png_1653464275090.png',
  webVersion: 41,
  apiCallInterval: {
    PARTIESDATA_LOCALSTORAGE_TIME: 3600000,
    TRANSACTIONDATA_LOCALSTORAGE_TIME: 300000, // 5 min
    CONTRACTS_LOCALSTORAGE_TIME: 300000,
    DASHBOARD_LOCALSTORAGE_TIME: 1200000,
  },
  sentry_env: 'DEV',
  firebase: {
    apiKey: 'AIzaSyBa3kJFYbyRLWDTlPvfcjxDmSBYKPgCBOU',
    authDomain: 'ninjacart-trader-qa.firebaseapp.com',
    databaseURL: 'https://ninjacart-trader-qa-default-rtdb.asia-southeast1.firebasedatabase.app/',
    projectId: 'ninjacart-trader-qa',
    storageBucket: 'ninjacart-trader-qa.appspot.com',
    messagingSenderId: '942663578465',
    appId: '1:942663578465:web:b6616051faa294c8f68b20',
    measurementId: 'G-RDJDHJZ0JP',
  },
  businessPanDocumentId: 12,
};
