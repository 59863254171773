import { ContentHeader } from 'src/app/_modals/content-header.modal';
import { StorageService } from 'src/app/_handlers/storage.service';
import { NetworkReleaseToUser, ReleaseNetworkToAll } from 'src/app/_config/network-release-config';
import { Store } from '@ngrx/store';
const _storage = new StorageService();
export enum ContentPage {
  onboardDash = 'onboardDash',
  addParty = 'addParty',
  parties = 'parties',
  searchParties = 'searchParties',
  partiesDetails = 'partiesDetails',
  sharePayment = 'sharePayment',
  captureContact = 'captureContact',
  verifyOtp = 'verifyOtp',
  createProfile = 'createProfile',
  editProfile = 'editProfile',
  trackConsignment = 'trackConsignment',
  addTrader = 'addTrader',
  addNewItem = 'addNewItem',
  addTrade = 'addTrade',
  addContract = 'addContract',
  addContractOtpVerification = 'addContractOtpVerification',
  addmoney = 'addmoney',
  paymentPaytm = 'paymentPaytm',
  recentParties = 'recentParties',
  tradeDetails = 'tradeDetails',
  additionalBuyerDetails = 'additionalBuyerDetails',
  contactParties = 'contactParties',
  toCollect = 'toCollect',
  onboardToCollect = 'onboardToCollect',
  notifications = 'notifications',
  consents = 'consents',
  transactionStatus = 'transactionStatus',
  transactionHistory = 'transactionHistory',
  transactionCollect = '',
  questions = 'questions',
  paymoney = 'paymoney',
  withdrawMoney = 'withdrawMoney',
  withdrawStatus = 'withdrawStatus',
  recordCash = 'recordCash',
  collectOnline = 'collectOnline',
  ninjaPay = 'ninjaPay',
  addTradeOrders = 'addTradeOrders',
  addTradeOrdersV2 = 'addTradeOrdersV2',
  addPartyFromTrade = 'addPartyFromTrade',
  addPartyFromContract = 'addPartyFromContract',
  editPartyFromTrade = 'editPartyFromTrade',
  recentPartiesFromTrade = 'recentPartiesFromTrade',
  recentPartiesFromContract = 'recentPartiesFromContract',
  contactPartiesFromTrade = 'contactPartiesFromTrade',
  referral = 'referral',
  rewards = 'rewards',
  upcomingOffers = 'upcomingOffers',
  referAndEarn = 'referAndEarn',
  referStatus = 'referStatus',
  walletSettings = 'walletSettings',
  orders = 'orders',
  help = 'help',
  watchHow = 'watchHow',
  businessDetails = 'businessDetails',
  payMoneyFromNotification = 'payMoneyFromNotification',
  collectStatus = 'collectStatus',
  paymentStatus = 'paymentStatus',
  transRechargeStatus = 'transRechargeStatus',
  shareTransactions = 'shareTransactions',
  selectOrder = 'selectOrder',
  addTradeReconedOrders = 'addTradeReconedOrders',
  selectReconPayment = 'selectReconPayment',
  selectKyc = 'selectKyc',
  splashKyc = 'splashKyc',
  downloadPartyDetails = 'downloadPartyDetails',
  tradeV2Detail = 'tradeV2Detail',
  aboutPaymentGuarantee = 'aboutPaymentGuarantee',
  productPaymentGuarantee = 'productPaymentGuarantee',
  supportPaymentGuarantee = 'supportPaymentGuarantee',
  detailPaymentGuarantee = 'detailPaymentGuarantee',
  aboutPaymentAssurance = 'aboutPaymentAssurance',
  chooseProductPaymentGuarantee = 'chooseProductPaymentGuarantee',
  inprogressPaymentGuarantee = 'inprogressPaymentGuarantee',
  creditScore = 'creditScore',
  settings = 'settings',
  voiceNotificationSettings = 'voiceNotificationSettings',
  team = 'team',
  editTeam = 'editTeam',
  viewTeam = 'viewTeam',
  teamProfile = 'teamProfile',
  staffDashboard = 'staffDashboard',
  onboardStaffDash = 'onboardStaffDash',
  staffTransactionHistory = 'staffTransactionHistory',
  addTeamMember = 'addTeamMember',
  paymentGuaranteeFees = 'paymentGuaranteeFees',
  minGuarantee = 'minGuarantee',
  helpCenter = 'helpCenter',
  contractDetails = 'contractDetails',
}

// NOTE:
// Provide backNav only for specific redirection.
// Default backNav will be Dashboard page only.

//based on new and old UI contract back and footer handling
let state = window.localStorage.getItem('state');
let contactStore = JSON.parse(state)?.contact;
let render_new_network_comp;
if (contactStore) {
  const authData = window.localStorage.authData;
  if (authData) {
    const parsedData = JSON.parse(authData);
    const { userId } = parsedData;
    render_new_network_comp = ReleaseNetworkToAll || NetworkReleaseToUser.includes(userId);
  }
}

export const CONTENT_PAGES = {
  onboardDash: <ContentHeader>{
    text: 'Hello!',
    showMenu: true,
    showFooter: true,
    showQrScanner: true,
  },
  onboardStaffDash: <ContentHeader>{
    text: 'Hello!',
    showMenu: true,
    showFooter: true,
    showQrScanner: true,
    hideBellIcon: true,
  },
  addParty: <ContentHeader>{
    text: 'Add Party',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
  },
  parties: <ContentHeader>{
    text: 'Parties',
    showMenu: false,
    showFooter: true,
    hideBack: true,
  },
  searchParties: <ContentHeader>{
    text: 'Parties',
    showMenu: false,
    showFooter: false,
    hideBack: false,
    backNav: 'app/content/select-party',
  },
  partiesDetails: <ContentHeader>{
    text: 'Ninjacart',
    showMenu: false,
    showFooter: false,
  },
  sharePayment: <ContentHeader>{
    text: 'Share Payment Link',
    showMenu: false,
    showFooter: false,
  },
  captureContact: <ContentHeader>{
    text: 'Mobile Verification',
    showMenu: false,
    hideBack: true,
    historyBackNav: true,
    showFooter: false,
  },
  verifyOtp: <ContentHeader>{
    text: 'Mobile Verification',
    showMenu: false,
    hideBack: true,
    backNav: '/signup/contact',
    showFooter: false,
  },
  createProfile: <ContentHeader>{
    text: 'Profile Info',
    showMenu: false,
    showFooter: false,
  },
  editProfile: <ContentHeader>{
    text: 'Profile Edit',
    showMenu: false,
    showFooter: false,
  },
  trackConsignment: <ContentHeader>{
    text: 'Track Consignment',
    showMenu: false,
    showFooter: false,
    backNav: '/app/content/orders-v2',
  },
  addTrader: <ContentHeader>{
    text: 'Add Trader',
    showMenu: false,
    showFooter: false,
  },
  addTrade: <ContentHeader>{
    text: 'Add Trade',
    showMenu: false,
    backNav: 'app/content/dashboard',
    showFooter: false,
  },
  addContract: <ContentHeader>{
    text: 'Add Contract',
    showMenu: false,
    backNav: 'app/content/orders-v2',
    showFooter: false,
  },
  addContractOtpVerification: <ContentHeader>{
    text: 'Verify OTP',
    showMenu: false,
    showFooter: false,
  },
  addTradeOrders: <ContentHeader>{
    text: 'Add Trade',
    showMenu: false,
    backNav: '/app/content/to-collect',
    routeState: { from: 'add-contract' },
    showFooter: false,
  },
  addTradeOrdersV2: <ContentHeader>{
    text: 'Add Trade',
    showMenu: false,
    backNav: '/app/content/orders-v2',
    showFooter: false,
  },
  addNewItem: <ContentHeader>{
    text: 'Add New Item',
    showMenu: false,
    backNav: 'app/content/add-contract',
    showFooter: false,
  },
  paymentPaytm: <ContentHeader>{
    text: 'Paytm Payment',
    showMenu: false,
    showFooter: false,
  },
  addmoney: <ContentHeader>{
    text: 'Add Money',
    showMenu: false,
    showFooter: false,
  },
  recentParties: <ContentHeader>{
    text: 'Recent Parties',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
  },
  tradeDetails: <ContentHeader>{
    text: 'Trade Details',
    hideBellIcon: true,
    // showMenu: false,
    backNav: 'app/content/dashboard',
    // showFooter: false,
  },
  additionalBuyerDetails: <ContentHeader>{
    text: 'Additional Buyers Details',
    hideBellIcon: true,
    // showMenu: false,
    backNav: 'app/content/orders-v2',
    // showFooter: false,
  },
  contactParties: <ContentHeader>{
    text: 'Contact Parties',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
  },
  onboardToCollect: <ContentHeader>{
    text: 'Ninjacart',
    showMenu: false,
    showFooter: false,
  },
  toCollect: <ContentHeader>{
    text: 'Activity',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
    showQrCode: true,
  },
  notifications: <ContentHeader>{
    text: 'Notifications',
    showMenu: false,
    showFooter: true,
  },
  consents: <ContentHeader>{
    text: 'Consents',
    showMenu: false,
    historyBackNav: true,
    showFooter: false,
  },
  transactionStatus: <ContentHeader>{
    text: 'Recharge status',
    showMenu: false,
    showFooter: false,
    //showShareIcon: true
  },
  transactionHistory: <ContentHeader>{
    text: 'Transaction History',
    showMenu: false,
    showFooter: true,
    hideBack: true,
  },
  staffTransactionHistory: <ContentHeader>{
    text: 'Transaction History',
    showMenu: false,
    showFooter: true,
    hideBack: true,
    hideBellIcon: true,
  },
  questions: <ContentHeader>{
    text: 'Questions',
    showMenu: false,
    showFooter: false,
  },
  paymoney: <ContentHeader>{
    text: 'Pay Money',
    showMenu: false,
    backNav: 'app/content/to-collect',
    showFooter: false,
  },
  withdrawMoney: <ContentHeader>{
    text: 'Withdraw Money',
    showMenu: false,
    showFooter: false,
  },
  withdrawStatus: <ContentHeader>{
    text: 'Withdraw Status',
    showMenu: false,
    backNav: '/app/content/trans-history',
    historyBackNav: true,
    //showShareIcon: true
  },
  recordCash: <ContentHeader>{
    text: 'Record Cash',
    showMenu: false,
    backNav: 'app/content/to-collect',
    showFooter: false,
  },
  collectOnline: <ContentHeader>{
    text: 'Collect Online',
    showMenu: false,
    backNav: 'app/content/to-collect',
    showFooter: false,
  },
  ninjaPay: <ContentHeader>{
    text: 'Ninja Pay',
    showMenu: false,
    backNav: 'app/content/to-collect',
    showFooter: false,
  },
  addPartyFromParties: <ContentHeader>{
    text: 'Add Party',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
  },
  addPartyFromTrade: <ContentHeader>{
    text: 'Add Party',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
  },
  addPartyFromContract: <ContentHeader>{
    text: 'Add Party',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
  },
  editPartyFromTrade: <ContentHeader>{
    text: 'Edit Party',
    showMenu: false,
    backNav: 'app/content/select-party',
    showFooter: false,
  },
  recentPartiesFromTrade: <ContentHeader>{
    text: 'Recent Parties',
    showMenu: false,
    backNav: 'app/content/add-contract',
    showFooter: false,
  },
  recentPartiesFromContract: <ContentHeader>{
    text: 'Recent Parties',
    showMenu: false,
    backNav: 'app/content/add-contract',
    showFooter: false,
  },
  contactPartiesFromTrade: <ContentHeader>{
    text: 'Contact Parties',
    showMenu: false,
    backNav: 'app/content/add-contract',
    showFooter: false,
  },
  referral: <ContentHeader>{
    text: 'Referral Code',
    showMenu: false,
    showFooter: true,
    backNav: 'app/content/dashboard',
  },
  rewards: <ContentHeader>{
    text: 'Rewards',
    showMenu: false,
    backNav: 'app/content/dashboard',
    showFooter: false,
  },
  upcomingOffers: <ContentHeader>{
    text: 'Offers',
    showMenu: false,
    backNav: 'app/content/dashboard',
    showFooter: false,
  },
  referAndEarn: <ContentHeader>{
    text: 'Refer and Earn',
    showMenu: false,
    backNav: 'app/content/dashboard',
    showFooter: false,
  },
  referStatus: <ContentHeader>{
    text: 'Referral Status',
    showMenu: false,
    backNav: 'app/content/refer',
    showFooter: false,
  },
  walletSettings: <ContentHeader>{
    text: 'Account Settings',
    showMenu: false,
    showFooter: true,
    backNav: 'app/content/dashboard',
  },
  orders: <ContentHeader>{
    text: 'Contracts',
    showMenu: false,
    hideBack: render_new_network_comp ? false : true,
    showFooter: render_new_network_comp ? false : true,
    backNav: 'app/content/dashboard',
    showDownload: true,
  },
  help: <ContentHeader>{
    text: 'Help',
    showMenu: false,
    showFooter: true,
    backNav: 'app/content/dashboard',
  },
  watchHow: <ContentHeader>{
    text: 'Watch How',
    showMenu: false,
    showFooter: true,
    backNav: 'app/content/help',
  },
  businessDetails: <ContentHeader>{
    text: 'Ninjacart',
    showMenu: false,
    showFooter: false,
    hideBack: true,
    hideBellIcon: true,
  },
  payMoneyFromNotification: <ContentHeader>{
    text: 'Pay Money',
    showMenu: false,
    //historyBackNav: true,
    showFooter: false,
    // backNav : 'app/content/notifications'
  },
  collectStatus: <ContentHeader>{
    text: 'Collect Status',
    showMenu: false,
    //historyBackNav: true,
    backNav: '/app/content/trans-history',
    //showShareIcon: true
  },
  paymentStatus: <ContentHeader>{
    text: 'Payment Status',
    showMenu: false,
    // historyBackNav: true
    backNav: '/app/content/trans-history',
    //showShareIcon: true
  },
  transRechargeStatus: <ContentHeader>{
    text: 'Recharge Status',
    showMenu: false,
    //historyBackNav: true
    backNav: '/app/content/trans-history',
    //showShareIcon: true
  },
  shareTransactions: <ContentHeader>{
    text: 'Contracts',
    showMenu: false,
    hideBack: true,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  selectOrder: <ContentHeader>{
    text: 'Select Order',
    showMenu: false,
    backNav: '/app/content/trans-history',
    showFooter: false,
  },
  addTradeReconedOrders: <ContentHeader>{
    text: 'Add Trade',
    showMenu: false,
    backNav: '/app/content/trans-history',
    showFooter: false,
  },
  selectReconPayment: <ContentHeader>{
    text: 'Select payment',
    showMenu: false,
    backNav: '/app/content/add-contract',
    showFooter: false,
  },
  selectKyc: <ContentHeader>{
    text: 'KYC Verification',
    showMenu: false,
    hideBellIcon: true,
    showFooter: false,
  },
  splashKyc: <ContentHeader>{
    text: 'KYC Verification',
    showMenu: false,
    hideBellIcon: true,
    hideBack: true,
    showFooter: false,
  },
  downloadPartyDetails: <ContentHeader>{
    text: 'Download',
    showMenu: false,
    hideBack: true,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  aboutPaymentGuarantee: <ContentHeader>{
    text: 'About Payment Assurance',
    showMenu: false,
    hideBellIcon: true,
    hideBack: false,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  aboutPaymentAssurance: <ContentHeader>{
    text: 'About Payment Assurance',
    showMenu: false,
    hideBellIcon: true,
    hideBack: false,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  productPaymentGuarantee: <ContentHeader>{
    text: 'Products',
    showMenu: false,
    hideBellIcon: true,
    hideBack: false,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  supportPaymentGuarantee: <ContentHeader>{
    text: 'Payment Assurance Support',
    showMenu: false,
    hideBellIcon: true,
    hideBack: false,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  detailPaymentGuarantee: <ContentHeader>{
    text: 'Payment Assurance Details',
    showMenu: false,
    hideBellIcon: true,
    hideBack: false,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  inprogressPaymentGuarantee: <ContentHeader>{
    text: 'Trade Details',
    showMenu: false,
    hideBellIcon: true,
    hideBack: false,
    showFooter: false,
    backNav: 'app/content/dashboard',
  },
  chooseProductPaymentGuarantee: <ContentHeader>{
    text: 'Choose Product',
    showMenu: false,
    hideBellIcon: true,
    hideBack: false,
    showFooter: false,
    backNav: '/app/content/payment-guarantee/about-payment-assured',
  },

  tradeV2Detail: <ContentHeader>{
    text: 'Trade Details',
    showMenu: false,
    showFooter: false,
    hideBellIcon: true,
    backNav: '/app/content/select-party-new',
    routeState: { selectedTab: 'All' },
  },
  creditScore: <ContentHeader>{
    text: 'Your Credit Score',
    showMenu: true,
    hideBack: false,
    backNav: '/app/content/dashboard',
    showFooter: false,
    hideBellIcon: false,
  },
  team: <ContentHeader>{
    text: 'Team',
    showMenu: false,
    hideBack: false,
    backNav: '/app/content/dashboard',
    showFooter: false,
    hideBellIcon: true,
  },
  editTeam: <ContentHeader>{
    text: 'Add Team Member',
    showMenu: false,
    hideBack: false,
    backNav: '/app/content/select-party',
    showFooter: false,
    hideBellIcon: true,
  },
  viewTeam: <ContentHeader>{
    text: 'Team',
    showMenu: false,
    hideBack: false,
    backNav: '/app/content/dashboard',
    showFooter: false,
    hideBellIcon: true,
  },
  teamProfile: <ContentHeader>{
    text: 'My Profile',
    showMenu: false,
    hideBack: false,
    historyBackNav: true,
    showFooter: false,
    hideBellIcon: true,
  },
  voiceNotificationSettings: <ContentHeader>{
    text: 'Voice Notification',
    showMenu: false,
    hideBack: false,
    backNav: '/app/content/settings',
    showFooter: false,
    hideBellIcon: false,
  },
  staffDashboard: <ContentHeader>{
    text: 'Hello!',
    showMenu: true,
    showFooter: true,
    showQrScanner: false,
  },
  settings: <ContentHeader>{
    text: 'Settings',
    showMenu: false,
    hideBack: false,
    backNav: '/app/content/dashboard',
    showFooter: false,
  },
  addTeamMember: <ContentHeader>{
    text: 'Add Team Member',
    showMenu: false,
    showFooter: false,
    hideBack: false,
    hideBellIcon: true,
    backNav: 'app/content/team',
  },
  paymentGuaranteeFees: <ContentHeader>{
    text: 'Payment Assurance Fees',
    showMenu: false,
    showFooter: false,
    hideBack: false,
    hideBellIcon: true,
    backNav: 'app/content/dashboard',
  },
  minGuarantee: <ContentHeader>{
    text: 'Minimum Guarantee',
    showMenu: false,
    showFooter: false,
    hideBack: true,
    hideBellIcon: true,
    showQrScanner: false,
  },
  helpCenter: <ContentHeader>{
    text: 'Help Center',
    showMenu: false,
    showFooter: false,
    hideBack: false,
    hideBellIcon: true,
    showQrScanner: false,
    historyBackNav: true,
  },
  contractDetails: <ContentHeader>{
    text: 'Contract Details',
    showMenu: false,
    showFooter: false,
    hideBack: false,
    hideBellIcon: true,
    showQrScanner: false,
    historyBackNav: true,
  },
};
