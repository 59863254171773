import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ninja-text-box',
  templateUrl: './ninja-text-box.component.html',
  styleUrls: ['./ninja-text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NinjaTextBoxComponent),
      multi: true
    }
  ]
})
export class NinjaTextBoxComponent implements OnInit, ControlValueAccessor {
  @Input() id;
  @Input() cssClass;
  @Input() label = '';
  @Input() textType;
  @Input() cssProperties;
  @Input() placeholder = '';
  @Input() showCross = false;
  @Input() disabled = false;
  @Input() crossCssProperties;
  @Input() required = false;
  @Input() initialValue;
  @Input() maxlength;
  @Input() float: boolean = false;
  @Output('change') change: EventEmitter<any> = new EventEmitter();
  @Output('keyup') keyup: EventEmitter<any> = new EventEmitter();
  @Output('keydown') keydown: EventEmitter<any> = new EventEmitter();
  @Output('keypress') keypress: EventEmitter<any> = new EventEmitter();
  @Output('focus') focus: EventEmitter<any> = new EventEmitter();
  crossCssProps = {
    "font-size": "24px",
    "position": "absolute",
    "top": "6.5vw",
    "right": "8vw",
    "color": "#569b40"
  };

  onChange: any = () => { };
  onTouched: any = () => { };
  value: any;

  constructor() { }

  ngOnInit(): void {
    this.value = this.initialValue;
    if (this.showCross) {
      this.crossCssProps = { ...this.crossCssProps, ...this.crossCssProperties };
    }
  }

  inputValue(e) {
    this.onChange(e.target.value);
    this.change.emit({
      event: e,
      value: e.target.value
    });
  }

  onKeyUp(e) {
    this.keyup.emit(e);
  }

  onKeyDown(e) {
    this.keydown.emit(e);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onFocus(e) {
    this.onTouched();
    this.focus.emit(e);
  }

  onKeypress(e) {
    this.keypress.emit(e);
  }
}
