import { Injectable } from '@angular/core';
import { environment } from './environments/environment';
import { AppHttpService } from './shared/services/app-http.service';
import { Observable } from "rxjs";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class NinjaFormService {

  // directNinja = `http://${environment.apiUrl.directNinja}`;
  // procurement = `http://${environment.apiUrl.procurement}`;
  // stagingAsgardOdin = `http://${environment.apiUrl.stagingAsgardOdin}`;
  // operationBaseUrl = `http://${environment.apiUrl.operationBaseUrl}`;

  constructor(private httpService: AppHttpService) { }

  uploadTripImage(fileUpload): Observable<any> {
    let url = `http://qa.ninjacart.in:7205/workflowEngine/v1/uploadFile/1/request`;
    return this.httpService.post(url, fileUpload);
  }

  genericSubmit(payload, submitUrl): Observable<any> {
    return this.httpService.post(submitUrl, payload);
  }

}

