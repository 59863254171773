import { Action } from '@ngrx/store';
import { AccountDetailVM } from '../../app/_modals/accountDetail';

export enum WithdrawActionTypes {
  CurrentAccount_Loading = 'CurrentAccount_Loading',
  CurrentAccount_Fulfilled = 'CurrentAccount_Fulfilled',
  CurrentAccount_Failed = 'CurrentAccount_Failed',
  reloadCurrentAccount = 'reloadCurrentAccount',
}

export class CurrentAccountLoading implements Action {
  readonly type = WithdrawActionTypes.CurrentAccount_Loading;
  constructor() {}
}

export class CurrentAccountFulfilled implements Action {
  readonly type = WithdrawActionTypes.CurrentAccount_Fulfilled;
  constructor(public payload: AccountDetailVM) {}
}

export class CurrentAccountFailed implements Action {
  readonly type = WithdrawActionTypes.CurrentAccount_Failed;
  constructor() {}
}

export class ReloadCurrentAccount implements Action {
  readonly type = WithdrawActionTypes.reloadCurrentAccount;
  constructor() {}
}

export type AddMoneyActions =
  | CurrentAccountLoading
  | CurrentAccountFulfilled
  | CurrentAccountFailed
  | ReloadCurrentAccount;
