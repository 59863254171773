import { GamificationActionTypes } from '../actions/Gamification.actions';
const initialState = {
  showModal: false,
  payload: null,
};

export const gamificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GamificationActionTypes.ShowModal:
      return {
        showModal: true,
        payload: action.payload,
      };
    case GamificationActionTypes.CloseModal:
      return {
        showModal: false,
        payload: null,
      };
    default:
      return state;
  }
};
