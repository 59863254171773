import { AddTradeParentActionTypes } from '../actions/AddTradeParent.actions';

export type AddTradeParentState = string;

const initialState: string = '';

export const addTradeParentReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddTradeParentActionTypes.SetParentPage: {
      return action.parentPage;
    }
    default: {
      return state;
    }
  }
};
