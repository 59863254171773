import { Action } from '@ngrx/store';
import { AccountDetailVM } from '../../app/_modals/accountDetail';

export enum AddMoneyActionTypes {
  AccountDetail_Loading = 'AccountDetail_Loading',
  AccountDetail_Fulfilled = 'AccountDetail_Fulfilled',
  AccountDetail_Failed = 'AccountDetail_Failed',
  AccountDetail_Reload = 'AccountDetail_Reload',
}

export class AccountDetailLoading implements Action {
  readonly type = AddMoneyActionTypes.AccountDetail_Loading;
  constructor() {}
}

export class AccountDetailFulfilled implements Action {
  readonly type = AddMoneyActionTypes.AccountDetail_Fulfilled;
  constructor(public payload: AccountDetailVM) {}
}

export class AccountDetailFailed implements Action {
  readonly type = AddMoneyActionTypes.AccountDetail_Failed;
  constructor() {}
}

export class AccountDetailReloading implements Action {
  readonly type = AddMoneyActionTypes.AccountDetail_Reload;
  constructor() {}
}

export type AddMoneyActions =
  | AccountDetailLoading
  | AccountDetailFulfilled
  | AccountDetailFailed
  | AccountDetailReloading;
