import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/_handlers/storage.service';

@Component({
  selector: 'app-permissions-modal',
  templateUrl: './permissions-modal.component.html',
  styleUrls: ['./permissions-modal.component.scss'],
})
export class PermissionsModalComponent implements OnInit {
  assetsPath = environment.assetsPath;
  isAndroid: any;
  buttonText: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private router: Router,
    private storage: StorageService,
    public dialogRef: MatDialogRef<PermissionsModalComponent>
  ) {}

  ngOnInit(): void {
    if ((this.storage.isTwa && !window['Native']) || window['Android']) {
      this.isAndroid = true;
      this.buttonText = 'Grant Permission';
    } else {
      this.isAndroid = false;
      this.buttonText = 'Continue';
    }
    console.debug('Open permission dialoge');
  }

  clearData() {
    const nativeContactList = window.localStorage.getItem('ninja-trader-contact-list');
    if (nativeContactList) {
      this.storage.clear('ninja-trader-contact-list');
    }
    global['contactList'] = [];
  }

  openPrivacyPolicy() {
    this.dialogRef.close();
    this.router.navigate(['/privacy-policy']);
  }
}
