import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ninja-text-view',
  templateUrl: './ninja-text-view.component.html',
  styleUrls: ['./ninja-text-view.component.css']
})
export class NinjaTextViewComponent implements OnInit {

  @Input() id;
  @Input() cssClass;
  @Input() label = '';
  @Input() cssProperties;

  constructor() { }

  ngOnInit(): void {
  }

}
