import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MyBrandingService {
  private workflowUrl: string;

  constructor(private http: HttpClient) {
    this.workflowUrl = environment.apiUrl.workflow;
  }

  thirdParty(payload, realmIdentifier, userId): Observable<any> {
    let url = `${this.workflowUrl}/${realmIdentifier}/${userId}/v1/execution/service/run/brandsLive-registration`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }
}
