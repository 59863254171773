import { Injectable } from '@angular/core';
import { SQSClient, SendMessageCommand } from '@aws-sdk/client-sqs';
import { environment } from 'src/environments/environment';
import { TraderEventVM } from '../_modals/event-stream';
import { StorageService } from '../_handlers/storage.service';
import { TRADER_EVENT_SCHEMA_DETAIL, TraderEventType } from '../_config/event-stream.config';
// import { gamification } from '@ninjacart/nc-gamification-module-app';
import { StoreFacade } from 'src/store/facades/store.facade';
import { Observable } from 'rxjs';
import { RefreshCustomEvents } from '../_config/event-stream.config';
import { ClevertapService } from './clevertap.service';
import { HanselService } from './hansel.service';

@Injectable({
  providedIn: 'root',
})
export class AwsEventService {
  modal: TraderEventVM;
  private pageId: string;
  client: SQSClient;
  command: SendMessageCommand;
  customEventDetails: any;
  dashboarsStore: Observable<any> = this.storeFacade.dashboardInfo;
  ordersStore: Observable<any> = this.storeFacade.ordersStore;
  transactionsStore: Observable<any> = this.storeFacade.transactionsStore;
  partiesStore: Observable<any> = this.storeFacade.partiesStore;

  reloadDashboard: boolean = false;
  reloadParties: boolean = false;
  reloadOrders: boolean = false;
  reloadTransactions = false;
  constructor(
    private _storage: StorageService,
    private storeFacade: StoreFacade,
    private cleverTap: ClevertapService,
    private hanselService: HanselService
  ) {
    this.client = new SQSClient({
      region: environment.awsSqsEntity.region,
      credentials: {
        accessKeyId: environment.awsSqsEntity.awsAccessKeyId,
        secretAccessKey: environment.awsSqsEntity.awsSecretAccessKey,
      },
    });
    this.command = new SendMessageCommand({
      QueueUrl: environment.awsSqsEntity.queueUrl,
      MessageBody: '',
    });
  }

  pageInit(pageId: string) {
    this.dashboarsStore.subscribe((data) => {
      this.reloadDashboard = data.reloadDashboard;
    });
    this.ordersStore.subscribe((data) => {
      this.reloadOrders = data.reloadOrders;
    });
    this.transactionsStore.subscribe((data) => {
      this.reloadTransactions = data.reloadTransactions;
    });
    this.partiesStore.subscribe((data) => {
      this.reloadParties = data.reloadParties;
    });
    this.pageId = pageId;
    if (!this.modal) {
      this.modal = this.createAwsEventModal();
    }
    this.modal.creationTime = new Date(Date.now()).getTime();
    this.modal.visitTime = new Date(Date.now()).getTime();
  }

  createAwsEventModal(): TraderEventVM {
    return <TraderEventVM>{
      appId: 1,
      appVersion: this.getVersionCode(),
      appVersionName: this.getVersionName(),
      creationTime: new Date(Date.now()).getTime(),
      customEventDetails: null,
      customEventSchemaId: '',
      customEventSchemaVersion: 1.0,
      eventType: '',
      pageId: '',
      sourceType: null,
      userId: this._storage.authData?.userId,
      visitTime: new Date(Date.now()).getTime(),
    };
  }

  getVersionCode(): number {
    let versionCode = 1000.0;
    try {
      let appBuildVersion: string = window.localStorage.getItem('app-build-version');
      if (appBuildVersion) {
        versionCode = Number.parseInt(appBuildVersion);
      }
    } catch (e) {
      versionCode = 1000.0;
    }
    return versionCode;
  }

  getVersionName(): string {
    let appVersion: string = environment.webVersion?.toString();
    try {
      let appBuildVersion: string = window.localStorage.getItem('app-build-version-name');
      if (appBuildVersion && appBuildVersion.length > 0) {
        appVersion = appVersion + '_' + appBuildVersion;
      }
    } catch (e) {
      appVersion = environment.webVersion?.toString();
    }
    return appVersion;
  }

  checkEvents(event: string) {
    const events: any = [
      TraderEventType.Payment_status_success,
      TraderEventType.Collect_status_success,
      TraderEventType.Record_cash_collect_success,
      TraderEventType.Record_cash_pay_success,
      TraderEventType.Add_party_success,
      TraderEventType.Add_trade_success,
      TraderEventType.Add_money_status_success,
    ];
    const userId = this._storage.authData?.systemUserId;
    if (event === RefreshCustomEvents.VisitOrdersPage) {
      this.storeFacade.loadOrders(userId);
    } else if (events.includes(event)) {
      this.storeFacade.reloadDashboard();
      this.storeFacade.reloadTransactions();
      this.storeFacade.reloadOrders();
      this.storeFacade.reloadParties();
    }
  }

  triggerTraderEvent(traderEventType: string, customEventDetails: any, navDetails: string) {
    if (!this.modal) {
      this.modal = this.createAwsEventModal();
    }
    this.modal.visitTime = new Date(Date.now()).getTime();
    this.modal.customEventDetails = customEventDetails;
    this.trigger(traderEventType);
  }

  trigger(traderEventType: string) {
    this.checkEvents(traderEventType);
    if (!this.modal) return;
    const metaData = TRADER_EVENT_SCHEMA_DETAIL[traderEventType];
    if (metaData) {
      this.modal.customEventSchemaId = metaData.customEventSchemaId;
      this.modal.customEventSchemaVersion = metaData.customEventSchemaVersion;
      this.modal.eventType = metaData.eventType;
      this.modal.pageId = this.pageId;
      this.modal.sourceType = 'TRADER_APP';
      this.modal.creationTime = new Date(Date.now()).getTime();
      this.modal.userId = this._storage.authData?.userId;
      // if (otherConfigs) {
      //   Object.entries(otherConfigs).forEach(([key, value]) => {
      //     this.modal[key] = value;
      //   });
      // }
      // console.log(this.command, this.command.input);
      this.command = new SendMessageCommand({
        QueueUrl: environment.awsSqsEntity.queueUrl,
        MessageBody: JSON.stringify(this.modal),
      });
      // this.command = {
      //   ...this.command,
      //   QueueUrl: environment.awsSqsEntity.queueUrl,
      //   input: {
      //     MessageBody: JSON.stringify(this.modal)
      //   }
      // };
      // gamification.track(traderEventType, {
      //   eventData: this.command,
      //   environment,
      //   ...this._storage.authData,
      // });
      this.customEventDetails = this.modal.customEventDetails;
      delete this.modal.customEventDetails;
      const cleverTapCustomEventData = { ...this.modal, ...this.customEventDetails };

      this.cleverTap.pushCustomEvent(traderEventType, cleverTapCustomEventData, this.modal);
      this.hanselService.registerCustomEvent(traderEventType, this.modal.customEventDetails);
    }
    this.storeFacade.triggerEvent(traderEventType, {
      eventData: this.command,
      environment,
      ...this._storage.authData,
    });
    // this.client.send(this.command).then((res) => res);
  }
}
