import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { NinjaTabLinkVM } from './ninja-tabs.modal';

@Component({
  selector: 'ninja-tabs',
  templateUrl: './ninja-tabs.component.html',
  styleUrls: ['./ninja-tabs.component.scss']
})
export class NinjaTabsComponent implements OnInit {

  @Input() id;
  @Input() cssClass;
  @Input() tabLinks: NinjaTabLinkVM[] = [];
  @Input() activeLink: NinjaTabLinkVM;
  @Input() background: ThemePalette = undefined;
  @Output() change: EventEmitter<NinjaTabLinkVM> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.activeLink && this.tabLinks?.length > 0) {
      this.activeLink = this.tabLinks[0];
    }
  }

  onClickLink(link: NinjaTabLinkVM) {
    this.change.emit(link);
    this.activeLink = link;
  }
}
