import { Component, OnInit, Input } from '@angular/core';
import { NinjaGridColumnDef } from './ninja-grid';

@Component({
  selector: 'ninja-grid',
  templateUrl: './ninja-grid.component.html',
  styleUrls: ['./ninja-grid.component.scss']
})
export class NinjaGridComponent implements OnInit {

  @Input() data: any;
  @Input() columnDefs: NinjaGridColumnDef[];

  constructor() { }

  ngOnInit(): void {
  }

}
