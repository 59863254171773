import { PaymentModuleTypes } from '../actions/payment-modules.actions';

type PaymentsInitialState = {
  showPartyAccountDetails: boolean;
  user: object;
};

const initialState: PaymentsInitialState = {
  showPartyAccountDetails: false,
  user: {},
};

export const paymentModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentModuleTypes.Show_Party_Account_Details:
      return {
        ...state,
        showPartyAccountDetails: action.payload.showPartyAccountDetails,
        userId: action.payload.user,
      };

    default:
      return state;
  }
};
