import { ReturnUrlActionTypes } from '../actions/returnUrl.actions';

export type ReturnUrlState = string;

const initialState: string = '';

export const returnUrlReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReturnUrlActionTypes.SetReturnUrl: {
      return action.url;
    }
    default: {
      return state;
    }
  }
};
