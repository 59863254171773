import { Action, createReducer, on } from '@ngrx/store';
import { TransactionActionTypes } from '../actions/transaction-details.actions';

export const transactionDetailsFeatureKey = 'transactionDetails';

const transactionsHistory: any = {
  isLoading: false,
  isSuccessful: false,
  isFailed: false,
  data: null,
};

export const initialState: any = {
  transactionsHistory,
  selectedTransaction: null,
  reloadTransactions: false,
};

export const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TransactionActionTypes.Transaction_Loading:
      return {
        ...state,
        transactionsHistory: {
          isLoading: true,
          isSuccessful: false,
          isFailed: false,
          data: null,
          reloadTransactions: false,
          isLoadMore: false,
          isEmptyData: false,
        },
      };
    case TransactionActionTypes.Transaction_Fulfilled:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: true,
          isFailed: false,
          data: action.payload,
          reloadTransactions: false,
          isLoadMore: false,
          isEmptyData: false,
        },
      };
    case TransactionActionTypes.Transaction_Failed:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: false,
          isFailed: true,
          data: null,
          reloadTransactions: false,
          isLoadMore: false,
          isEmptyData: false,
        },
      };
    case TransactionActionTypes.Transaction_Selected:
      return {
        ...state,
        selectedTransaction: action.payload,
      };
    case TransactionActionTypes.Transaction_LoadNextSet:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: false,
          isFailed: false,
          data: action.payload,
          reloadTransactions: true,
          isLoadMore: true,
          isEmptyData: false,
        },
      };
    case TransactionActionTypes.Transaction_EmptyDataReceived:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: false,
          isFailed: false,
          data: action.payload,
          reloadTransactions: false,
          isLoadMore: false,
          isEmptyData: true,
        },
      };
    case TransactionActionTypes.Transaction_EmptyDataReceived_Reset:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: false,
          isFailed: false,
          data: action.payload,
          reloadTransactions: false,
          isLoadMore: false,
          isEmptyData: false,
        },
      };
    case TransactionActionTypes.Transaction_Reload:
      return {
        ...state,
        transactionsHistory: {
          isLoading: false,
          isSuccessful: false,
          isFailed: false,
          data: null,
          reloadTransactions: true,
          isLoadMore: false,
          isEmptyData: false,
        },
      };
    default:
      return state;
  }
};
