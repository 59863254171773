import { Action } from '@ngrx/store';
export enum AuthActionsTypes {
  Logout = 'Logout',
}

export class Logout implements Action {
  readonly type = AuthActionsTypes.Logout;
  constructor() {}
}

export type AuthActions = Logout;
