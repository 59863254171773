import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaTabsComponent } from './ninja-tabs.component';
import { MatTabsModule } from '@angular/material/tabs';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaTabsComponent],
  imports: [
    CommonModule,
    MatTabsModule
  ],
  exports: [MatTabsModule, NinjaTabsComponent]
})
export class NinjaTabsModule { }
