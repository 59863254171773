import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfflinePageComponent } from './offline-page.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [OfflinePageComponent],
  imports: [CommonModule, TranslateModule],
  exports: [OfflinePageComponent],
})
export class OfflinePageModule {}
