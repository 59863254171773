import { environment } from 'src/environments/environment';

const QA_CONFIG = [];
const PROD_CONFIG = [
  1221695, 1647196, 1593550, 1712267, 1726671, 1770152, 1786068, 1801224, 1100, 1750929, 1598134, 1749314, 1743615,
  1783925, 1745311, 1749317, 1754696, 1754695, 1799852, 1754705, 1749313, 1747843, 1601243, 1277487, 1751110, 1654468,
  1774223, 1673576, 1783924, 1781673, 1789185, 1799726, 1770150, 1740076, 1791304, 1645070, 1754580, 1311509, 1778229,
  1794776, 1791267, 88043, 1779101, 1781844, 1600018, 1752148, 1809738, 1797911, 1790174, 1806029, 1796383, 1791301,
  1605458, 1796904, 1798507, 1785622, 1796646, 1797398, 1794185, 1810154, 1810107, 1810734, 1810068, 1810816, 1747483,
  1780192, 1779004, 1774666, 1781370, 1779540, 1814648, 1810107, 1815333, 1808960, 1781702, 1783830, 1782917, 1781712,
  1776006, 1796339, 1796873, 1798515, 1792933, 1799117, 1786359, 1786517, 1266508, 164675, 1794177, 44591, 1782844,
  1791970, 1792256, 1792274, 1767969,
];

export const ReleaseGenericPayToAll = environment.production ? false : true;
export const GENERIC_PAY_ENABLED_USERS = environment.production ? PROD_CONFIG : QA_CONFIG;
