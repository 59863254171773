import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommerceSdkComponent } from '../commerce-sdk/commerce-sdk.component';
import { StorageService } from '../_handlers/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from '../_handlers/global.service';
@Injectable({
  providedIn: 'root',
})
export class PartiesService {
  private workflow_base_url = environment.apiUrl.workflow;
  private contact_base_url = environment.apiUrl.contact;

  showDownloadDialog: Subject<boolean> = new Subject();

  constructor(
    private storage: StorageService,
    private httpClient: HttpClient,
    private bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    private _global: GlobalService
  ) {}

  navigateToVCommerce(org_id?) {
    console.log(org_id);
    this._global.openCommerceSdk.next({
      navigate: `/profile/detail/${org_id}`,
    });
  }

  getPartieData(): Observable<any> {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow_base_url}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/to-collect-pay`;
    return this.httpClient.post(url, {}).pipe(map((m: any) => m));
  }

  getParties(requestBody?: any) {
    const realmObj = this.storage.realmOwnerData;
    const userId = this.storage.authData.userId;
    return this.httpClient.post<any>(
      `${this.workflow_base_url}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/parties-elastic-search-service-config`,
      requestBody
    );
  }
  getContact(requestBody?: any) {
    const realmObj = this.storage.realmOwnerData;
    const userId = this.storage.authData.userId;
    return this.httpClient.post<any>(
      `${this.workflow_base_url}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/contacts-elastic-search-service-config`,
      requestBody
    );
  }
  getBusiness(requestBody?: any) {
    const realmObj = this.storage.realmOwnerData;
    const userId = this.storage.authData.userId;
    return this.httpClient.post<any>(
      `${this.workflow_base_url}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/org-elastic-search-service-config`,
      requestBody
    );
  }
  getRealmStatus(requestBody) {
    const realmObj = this.storage.realmOwnerData;
    const userId = this.storage.authData.userId;
    return this.httpClient.post<any>(
      `${this.workflow_base_url}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/trader-realm-status`,
      requestBody
    );
  }

  //toast
  openSnackBar(message: string, action: string, duration: number) {
    this._snackBar.open(message, action, { duration: duration });
  }

  // count by party

  getNWPartyList(pageNo: number, pageSize: number) {
    const realmObj = this.storage.realmOwnerData;
    const systemUserId = this.storage.authData.systemUserId;
    try {
      console.log('url to hit is', `${this.contact_base_url}/${realmObj?.realmIdentifier}/${systemUserId}/party`);
      return this.httpClient.get(`${this.contact_base_url}/${realmObj?.realmIdentifier}/${systemUserId}/party`, {
        params: { pageNo, pageSize },
      });
    } catch (err) {
      throw err;
    }
  }

  async checkESUserPartyExists(systemUserId: string): Promise<boolean> {
    let reqbody = {
      from: 0,
      size: 0,
      query: {
        term: { 'payload.Party.userId': systemUserId },
      },
    };
    let partyCount = await this.getParties(reqbody).toPromise();
    try {
      if (partyCount.data?.hits?.total?.value > 0) return true;
      else return false;
    } catch (err) {
      throw err;
    }
  }

  async checkESUserContactExists(systemUserId: string): Promise<boolean> {
    try {
      let reqbody = {
        from: 0,
        size: 0,
        query: {
          term: { 'payload.Contact.userId': systemUserId },
        },
      };
      let contactCount = await this.getContact(reqbody).toPromise();
      try {
        if (contactCount.data?.hits?.total?.value > 0) return true;
        else return false;
      } catch (err) {
        throw err;
      }
    } catch (err) {
      throw err;
    }
  }

  getLSContact(): any[] {
    try {
      let contactArr = [];
      let contactStr = localStorage.getItem('ninja-trader-contact-list');
      if (contactStr) {
        try {
          contactArr = JSON.parse(contactStr);
        } catch (err) {
          contactArr = [];
        }
      }
      return contactArr;
    } catch (err) {
      throw err;
    }
  }

  getNetReceivableAmount() {
    const realmObj = this.storage.realmOwnerData;
    const userId = this.storage.authData.userId;
    return this.httpClient
      .post(
        `${this.workflow_base_url}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-order-outstanding-against-user`,
        {
          input: {
            outputTemplate: 'trader-app-fetch-filter-v2_1',
          },
        }
      )
      .pipe(map((m: any) => m));
  }
}
