import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaWhatsappComponent } from './ninja-whatsapp.component';

@NgModule({
  declarations: [NinjaWhatsappComponent],
  imports: [CommonModule],
  exports: [NinjaWhatsappComponent],
})
export class NinjaWhatsappModule {}
