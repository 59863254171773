import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaDatepickerComponent } from './ninja-datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [NinjaDatepickerComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule 
  ],
  exports: [NinjaDatepickerComponent]
})
export class NinjaDatepickerModule { }
