import { Action } from '@ngrx/store';
import { PartiesFullfillmentModel } from '../facades/parties-v2.facade';

export enum PartiesV2ActionTypes {
  V2_Parties_Loading = 'V2_Parties_Loading',
  V2_Parties_Fulfilled = 'V2_Parties_Fulfilled',
  V2_Parties_Failed = 'V2_Parties_Failed',
  V2_Reload_Parties = 'V2_Reload_Parties',
}

export class PartiesLoading implements Action {
  readonly type = PartiesV2ActionTypes.V2_Parties_Loading;
  constructor() {}
}

export class PartiesFulfilled implements Action {
  readonly type = PartiesV2ActionTypes.V2_Parties_Fulfilled;
  constructor(public data: PartiesFullfillmentModel) {}
}

export class PartiesFailed implements Action {
  readonly type = PartiesV2ActionTypes.V2_Parties_Failed;
  constructor() {}
}

export class ReloadParties implements Action {
  readonly type = PartiesV2ActionTypes.V2_Reload_Parties;
  constructor() {}
}

export type PartiesV2Actions = PartiesLoading | PartiesFulfilled | PartiesFailed | ReloadParties;
