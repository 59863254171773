import { StorageService } from 'src/app/_handlers/storage.service';
import { Component } from '@angular/core';
import { DashboardFacade } from '../../../store/facades/Dashboard.facade';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { SellerBuyerFacade } from 'src/store/facades/sellerBuyer.facade';
import { PermissionsModalComponent } from 'src/app/main/content/permissions-modal/permissions-modal.component';
import { TWAService } from 'src/app/shared/services/twa.service';
import createTrie from 'autosuggest-trie';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/_services/user.service';
@Component({
  selector: 'iframe-container',
  templateUrl: './iframe.component.html',
})
export class IFrameComponent {
  dashboardData: any;
  isCollectAssureApp = false;
  collectAssureProduct;
  collectAssureIFrameUrl: SafeResourceUrl;
  isSellingTrade: any;
  sellingTradeData: any;
  contractData: any;
  translations: any;
  isKnowMyTrader: any;
  knowMyTraderProduct: any;

  constructor(
    private dashboardFacade: DashboardFacade,
    private sanitize: DomSanitizer,
    private router: Router,
    private _storage: StorageService,
    private sellerBuyerFacade: SellerBuyerFacade,
    private twaService: TWAService,
    public dialog: MatDialog,
    private user: UserService,
    private _translate: TranslateService
  ) {}

  ngOnInit() {
    this.dashboardFacade.dashboardInfo$.subscribe((data) => {
      this.dashboardData = data;
      this.isCollectAssureApp = data?.isCollectAssure;
      this.collectAssureProduct = data?.collectAssureProduct;
      this.isSellingTrade = data?.isSellingTrade;
      this.sellingTradeData = data?.sellingTradeData;
      this.contractData = data?.contractData;
      this.isKnowMyTrader = data?.isKnowMyTrader;
      this.knowMyTraderProduct = data?.knowMyTraderProduct;

      const show_marketing = !this._storage?.userRealmData?.collectAssureProduct;
      let url = `${environment.collectAssure.url}`;
      if (this.collectAssureProduct) {
        url = `${url}?target=${this.collectAssureProduct}&show_marketing=${show_marketing}`;
      }
      if (this.isCollectAssureApp && this.isSellingTrade) {
        url = `${url}/raise-collection-request`;
      }
      if (this.isCollectAssureApp && this.isKnowMyTrader && this.knowMyTraderProduct) {
        url = `${url}?product=${this.knowMyTraderProduct}`;
      }
      console.log(url);
      this.collectAssureIFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(url);

      if (this.isCollectAssureApp) {
        this.postAuthData();
      }
    });

    window.addEventListener('message', (event) => {
      if (this.isCollectAssureApp) {
        const { data = {} } = event;
        const { action } = data;
        console.log('check event', action);
        if (action === 'close_collect_assure') {
          this.dashboardFacade.loadDashboard(1);
          this.dashboardFacade.toggleCollectAssure({
            isCollectAssure: false,
            isSellingTrade: false,
            placeholderToggle: false,
          });
          this._storage.clear('disableCheck');
          this.router.navigate(['/app/content/dashboard']);
          return;
        } else if (action === 'get_auth_data') {
          this.postAuthData();
        } else if (action === 'add_more_selling_trade') {
          const { data = {} } = event;
          const { action, data: CollectAssureData } = data;
          console.log('tester', CollectAssureData);

          this.dashboardFacade.toggleCollectAssure({
            isCollectAssure: false,
            isSellingTrade: true,
            sellingTradeData: CollectAssureData,
            placeholderToggle: true,
          });
          this._storage.disableCheckForCA = 'disable';
          this.router.navigate(['app/content/select-party-new']);
        } else if (action === 'collect_assure_toggle') {
          const { data = {} } = event;
          const { action, data: CollectAssureData } = data;

          this.dashboardFacade.toggleCollectAssure({
            isCollectAssure: false,
            isSellingTrade: false,
            sellingTradeData: CollectAssureData,
            placeholderToggle: false,
          });

          this.router.navigate(['app/content/select-party-new']);
        } else if (action === 'add_byer_info') {
          const { data = {} } = event;
          const { action, data: CollectAssureData } = data;
          this.sellerBuyerFacade.setInfo(CollectAssureData.selectedDefaultInfoPayload);
          this.dashboardFacade.toggleCollectAssure({
            isCollectAssure: false,
            isSellingTrade: true,
            sellingTradeData: CollectAssureData,
            placeholderToggle: true,
          });
          this.router.navigate(['/app/content/additional_buyer-details'], {
            state: {
              partyId: CollectAssureData.selectedDefaultInfoPayload.id,
              disableCheck: 'disable',
              from: 'CollectAssure',
            },
          });
        } else if (action === 'collect_assure_contracts') {
          this.dashboardFacade.toggleCollectAssure({
            isCollectAssure: false,
            isSellingTrade: false,
            placeholderToggle: false,
          });
          this.router.navigate(['/app/content/orders-v2'], { replaceUrl: true });
        } else if (action === 'get_request_data') {
          this.postRequestData({ sellingtrade: this.sellingTradeData, contractData: this.contractData });
        } else if (action === 'get_contacts_permission') {
          this.askContactPermissions();
        }
      }
    });
  }

  private postRequestData(data) {
    const frame = document.querySelector('#collectAssureFrame') as HTMLIFrameElement;
    if (frame) {
      frame.contentWindow.postMessage({ call: 'sendData', value: data }, `${environment.collectAssure.url}`);
    }
  }

  private postAuthData() {
    const frame = document.querySelector('#collectAssureFrame') as HTMLIFrameElement;
    if (frame) {
      frame.contentWindow.postMessage(
        { call: 'sendValue', value: localStorage.authData },
        `${environment.collectAssure.url}`
      );
    }
  }

  async getResourceAsync() {
    return await this._translate.get('resource').toPromise();
  }

  private async askContactPermissions() {
    try {
      this.translations = await this.getResourceAsync();
      let androidVersion = +window.localStorage.getItem('app-build-version');
      const isPermissionGranted = window.localStorage.getItem('contact-permission-granted');
      if (window['Android'] && androidVersion < 139 && isPermissionGranted == 'false') {
        window['Android'].askContactPermission();
      } else {
        if (
          (isPermissionGranted == 'false' || !isPermissionGranted) &&
          window.localStorage.getItem('app-build-version')
        ) {
          const dialogRef = this.dialog.open(PermissionsModalComponent, {
            data: {
              title: this.translations.contactPermissionTitle,
              description: this.translations.contactPermissionDescription,
            },
          });
          const result = await dialogRef.afterClosed().toPromise();
          if (result) {
            if (this._storage.isTwa) {
              this.twaService.askContactPermission(this._storage.authData);
              await this.twaService.persistPermissionStatuses();
            } else if (window['Android']) {
              window['Android'].askContactPermission();
            } else if (window['Native']) {
              window['Native'].askContactPermission();
            }
          }
        }
      }
    } catch (e) {
      console.log('err is ask contact');
    }
  }
}
