export interface KycActionSummaryVM {
  action_id: number;
  action_name: string;
  status: string;
}

export interface KycDocumentStatusVM {
  document_id: number;
  documentName: string;
  status: string;
  comment: string;
  refDoc: any;
  restrictionMode: string;
  entityType: string;
  docKeyName?: string;
  subDocumentStatus: any;
}
export interface KycActionDetailVM {
  actionId: number;
  actionName: string;
  documentStatus: KycDocumentStatusVM[];
  kycStatus: string;
}

export interface KycEntitySubTypeVM {
  userTypeId: number;
  entityType: string;
  entitySubType: string;
}

export enum KycDocumentTypeEnum {
  Adhaar = 1,
  pan = 3,
  videoVerification = 13, // need to check with backend once configured
  MarketLicence = 4,
}

export enum KycDocumentStatusEnum {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  NotRaised = 'NOT_RAISED',
  Pending = 'PENDING',
}
export enum KycDocumentNameEnum {
  panCard = 'PAN Card',
  businessPanCard = 'Business PAN Card',
  aadhaarCard = 'Aadhaar Card',
}
export enum KycMapDocTypeWithDocName {
  panCard = 'PAN',
  businessPanCard = 'BUSINESS_PAN',
}

export enum KycBusinessTypeEnum {
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  PatnershipType = 'PARTNERSHIP',
  privateType = 'PRIVATE/PUBLIC_LTD',
}

export enum KycTypeEnum {
  Business = 'BUSINESS',
  Individual = 'INDIVIDUAL',
  Lite = 'KYC_LITE',
}

export enum BusinessTypeMapEnum {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  PARTNERSHIP = 'PARTNERSHIP',
  PRIVATE = 'PRIVATE/PUBLIC_LTD',
}

export enum KycEntitySubTypeEnum {
  SOLE_PROPRIETORSHIP_REGISTERED = 'Sole Proprietorship (Registered Firm)',
  SOLE_PROPRIETORSHIP_UNREGISTERED = 'Sole Proprietorship (Unregistered Firm)',
  PARTNERSHIP = 'Partnership',
  PUBLIC_PRIVATE = 'Private/Public Ltd',
  KYC_MINIMUM = 'KYC_MINIMUM',
}

export enum KycBackPage {
  Dashboard = 0,
  Add_money = 1,
  pay_money = 2,
  withdraw_money = 3,
  upcomingOffers = 4,
}

export enum KYC_STATUS {
  PENDING = 1,
  VERIFIED = 2,
  PROGRESS = 3,
}

export enum KYC_LITE_STATUS {
  PENDING = 1,
  VERIFIED = 2,
  PROGRESS = 3,
}

export enum KycUserType {
  TRADER = 'TRADER',
  TRADER_MINIMUM_KYC = 'TRADER_MINIMUM_KYC',
}
