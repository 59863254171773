import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NinjaWhatsappService {
  SendSubject: Subject<{ to: any; messageBody: string; desktop?: boolean }> = new Subject();

  constructor() {}

  send(to, messageBody: string, desktop?: boolean) {
    this.SendSubject.next({
      to,
      messageBody,
      desktop,
    });
  }

  sanitizePhoneNumber(contactNumber) {
    if (contactNumber) {
      contactNumber.replace(/\s/g, '');
      var _includesCountryCode = contactNumber.includes('+91');
      if (!_includesCountryCode) {
        contactNumber = '+91' + contactNumber;
      }
    }
    return contactNumber;
  }
}
