import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaTextBoxComponent } from './ninja-text-box.component';
import { FormsModule } from '@angular/forms';
import { SharedLibModule } from '../shared/shared/shared.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaTextBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedLibModule
  ],
  exports: [NinjaTextBoxComponent]
})
export class NinjaTextBoxModule { }
