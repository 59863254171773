import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { NinjaNotificationService, NinjaSpinnerService, NinjaAlertOptions } from 'ninja-modules';
import { EMPTY } from 'rxjs';
import { StorageService } from '../_handlers/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SPL_REQ_HANDLING } from '../_config/req-handler-config';
import { AwsEventService } from './aws-event.service';
import { Router } from '@angular/router';
import { StoreFacade } from 'src/store/facades/store.facade';
import { TraderEventVM } from '../_modals/event-stream';
import { TraderEventType } from '../_config/event-stream.config';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  resource: any;
  isSplHandling: boolean;
  counter: number = 0;
  constructor(
    private storage: StorageService,
    private translate: TranslateService,
    private spinner: NinjaSpinnerService,
    private notify: NinjaNotificationService,
    private _aws: AwsEventService,
    private router: Router,
    private storeFacade: StoreFacade
  ) {}
  splReqHandler(req) {
    let request = req.split('/');
    if (SPL_REQ_HANDLING.includes(request[request.length - 1])) {
      this.isSplHandling = true;
    } else {
      this.isSplHandling = false;
    }
  }
  catchErrorHandling(err) {
    this.spinner.end();
    this.splReqHandler(err.url);
    if (err) {
      if (!this.isSplHandling) {
        if (err.status !== 0) {
          this._aws.triggerTraderEvent(
            TraderEventType.Generic_event,
            {
              eventName: 'Error_popup',
              refType1: 'PageUrl',
              refType2: 'Api',
              refType3: 'Status',
              refType4: 'ErrorMessage',
              refType5: 'RequestId',
              refKey1: this.router.url,
              refKey2: err?.url,
              refKey3: JSON.stringify(err?.status),
              refKey4: JSON.stringify(
                err?.error?.message || err?.error?.data?.message || err?.error?.errorMessage || err?.message
              ),
              refKey5: JSON.stringify(err?.headers?.get('x-ninjacart-request-id')),
            },
            null
          );
        }
        if (err.status === 401) {
          this.error(err?.status?.toString(), `Your session expired, Please login again`, true).then((res) => {
            if (res.isConfirmed) {
              this.storage.clear('authData');
              this.router.navigate(['/agreement']);
            }
          });
        } else if (err.status === 400) {
          this.errorGeneric(
            err?.status?.toString(),
            err?.error?.message || err?.error?.data?.message || err?.error?.errorMessage || err?.message
          ).then((res) => {});
        } else if (err.status === 0) {
          return;
        } else {
          this.error(err?.status?.toString(), `Something went wrong. Please try after sometime`).then((res) => {
            if (res.isConfirmed) {
              if (this.storage?.authData?.token) {
                this.router.navigate(['/app/content/dashboard']);
              } else {
                this.router.navigate(['/agreement']);
              }
            }
          });
        }
      }
      return EMPTY;
    }
  }
  async error(title?: string, text?: string, redirectToLogin: boolean = false) {
    this.spinner.end();
    this.resource = await this.getResourceAsync();
    let options: NinjaAlertOptions = {
      icon: 'error',
      type: 'failure',
      title: this.resource?.errorMsg,
      text: text || this.resource?.somethingWentWrong,
      allowOutsideClick: false,
    };
    if (redirectToLogin) {
      options.confirmButtonText = this.resource?.loginHere;
      this.storeFacade.resetStore();
    } else {
      this.storeFacade.resetDashboard();
      if (this.router.url !== '/app/content/dashboard' && this.storage?.authData?.token) {
        options.confirmButtonText = this.resource?.gotToHome;
      } else {
        options.confirmButtonText = this.resource?.ok;
      }
    }
    return this.notify.fire(options, false);
  }

  async errorGeneric(title?: string, text?: string) {
    this.resource = await this.getResourceAsync();
    let options: NinjaAlertOptions = {
      icon: 'error',
      type: 'failure',
      title: this.resource?.errorMsg,
      text: text || this.resource?.somethingWentWrong,
    };
    return this.notify.fire(options, false);
  }

  triggerErrorEvent(error: HttpErrorResponse, req: HttpRequest<any>) {
    let errorObj = {
      // userId: urlParam && urlParam.a,
      errorMessage: error && error.message,
      requestBody: req.body,
      requestUrl: req.url,
      requestMethod: req.method,
      responseStatus: error.status,
      responseTime: new Date(Date.now()).getTime(),
      responseBody: error.error,
      errorType: 'SERVER',
    };
    this._aws.modal = this._aws.modal || ({} as TraderEventVM);
    this._aws.triggerTraderEvent(TraderEventType.ServerError, JSON.stringify(errorObj), null);
  }

  async getResourceAsync() {
    return await this.translate.get('resource').toPromise();
  }
}
