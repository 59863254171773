import { Injectable } from '@angular/core';
import { AuthResponseVM } from '../../_modals/auth-response.modal';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { StorageService } from 'src/app/_handlers/storage.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as AuthActions from 'src/store/actions/auth.actions';

@Injectable({ providedIn: 'root' })
export class TWAService {
  twaSessionId: string;
  constructor(
    private db: AngularFireDatabase,
    private storage: StorageService,
    private router: Router,
    private store: Store
  ) {}

  initialize(queryParameters: string) {
    function _extractSessionId(queryParameters: string) {
      const matches = /twaSessionId=(.*?)(&|$)/i.exec(queryParameters);
      if (matches && matches.length > 1) {
        return matches[1];
      }
      return null;
    }

    if (this.twaSessionId) {
      // @ts-ignore
      window.localStorage?.['twaSessionId'] = this.twaSessionId;
      this.persistLocalValueStatuses();
      return;
    }
    let sessionId = _extractSessionId(queryParameters);
    this.twaSessionId = sessionId;
    if (sessionId == null) {
      return;
    }
    // @ts-ignore
    window.localStorage?.['twaSessionId'] = sessionId;
    const isTwa = /isTwa=(.*?)(&|$)/i.exec(window.location.search);
    window.localStorage.setItem('isTwa', JSON.stringify(JSON.parse(isTwa[1])));
    const matches = /rebootTime=(.*?)(&|$)/i.exec(window.location.search);
    const epochNow = new Date().getTime();
    window.localStorage.setItem('loadTime', JSON.stringify((epochNow - JSON.parse(matches[1])) / 1000));

    this.persistLocalValueStatuses();
  }

  triggerIntent(host: String, scheme: String, data: object) {
    const resultUrl = self.document.location.href;
    const hyperlink = self.document.createElement('a');
    const intentData = encodeURIComponent(JSON.stringify({ ...data, resultUrl }));
    hyperlink.href = `intent://${host}#Intent;scheme=${scheme};package=com.nc.trader;S.data=${intentData};end`;
    hyperlink.click();
  }

  processResultFromEncodedFragment(fragment: string, resultCallback: (stringifiedJSONResult: string) => void) {
    if (fragment == null) {
      return;
    }
    try {
      const jsonMessage = JSON.parse(atob(fragment));
      this._processResultMessage(jsonMessage, resultCallback);
    } catch (e) {}
  }

  sendWhatsAppMessage(number: string, msg: string) {
    this.triggerIntent('open', 'whatsapp', { number, msg });
  }

  shareAppLink(title: string, body: string, referralCode: string) {
    this.triggerIntent('open', 'firebase_d_link', {
      title,
      body,
      referralCode,
    });
  }

  notifyUserLogin(authResponse: AuthResponseVM) {
    this.triggerIntent('receive', 'user', authResponse);
  }

  notifyUserLoggedOut() {
    this.triggerIntent('receive', 'logout', {});
  }

  shareAndroid(msg: string) {
    this.triggerIntent('open', 'share_apps', { msg });
  }

  shareFromMessage(msg: string, number: string) {
    this.triggerIntent('open', 'inapp_msg', { number, msg });
  }

  dialPhoneNumber(number: string) {
    this.triggerIntent('action', 'dial', { number });
  }

  downloadFileWithBase64(data: string, fileName: string) {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    a.target = '_blank';
    a.click();
  }

  deleteFileFromStorage(fileName: string) {
    this.triggerIntent('action', 'delete_file', { fileName });
  }

  askContactPermission(authData: AuthResponseVM) {
    this.triggerIntent('permission', 'contact', authData);
  }

  askStoragePermission() {
    this.triggerIntent('permission', 'storage', {});
  }

  checkCameraPermission() {
    this.triggerIntent('permission', 'camera', {});
  }

  askForLocationPermission() {
    this.triggerIntent('permission', 'location', {});
  }

  monitorFirebaseMessage(namespace: string, resultCallback: (stringifiedJSONResult: string) => void) {
    const sessionId = window.localStorage?.['twaSessionId'] ?? null;
    if (sessionId == null) {
      return;
    }
    const sessionMessagesObject = this.db.object(sessionId.toString());
    const sessionMessagesObserver = sessionMessagesObject.valueChanges();
    const subscription = sessionMessagesObserver.subscribe((value) => {
      if (value == null) {
        return;
      }
      try {
        const valueObject: object = value as object;
        const jsonMessageInNamespace = valueObject[namespace];
        if (jsonMessageInNamespace != null) {
          this._processResultMessage(jsonMessageInNamespace, resultCallback);
          sessionMessagesObject.set({ ...valueObject, [namespace]: {} });
        }
      } catch (e) {
      } finally {
        subscription.unsubscribe();
      }
    });
  }

  _processResultMessage(jsonMessage: object, resultCallback: (stringifiedJSONResult: string) => void) {
    try {
      const resultForCallback = JSON.stringify(jsonMessage);
      resultCallback(resultForCallback);
    } catch (e) {}
  }

  openInstaBiz() {
    this.triggerIntent('open', 'icic_instabiz', {});
  }

  async persistPermissionStatuses() {
    return new Promise<void>((resolve) => {
      this.monitorFirebaseMessage('permissions', (permissionsJSON) => {
        const permissions = JSON.parse(permissionsJSON);
        for (let permission of permissions) {
          // @ts-ignore
          window.localStorage?.[permission.name] = permission.value;
        }
        resolve();
      });
    });
  }

  persistLocalValueStatuses() {
    this.monitorFirebaseMessage('localValues', (localValuesJSON) => {
      const localValues = JSON.parse(localValuesJSON);
      for (let localValue of localValues) {
        // @ts-ignore
        window.localStorage?.[localValue.name] = localValue.value;
      }
      if (this.storage.clearTwaCache && this.storage.authData) {
        this.storage.clearAll();
        this.router.navigate(['/select-language']);
        this.store.dispatch(new AuthActions.Logout());
        this.initialize(window.location.search);
        this.notifyUserLoggedOut();
      }
    });
  }
}
