import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ninja-datepicker',
  templateUrl: './ninja-datepicker.component.html',
  styleUrls: ['./ninja-datepicker.component.css']
})
export class NinjaDatepickerComponent implements OnInit {

  @Input() id;
  @Input() cssClass;
  @Input() label = '';
  @Input() textType;
  @Input() cssProperties;
  @Input() placeholder = '';
  @Input() showCross = false;
  @Input() disabled = false;
  @Input() crossCssProperties;
  @Output('change') change: EventEmitter<any> = new EventEmitter();
  
  onChange: any = () => { };
  onTouched: any = () => { };
  value: any;

  constructor() { }

  ngOnInit(): void {
  }

  inputValue(e) {
    this.onChange(e.target.value);
    this.change.emit({
      event: e,
      value: e.target.value
    });
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
