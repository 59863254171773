import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NinjaNotificationService, NinjaSpinnerService } from 'ninja-modules';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpiParamDetail } from '../main/content/payment/add-money/modules/upiParamDetail';
import { StorageService } from 'src/app/_handlers/storage.service';
import { HttpApiResponseVM } from '../_modals/http-api-response';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from './error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class AddMoneyService {
  userId: number;
  realmId: string;
  resource: any;
  private workflowUrl: string;
  private paymentUrl: string;
  private stagingOdinUrl: string;
  private finsUrl: string;
  private upiTransactionDetail: UpiParamDetail;
  private neftChecked: boolean;
  private balanceSubject = new Subject<number>();
  private verifyUpiSubject = new Subject<any>();
  paymentObject: any;
  public cashTransIds: any = [];
  private vanUrl: string;

  constructor(
    private spinner: NinjaSpinnerService,
    private translate: TranslateService,
    private storage: StorageService,
    private notify: NinjaNotificationService,
    private http: HttpClient,
    private handler: HttpBackend,
    private error: ErrorHandlingService
  ) {
    this.workflowUrl = environment.apiUrl.workflow;
    this.vanUrl = environment.apiUrl.van;
    this.paymentUrl = environment.apiUrl.payment;
    this.finsUrl = environment.apiUrl.fintrans;
    this.userId = this.storage.authData?.userId;
    this.realmId = this.storage.realmOwnerData?.realmIdentifier;
    this.stagingOdinUrl = environment.apiUrl.stagingOdin;
  }

  fetchAccountDetails(): Observable<any> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${authData.userId}/v1/execution/service/run/trader-wallet-info`;
    return this.http.post(url, {}, { headers: { skip: 'true' } }).pipe(map((m: any) => m));
  }

  fetchAccountDetailsV2(): Observable<any> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.vanUrl}/${realmObj.realmIdentifier}/${authData.systemUserId}/account/self/all`;
    return this.http.get(url);
  }

  fetchAdditionalBuyersDetails(): Observable<HttpApiResponseVM> {
    let url = `${this.stagingOdinUrl}/ch-facility-category-map/facilities-metadata`;
    let contactHttp = new HttpClient(this.handler);
    return contactHttp
      .get(url, {
        headers: {
          Authorization: `Basic bWF5YW5rOmNyQHp5bUBydmVs`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  fetchNoAuthAccountDetails(payload): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${authData.userId}/v1/execution/service/runWithNoAuth/trader-wallet-info`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  fetchTransactionDetails(params): Observable<any> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.paymentUrl}/${realmObj.realmIdentifier}/${authData.systemUserId}/payments/request`;
    return this.http.post(url, params).pipe(map((m: any) => m));
  }

  verifyUpiDetails(params) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let verifyHttp: HttpClient = new HttpClient(this.handler);
    let url = `${this.paymentUrl}/${realmObj.realmIdentifier}/${authData.systemUserId}/upi/provider/1/verify`;
    return verifyHttp.post(url, params).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  postPaymentUTR(payload): Observable<any> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let reconUpiHttp: HttpClient = new HttpClient(this.handler);
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${authData.userId}/v1/execution/service/run/upi-recon`;
    return reconUpiHttp
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(map((m: any) => m));
  }

  requestUpiPayment(payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${authData.userId}/v1/execution/service/run/upi-collect`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  fetchPaymentStatus(payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${authData.userId}/v1/execution/service/run/fetch-transactions-by-time`;
    return this.http.post(url, payload).pipe(map((m: any) => m));
  }

  postTransactionStatus(transactionId, params) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.finsUrl}/${realmObj.realmIdentifier}/${authData.systemUserId}/cashTransactions/update/status/${transactionId}`;
    return this.http.post(url, params).pipe(map((m: any) => m));
  }

  getCreditedTransactions(): Observable<any> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.finsUrl}/${realmObj.realmIdentifier}/${authData.systemUserId}/cashTransactions/unTagged/credits?latestFirst=true&sendExtraDetails=true`;
    return this.http.get(url);
  }

  tagCreditedTransaction(rawdata): Observable<any> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${authData.userId}/v1/execution/service/run/tag-transaction`;
    let payload = {
      input: {
        accountHolderName: rawdata.ac_name,
        accountNumber: rawdata.vpa ? null : rawdata.ac_no,
        ifsc: rawdata.vpa ? null : rawdata.ifsc,
        vpa: rawdata.vpa,
        payeeUserId: rawdata.payeeUid,
        referenceType: rawdata.refType ? rawdata.refType : 'PARTY',
        referenceTypeId: rawdata.id,
        transactionId: rawdata.cashTransactionId,
      },
    };
    return this.http.post(url, payload);
  }

  updateCreditedTransactionStatus(transId: number): Observable<any> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.finsUrl}/${realmObj.realmIdentifier}/${authData.systemUserId}/cashTransactions/add/additional/${transId}`;
    let payload = [
      {
        key: 'IGNORE_TAGGING',
        value: true,
      },
    ];
    return this.http.post(url, payload);
  }

  setUpiTransactionDetail(upiTransactionDetail: UpiParamDetail) {
    this.upiTransactionDetail = upiTransactionDetail;
  }

  getUpiTransactionDetail(): UpiParamDetail {
    return this.upiTransactionDetail;
  }

  setNeftChecked(neftChecked: boolean) {
    this.neftChecked = neftChecked;
  }

  isNeftChecked(): boolean {
    return this.neftChecked;
  }

  sendBalanceRequest(value: number) {
    this.balanceSubject.next(value);
  }

  getBalanceRequest(): Observable<number> {
    return this.balanceSubject.asObservable();
  }

  getVerifyUpiRequest(): Observable<any> {
    return this.verifyUpiSubject.asObservable();
  }

  setPaymentObj(paymentObject) {
    this.paymentObject = paymentObject;
  }

  getPaymentObj() {
    return this.paymentObject;
  }

  catchError(error: HttpErrorResponse) {
    // this.spinner.end();
    // this.verifyUpiSubject.next();
    // this.error(
    //   error?.status?.toString(),
    //   error?.error?.message || error?.message
    // ).then((res) => {
    //   if (res.isConfirmed) {
    //     history?.back();
    //   }
    // });
    // return EMPTY;
    this.error.catchErrorHandling(error);
  }

  // async error(title?: string, text?: string) {
  //   this.resource = await this.getResourceAsync();
  //   return this.notify.fire({
  //     icon: 'error',
  //     type: 'failure',
  //     title: this.resource?.errorMsg,
  //     text: this.resource?.goBackHomeTryAgain, //text
  //     confirmButtonText: this.resource?.gotToHome,
  //   });
  // }

  clearPaymentDetails() {
    // this.paymoneyService.clearPaymentDetails();
    this.setNeftChecked(true);
    this.setPaymentObj(null);
    this.setUpiTransactionDetail(null);
  }
  async getResourceAsync() {
    return await this.translate.get('resource').toPromise();
  }
}
