import { Action } from '@ngrx/store';
import { OrderActions } from './Orders.actions';

export enum QrCodeActionType {
  Show_Qr_Code = 'Show_Qr_Code',
  Hide_Qr_Code = 'Hide_Qr_Code',
  Show_Qr_Scanner = 'Show_Qr_Scanner',
  Hide_Qr_Scanner = 'Hide_Qr_Scanner',
  Show_Qr_Amount_Validation = 'Show_Qr_Amount_Validation',
  Hide_Qr_Amount_Validation = 'Hide_Qr_Amount_Validation',
  Show_Generic_Pay = 'Show_Generic_Pay',
  Hide_Generic_Pay = 'Hide_Generic_Pay',
  Reset_Qr_State = 'Reset_Qr_State',
  Show_Self_Transfer = 'Show_Self_Transfer',
  Hide_Self_Transfer = 'Hide_Self_Transfer',
}

export class ShowQrCode implements Action {
  readonly type = QrCodeActionType.Show_Qr_Code;
  constructor(public upiId: any, public collectFrom: string) {}
}

export class HideQrCode implements Action {
  readonly type = QrCodeActionType.Hide_Qr_Code;
  constructor() {}
}

export class ShowQrScanner implements Action {
  readonly type = QrCodeActionType.Show_Qr_Scanner;
  constructor() {}
}

export class HideQrScanner implements Action {
  readonly type = QrCodeActionType.Hide_Qr_Scanner;
  constructor() {}
}

export class ShowQrAmountValidation implements Action {
  readonly type = QrCodeActionType.Show_Qr_Amount_Validation;
  constructor() {}
}

export class HideQrAmountValidation implements Action {
  readonly type = QrCodeActionType.Hide_Qr_Amount_Validation;
}

export class ShowGenericPay implements Action {
  readonly type = QrCodeActionType.Show_Generic_Pay;
  constructor() {}
}

export class HideGenericPay implements Action {
  readonly type = QrCodeActionType.Hide_Generic_Pay;
  constructor() {}
}

export class ResetState implements Action {
  readonly type = QrCodeActionType.Reset_Qr_State;
  constructor() {}
}
export class ShowSelfTransfer implements Action {
  readonly type = QrCodeActionType.Show_Self_Transfer;
  constructor() {}
}

export class HideSelfTransfer implements Action {
  readonly type = QrCodeActionType.Hide_Self_Transfer;
  constructor() {}
}

export type QrCodeActions =
  | ShowQrCode
  | HideQrCode
  | ShowQrCode
  | HideQrCode
  | ShowQrAmountValidation
  | ShowGenericPay
  | HideGenericPay
  | ShowSelfTransfer
  | HideSelfTransfer
  | ResetState;
