// import { PartiesDataModel } from 'tecxprt-lib/lib/common/invitation-list/invitation-list.page';
import { PartiesV2ActionTypes } from '../actions/parties-v2.actions';
import { PartiesFullfillmentModel } from '../facades/parties-v2.facade';

export interface PartiesV2InitialState {
  partiesData: any[];
  isLoading: boolean;
  isFulfilled: boolean;
  isFailed: boolean;
  reloadParties: boolean;
  es_data_available: boolean;
  lastDataLoadedAt: Date;
}

export type PartiesV2State = PartiesV2InitialState;

const initialState: PartiesV2InitialState = {
  partiesData: [],
  isFailed: false,
  isFulfilled: false,
  isLoading: false,
  reloadParties: false,
  es_data_available: false,
  lastDataLoadedAt: new Date(),
};

export const partiesV2Reducer = (state = initialState, action) => {
  switch (action.type) {
    case PartiesV2ActionTypes.V2_Parties_Loading: {
      return {
        ...state,
        partiesData: [],
        isLoading: true,
        isFulfilled: false,
        isFailed: false,
        reloadParties: false,
      };
    }
    case PartiesV2ActionTypes.V2_Parties_Fulfilled: {
      return {
        ...state,
        partiesData: (action.data as PartiesFullfillmentModel).data,
        es_data_available: (action.data as PartiesFullfillmentModel).es_data_available,
        isLoading: false,
        isFulfilled: true,
        isFailed: false,
        reloadParties: false,
        lastDataLoadedAt: new Date(),
      };
    }
    case PartiesV2ActionTypes.V2_Parties_Failed: {
      return {
        ...state,
        partiesData: {},
        isLoading: false,
        isFulfilled: false,
        isFailed: true,
        reloadParties: false,
      };
    }
    case PartiesV2ActionTypes.V2_Reload_Parties:
      return {
        ...state,
        reloadParties: true,
      };
    default: {
      return state;
    }
  }
};
