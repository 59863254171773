import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NinjaSpinnerService } from './ninja-spinner.service';

@Component({
  selector: 'ninja-spinner',
  templateUrl: './ninja-spinner.component.html',
  styleUrls: ['./ninja-spinner.component.scss']
})
export class NinjaSpinnerComponent implements OnInit {

  loading: boolean = false;
  $spinner: Subscription;
  @Input() loadingUrl: string;
  @Input() loadingText: string;

  constructor(private appSpinnerService: NinjaSpinnerService) { }

  ngOnInit(): void {
    this.$spinner = this.appSpinnerService.spinnerSubject.subscribe(res => this.loading = res);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
    this.$spinner.unsubscribe();
  }

}
