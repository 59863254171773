import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'offline-page',
  templateUrl: './offline-page.component.html',
  styleUrls: ['./offline-page.component.scss'],
})
export class OfflinePageComponent implements OnInit {
  assetsPath;

  constructor() {}

  ngOnInit(): void {
    this.assetsPath = environment.assetsPath;
  }
}
