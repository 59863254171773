import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StorageService } from '../../_handlers/storage.service';

import { GlobalService } from '../../_handlers/global.service';
@Component({
  selector: 'iframe-mandi',
  templateUrl: './iframe.component.html',
})
export class IFrameComponent {
  mandiUrl: SafeResourceUrl;
  resource: any;

  constructor(
    private sanitize: DomSanitizer,
    private _translate: TranslateService,
    public dialog: MatDialog,
    public global: GlobalService,
    private router: Router,
    private storage: StorageService
  ) {
    this._translate.get('resource')?.subscribe((resource) => (this.resource = resource));
  }

  ngOnInit() {
    let url = `${environment.mandiNews.url}auto_login?mobile=${this.storage.realmOwnerData.contactNumber}&system_user_id=${this.storage.realmOwnerData.systemUserId}&user_realm_id=${this.storage.realmOwnerData.userRealmId}&name=${this.storage.realmOwnerData.realmName}&realm_owner_name=${this.storage.realmOwnerData.realmName}`;
    this.mandiUrl = this.sanitize.bypassSecurityTrustResourceUrl(url);
  }

  back() {
    this.global.openMandiNewsApp$.next(false);
    this.router.navigate(['/app/content/dashboard']);
  }
}
