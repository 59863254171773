import { Component } from '@angular/core';
import { Router, NavigationStart, Event as rEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './_handlers/storage.service';
import FastClick from '@onsenui/fastclick';
import { NinjaTabbarVM } from 'ninja-modules/public-api';
import { environment } from 'src/environments/environment';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DashboardFacade } from '../store/facades/Dashboard.facade';
import { GlobalService } from './_handlers/global.service';
import { NinjaNotificationService, NinjaSpinnerService } from 'ninja-modules';
import _ from 'lodash';
import { QrCodeFacade } from '../store/facades/qrCode.facade';
import { registerLocaleData } from '@angular/common';
import localeIn from '@angular/common/locales/en-IN';
import { AwsEventService } from './_services/aws-event.service';
import { TraderEventType } from 'src/app/_config/event-stream.config';
import { TWAService } from './shared/services/twa.service';
import { UserService } from './_services/user.service';
registerLocaleData(localeIn);
import { HanselService } from './_services/hansel.service';
import { SplitService } from '@splitsoftware/splitio-angular';
import { SplitIo } from './_modals/splitIo.enum';
import { PaymentGuaranteeService } from './_services/payment-guarantee.service';
import { LoanHelpline } from 'src/app/_common/constants';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Trader-Web';
  elem;
  loadingUrl;
  loadingText = 'Loading...';
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  offline: boolean = false;
  isFintechApp: boolean = false;
  isVehicleTrack: boolean = false;
  isMandiApp: boolean = false;
  isMyBrand: boolean = false;
  iFrameUrl: SafeResourceUrl;
  dashboardData = null;
  sdkNavigateUrl: any;
  isCollectAssureApp = false;
  tabs: NinjaTabbarVM[] = [
    {
      icon: 'ion-ios-home',
      text: 'Home',
      route: '/app/content/dashboard',
      active: false,
    },
    {
      icon: 'ion-ios-contacts',
      text: 'Parties',
      route: '/app/content/select-party',
      active: false,
    },
    {
      icon: 'ion-ios-reorder-four',
      text: 'Trades',
      route: '/app/content/orders-v2',
      active: false,
    },
    {
      icon: 'ion-ios-calendar',
      text: 'Transactions',
      route: '/app/content/trans-history',
      active: false,
    },
    // { icon: 'ion-ios-help-circle', text: 'Question', route: '/app/content/question', active: false },
  ];
  splitReady: boolean;
  existPartiesData: any;
  isKnowMyTrader: boolean;
  constructor(
    private router: Router,
    private storage: StorageService,
    private translate: TranslateService,
    private dashboardFacade: DashboardFacade,
    private sanitize: DomSanitizer,
    public global: GlobalService,
    private hanselService: HanselService,
    private notify: NinjaNotificationService,
    private qrFacade: QrCodeFacade,
    private _aws: AwsEventService,
    private twaService: TWAService,
    private userService: UserService,
    private spinner: NinjaSpinnerService,
    private splitService: SplitService,
    private pgService: PaymentGuaranteeService
  ) {
    this.iFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(`${environment.fintech.url}`);
    // router change event
    this.router.events.subscribe((event: rEvent) => {
      if (event instanceof NavigationStart) {
        this.hanselService.registerPageBrowseNudge();
      }
    });
    this.global.openCommerceSdk.subscribe((data) => {
      if (data) {
        if (window['Android'] && window['Android']?.disableAndroidBackButton) {
          window['Android']?.disableAndroidBackButton(true);
        }
        this.global.openAnyApp = true;
        this.sdkNavigateUrl = data?.navigate;
        this.global.iscommerceSdk = true;
      }
    });
  }

  async sdkCloce(event: any) {
    let closeSDKCheck = JSON.parse(event);
    console.log('index event', closeSDKCheck);
    this.global.openAnyApp = false;
    this.global.iscommerceSdk = false;
    this.dashboardFacade.loadDashboard(1);
    if (this.sdkNavigateUrl == 'PG') {
      this.router.navigate(['/app/content/payment-guarantee/about-payment-assured']);
    } else if (this.sdkNavigateUrl == 'Contract') {
      this.router.navigate(['app/content/add-contract']);
    } else if (this.sdkNavigateUrl == 'Loan') {
      this.spinner.start();
      this.userService.checkForBusinessProfileComplete().then((res) => {
        this.spinner.end();
        if (res) {
          this.global.openFintechApp$.next(true);
          this._aws.triggerTraderEvent(
            TraderEventType.Generic_event,
            {
              eventName: 'Apply_loans_footer_initiate',
            },
            null
          );
        } else {
          this.router.navigate(['/app/content/dashboard']);
        }
      });
    } else {
      if (closeSDKCheck) this.router.navigate(['/app/content/dashboard']);
      if (closeSDKCheck.key == 'add-contract') {
        if (this.splitService.getTreatment(SplitIo.PgFeesException) !== 'on') {
          const res = await this.pgService.pgFeesNavigationHandler();
          if (res.length) return;
        }
        this.existPartiesData = closeSDKCheck.value;
        const addTradeFromOrders = {
          selectedParty: this.existPartiesData,
        };
        this.router.navigate(['app/content/add-contract'], {
          state: {
            addTradeFromOrders: addTradeFromOrders,
            sdk: 'commerce-sdk',
          },
        });
      }
    }
    if (window['Android'] && window['Android']?.disableAndroidBackButton) {
      window['Android']?.disableAndroidBackButton(false);
    }
  }

  async ngOnInit() {
    this.qrFacade.resetState();
    // Loans redirection
    try {
      // Get the loans Redirection URL from the location
      let loansRedirectionUrl = window.location.search?.split('loans_url=')[1];
      /**
       * Ex: Digio redirection from loans
       * Redirected trader url: http://192.168.0.7:3000/app/content/dashboard&loans_url=http://192.168.0.7:3000/loans/pending-steps/20138&status=success&digio_doc_id=DID221010122007572RX4H12SFY6YCEV&message=SignedSuccesssfully
       * Here &status=success&digio_doc_id=DID221010122007572RX4H12SFY6YCEV&message=SignedSuccesssfully is added by Digio
       * Digio added & instead of ? as the redirection url already had ?loans_url=
       * but while opening loans the first '&' has to be '?' to be considered as query params.
       */
      if (loansRedirectionUrl?.split('&')[0]?.indexOf('?') === -1) {
        // add ? in case there are query params added during redirection
        loansRedirectionUrl = loansRedirectionUrl.replace(/&/i, '?').replace(/%2F/gi, '/');
      }
      if (loansRedirectionUrl) {
        this.spinner.start();
        this.iFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(`${loansRedirectionUrl}`);
        fetch(loansRedirectionUrl)
          .then((response) => {
            if (response.status === 200) {
              this.spinner.end();
              this.isFintechApp = true;
            }
          })
          .catch(() => {
            this.notify.fire({
              icon: 'error',
              type: 'failure',
              title: 'Error',
              text: 'Failed to load Loans app',
            });
            this.isFintechApp = false;
            this.global.openFintechApp$.next(false);
          });
      }
    } catch (error) {
      console.error(error);
    }

    import('@ninjacart/nc-payments-ui').then((res) => {
      const { Payments } = res;
      setTimeout(() => {
        const userId = _.get(this.storage.authData, 'businessName.traderDTO.userId')
          ? _.get(this.storage.authData, 'businessName.traderDTO.userId')
          : _.get(this.storage.realmOwnerData, 'systemUserId');
        Payments.initializePayments.update({
          ...Payments.initializePayments.data,
          authentication: {
            ...Payments.initializePayments.data.authentication,
            contactNumber: _.get(this.storage.realmOwnerData, 'contactNumber'),
            name: _.get(this.storage.realmOwnerData, 'realmName'),
            env:
              window.location.host === 'traders.ninjacart.in'
                ? 'prod'
                : window.location.host === 'traders.trafyn.site'
                ? 'qa'
                : 'dev',
            userId: userId,
            token: _.get(this.storage.authData, 'token'),
            token_type: _.get(this.storage.authData, 'token_type'),
            realmIdentifier: _.get(this.storage.realmOwnerData, 'realmIdentifier'),
            otherProps: {
              traderName: _.get(this.storage.authData, 'businessName.traderDTO.traderName'),
              existingUser: _.get(this.storage.authData, 'existingUser'),
            },
          },
        });
      }, 3000);
    });
    // Initializing Payments SDK

    setTimeout(() => {
      const frame = document.getElementById('loansIframe') as HTMLIFrameElement;
      if (frame) {
        frame.contentWindow.postMessage(
          { call: 'sendValue', value: localStorage.authData },
          `${environment.fintech.url}/`
        );
      }
    }, 3000);
    this.dashboardFacade.dashboardInfo$.subscribe((data) => {
      this.dashboardData = data;
      this.isKnowMyTrader = data?.isKnowMyTrader;
      this.isCollectAssureApp = data?.isCollectAssure;
      if (this.isCollectAssureApp) {
      }
    });
    window.addEventListener('message', (event) => {
      const { data = {} } = event;
      const { action } = data;
      if (this.isFintechApp) {
        if (action === 'close_fintech') {
          this.iFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(`${environment.fintech.url}`);
          this.global.openFintechApp$.next(false);
          this.isFintechApp = false;
          if (!this.storage.isEdiLoanSummary) {
            this.router.navigate(['/app/content/dashboard']);
          } else {
            this.storage.isEdiLoanSummary = false;
          }
          return;
        } else if (action === 'openKYC') {
          this.global.openFintechApp$.next(false);
          this.isFintechApp = false;
          this.router.navigate(['/e-kyc/select']);
          this._aws.triggerTraderEvent(
            TraderEventType.Generic_event,
            {
              eventName: 'ekyc_upload_initiate',
            },
            null
          );
        } else if (action === 'kyc_lite') {
          this.iFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(`${environment.fintech.url}`);
          this.global.openFintechApp$.next(false);
          this.isFintechApp = false;
          this.router.navigate(['/e-kyc/kyc-lite/loans']);
        } else if (action === 'loan_call_us') {
          if (window && window['Android']) {
            window['Android']?.dialPhoneNumber(LoanHelpline);
          } else if (window['Native']) {
            window['Native']?.dialPhoneNumber(LoanHelpline);
          }
        } else if (action === 'get_detail') {
          this._aws.triggerTraderEvent(
            TraderEventType.Generic_event,
            {
              eventName: 'Apply_loans_success',
            },
            null
          );
          const frame: any = document.getElementById('iframe');
          setTimeout(() => {
            let auth = JSON.parse(localStorage.authData);
            let realmData = JSON.parse(localStorage.realmOwnerData);
            let authData = {};

            if (auth.businessName == undefined || auth.businessName == null) {
              authData = {
                token: auth.token,
                token_type: auth.token_type,
                realmIdentifier: realmData.realmIdentifier,
                existingUser: auth.existingUser,
                userId: auth.systemUserId,
                businessName: realmData.realmName,
                traderName: realmData.realmName,
                name: realmData.realmName,
                contactNumber: realmData.contactNumber,
              };
            } else {
              authData = localStorage.authData;
            }

            frame.contentWindow.postMessage(
              {
                call: 'sendValue',
                value: {
                  authData: localStorage.authData,
                  dashboardData: this.dashboardData.dashboardData,
                  env: environment.fintech.env,
                  languageCode: this.storage.languageCode,
                },
              },
              '*'
            );
          }, 3000);
        }
      } else if (this.isVehicleTrack) {
        if (action === 'load') {
          this.spinner.end();
        } else if (action === 'close_ninja_track') {
          const url = this.router.url.split('?')[0];
          this.global.openVehicleTrackApp$.next(false);
          this.isVehicleTrack = false;
          this.router.navigate([url]);
        } else if (action === 'open_privacy_policy') {
          this.global.openVehicleTrackApp$.next(false);
          this.isVehicleTrack = false;
          this.router.navigate(['/privacy-policy']);
        } else if (window['Native']) {
          const [identifier, value, phnNum] = JSON.parse(action);
          if (identifier === 'dialPhone') {
            window['Native']?.dialPhoneNumber(value);
          } else if (identifier === 'share') {
            window['Native']?.shareAndroid(value);
          } else if (identifier === 'contactAccess') {
            window['Native']?.askContactPermission();
          } else if (identifier === 'locationAccess') {
            window['Native']?.askForLocationPermission();
          } else if (identifier === 'whatsappshare') {
            window['Native']?.sendWhatsAppMessage(phnNum, value);
          } else if (identifier === 'copy') {
            window['Native']?.copiedTextFromWeb(value);
          } else if (identifier === 'sms') {
            window['Native']?.shareFromMessage(value, phnNum);
          }
        }
      }
    });

    this.global.openFintechApp$.subscribe((res) => {
      if (res) {
        fetch(environment.fintech.url)
          .then((response) => {
            if (response.status === 200) {
              if (this.storage.isEdiLoanSummary) {
                this.iFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(
                  `${environment.fintech.url}edi-loan-summary/${this.storage.ediLoanId}?fromQR`
                );
              } else {
                this.iFrameUrl = this.sanitize.bypassSecurityTrustResourceUrl(`${environment.fintech.url}`);
              }
              this.isFintechApp = res;
            }
          })
          .catch(() => {
            this.notify.fire({
              icon: 'error',
              type: 'failure',
              title: 'Error',
              text: 'Failed to load Loans app',
            });

            this.isFintechApp = false;
            this.global.openFintechApp$.next(false);
          });
      }
    });

    this.global.openVehicleTrackApp$.subscribe((res) => {
      if (res && environment.ninjaTrack.url) {
        this.spinner.start();
        fetch(environment.ninjaTrack.url)
          .then((response) => {
            if (response.status === 200) {
              this.isVehicleTrack = res;
            }
          })
          .catch(() => {
            this.spinner.end();
            this.notify.fire({
              icon: 'error',
              type: 'failure',
              title: 'Error',
              text: 'Failed to load Track app',
            });

            this.isVehicleTrack = false;
            this.global.openVehicleTrackApp$.next(false);
          });
      }
    });

    this.global.openMandiNewsApp$.subscribe((res) => {
      if (!res) {
        this.isMandiApp = res;
        return;
      }
      if (res && environment.mandiNews.url) {
        this.isMandiApp = res;
        return;
      }
    });
    this.global.openMyBrandingApp$.subscribe((res) => {
      if (!res) {
        this.isMyBrand = false;
        return;
      }
      if (res && environment.brandLive.url) {
        this.isMyBrand = true;
        return;
      }
    });

    const trackDeepLink = this.storage.notificationDeepLink;

    if (trackDeepLink?.notificationType === 'TRACK_SDK') {
      this.global.openVehicleTrackApp$.next(true);
    }

    if (!this.storage?.realmOwnerData?.realmIdentifier) {
      this.storage.realmOwnerData = {
        realmIdentifier: environment.defaultRealmIdentifier,
      } as any;
    }
    this.handleAppConnectivityChanges();
    this.loadingUrl = `${environment.assetsPath}/icons/ninja-logo.png`;
    this.storage.languageCode = this.storage.languageCode || 'en';
    this.translate.setDefaultLang(this.storage.languageCode);
    FastClick.attach(document.body);
    this.twaService.initialize(window.location.search);
    let appLinkPathName = window?.location?.pathname;
    if (this.storage.goToGenerateQRCode) {
      this.router.navigate(['/qr-ninja-upi']);
      return;
    }
    if (appLinkPathName?.includes('/qrcode')) {
      this.storage.goToGenerateQRCode = true;
      return;
    }
    if (!this.storage.authData?.token) {
      this.router.navigate(['/select-language']);
      return;
    }
    if (appLinkPathName?.includes('/e-kyc/select')) {
      return;
    }
    if (appLinkPathName?.includes('/add-contract/')) {
      // added '/' as the end to only allow deeplink url's i.e. /add-contract/123
      this.storage.redirectedFromDeepLink = true;
      return;
    }
    if (appLinkPathName?.includes('/pending-kyc-score')) {
      return;
    }
    if (appLinkPathName?.includes('/e-kyc/kyc-lite')) {
      return;
    }
    if (
      appLinkPathName?.includes('/payment-guarantee/about-payment-assured') ||
      appLinkPathName?.includes('/payment-guarantee/view')
    ) {
      return;
    }
    if (appLinkPathName?.includes('/payment-guarantee/view')) {
      return;
    }
    if (appLinkPathName?.includes('/switch-team')) {
      await this.userService.initiateSplitIO();
      return;
    }
    if (appLinkPathName?.includes('/privacy-policy')) {
      return;
    }
    if (appLinkPathName?.includes('/upcoming-offers')) {
      return;
    }
    if (
      appLinkPathName?.includes('/select-party-new') ||
      appLinkPathName?.includes('/select-party-new?flow=ADD_SELLING_TRADE')
    ) {
      return;
    }
    if (appLinkPathName?.includes('/add-party')) {
      return;
    }
    if (appLinkPathName?.includes('generic-pay')) {
      this.qrFacade.showGenericPay();
      return;
    }
    if (appLinkPathName?.includes('/party-account-details')) {
      return;
    }
    if (appLinkPathName?.includes('/orders-v2')) {
      return;
    }
    if (appLinkPathName?.includes('/trans-history')) {
      return;
    }
    if (appLinkPathName?.includes('/rewards')) {
      return;
    }
    if (appLinkPathName?.includes('/refer')) {
      return;
    }
    if (appLinkPathName?.includes('/add-money/neft-payment')) {
      return;
    }
    if (appLinkPathName?.includes('/add-money/upi-payment')) {
      return;
    }
    if (appLinkPathName?.includes('/tabs/discover')) {
      return;
    }
    if (appLinkPathName?.includes('/link-account')) {
      return;
    }
    if (appLinkPathName?.includes('/link-account/bank-detail')) {
      return;
    }
    if (appLinkPathName?.includes('/ninjapay-transaction')) {
      return;
    }
    if (appLinkPathName?.includes('/tabs/requirements')) {
      return;
    }
    if (appLinkPathName?.includes('/content/consent')) {
      await this.userService.initiateSplitIO();
      if (this.splitService.getTreatment(SplitIo.PgFeesException) !== 'on') {
        await this.pgService.pgFeesNavigationHandler();
      }
      this.storage.redirectedFromDeepLink = true;
      return;
    }
    if (
      appLinkPathName?.includes('/select-party') ||
      appLinkPathName?.includes('/select-party?flow=PAY_COLLECT') ||
      appLinkPathName?.includes('/select-party?flow=ADD_CONTRACT')
    ) {
      return;
    }
    if (
      appLinkPathName?.includes('/dashboard') ||
      appLinkPathName?.includes('/dashboard?flow=COLLECT_PLUS') ||
      appLinkPathName?.includes('/dashboard?flow=COLLECT_ASSURE') ||
      appLinkPathName?.includes('/dashboard?flow=LOANS') ||
      appLinkPathName?.includes('/dashboard?flow=TRACK') ||
      appLinkPathName?.includes('/dashboard?flow=MANDI-NEWS') ||
      appLinkPathName?.includes('/dashboard?flow=MY-BRANDING') ||
      appLinkPathName?.includes('/dashboard?flow=KNOW_MY_TRADER')
    ) {
      this.offline = false;
      this.isFintechApp = false;
      this.isCollectAssureApp = false;
      this.isVehicleTrack = false;
      this.isMandiApp = false;
      this.isMyBrand = false;
      this.isKnowMyTrader = false;
      return;
    }
    if (appLinkPathName?.includes('/view-contract-details')) {
      this.storage.redirectedFromDeepLink = true;
      return;
    }
    if (appLinkPathName?.includes('/qr-segment')) {
      return;
    }
    let deepLinkingPath: boolean =
      appLinkPathName &&
      appLinkPathName?.length > 0 &&
      ((appLinkPathName?.indexOf('consent') > -1 && !(appLinkPathName?.indexOf('content/consent') > -1)) ||
        appLinkPathName?.indexOf('trader-account') > -1);
    if (!this.storage.authData?.token && !deepLinkingPath) {
      this.router.navigate(['/select-language']);
    } else if (
      !deepLinkingPath &&
      !(appLinkPathName?.indexOf('/pay-money') > -1 && !(appLinkPathName?.indexOf('content/pay-money') > -1))
    ) {
      this.router.navigate(['/app']);
    }
  }

  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.offline = false;
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.offline = true;
      })
    );
  }
}
