import { Injectable } from '@angular/core';
import { AuthResponseVM } from '../_modals/auth-response.modal';
import { DgoCallbackResponseVM } from '../_modals/dgo.modal';
import { RealmVM } from '../_modals/realm.modal';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  set languageCode(value: string) {
    window.localStorage.setItem('languageCode', value);
  }

  get languageCode() {
    return window.localStorage.getItem('languageCode');
  }

  set authData(value: AuthResponseVM) {
    window.localStorage.setItem('authData', JSON.stringify(value));
  }

  get authData(): AuthResponseVM {
    return JSON.parse(window.localStorage.getItem('authData'));
  }

  set realmOwnerData(value: RealmVM) {
    window.localStorage.setItem('realmOwnerData', JSON.stringify(value));
  }

  get realmOwnerData(): RealmVM {
    return JSON.parse(window.localStorage.getItem('realmOwnerData'));
  }

  set isTradeCreated(value: boolean) {
    window.localStorage.setItem('isTradeCreated', JSON.stringify(value));
  }

  get isTradeCreated(): boolean {
    return JSON.parse(window.localStorage.getItem('isTradeCreated'));
  }

  set isVanRequest(value: boolean) {
    window.localStorage.setItem('isVanRequest', JSON.stringify(value));
  }

  get isVanRequest(): boolean {
    return JSON.parse(window.localStorage.getItem('isVanRequest'));
  }

  set cashTransactionId(value: string) {
    window.localStorage.setItem('cashTransactionId', value);
  }

  get cashTransactionId() {
    return window.localStorage.getItem('cashTransactionId');
  }

  set entityTypeParams(value: any) {
    window.localStorage.setItem('entityTypeParams', JSON.stringify(value));
  }

  get entityTypeParams(): any {
    return JSON.parse(window.localStorage.getItem('entityTypeParams'));
  }

  set digioRequestId(value: string) {
    window.localStorage.setItem('digioRequestId', value);
  }

  get digioRequestId() {
    return window.localStorage.getItem('digioRequestId');
  }

  set dgoCallbackResponse(value: DgoCallbackResponseVM) {
    window.localStorage.setItem('dgoCallbackResponse', JSON.stringify(value));
  }

  get dgoCallbackResponse(): DgoCallbackResponseVM {
    return JSON.parse(window.localStorage.getItem('dgoCallbackResponse'));
  }

  set createPgPopUpShown(value: any) {
    window.localStorage.setItem('createPgPopUpShown', value);
  }

  get createPgPopUpShown() {
    return window.localStorage.getItem('createPgPopUpShown');
  }

  set kycSummaryDetails(value: any) {
    window.localStorage.setItem('kycSummaryDetails', JSON.stringify(value));
  }

  get kycSummaryDetails() {
    return JSON.parse(window.localStorage.getItem('kycSummaryDetails'));
  }

  get stateData() {
    return JSON.parse(window.localStorage.getItem('state'));
  }

  get goToGenerateQRCode() {
    return JSON.parse(window.localStorage.getItem('generateQRCode'));
  }

  set goToGenerateQRCode(value) {
    window.localStorage.setItem('generateQRCode', JSON.stringify(value));
  }

  set multiBankingEnabled(value: boolean) {
    window.localStorage.setItem('multiBankingEnabled', JSON.stringify(value));
  }

  get multiBankingEnabled() {
    return JSON.parse(window.localStorage.getItem('multiBankingEnabled'));
  }

  get lastSuccessTransactionUid() {
    return window.localStorage.getItem('lastSuccessTransactionUid');
  }

  set lastSuccessTransactionUid(value: string) {
    window.localStorage.setItem('lastSuccessTransactionUid', value);
  }

  get ediLoanId() {
    return window.localStorage.getItem('ediLoanId');
  }

  set ediLoanId(value: string) {
    window.localStorage.setItem('ediLoanId', value);
  }

  get userRealmData() {
    let userRealmData = window.localStorage.getItem('userRealmData');
    if (userRealmData) {
      return JSON.parse(window.localStorage.getItem('userRealmData'));
    } else {
      return {};
    }
  }

  set userRealmData(value: any) {
    window.localStorage.setItem('userRealmData', JSON.stringify(value));
  }

  get isPanAadhaarUpdatedForNonKycuser(): boolean {
    return JSON.parse(window.localStorage.getItem('isPanAadhaarUpdatedForNonKycuser'));
  }

  set isPanAadhaarUpdatedForNonKycuser(value: boolean) {
    window.localStorage.setItem('isPanAadhaarUpdatedForNonKycuser', JSON.stringify(value));
  }

  get lastSuccessCollectRequestUid() {
    return window.localStorage.getItem('lastSuccessCollectRequestUid');
  }

  set lastSuccessCollectRequestUid(value: string) {
    window.localStorage.setItem('lastSuccessCollectRequestUid', value);
  }

  get kycStatus(): boolean {
    return JSON.parse(window.localStorage.getItem('kycStatus'));
  }

  set kycStatus(value: boolean) {
    window.localStorage.setItem('kycStatus', JSON.stringify(value));
  }

  set addSellingTrade(value: any) {
    window.localStorage.setItem('addSellingTrade', value);
  }

  get addSellingTrade() {
    return window.localStorage.getItem('addSellingTrade');
  }

  set authrizeClickFlag(value: any) {
    window.localStorage.setItem('authrizeClickFlag', JSON.stringify(value));
  }

  get authrizeClickFlag() {
    return JSON.parse(window.localStorage.getItem('authrizeClickFlag'));
  }

  get isTwa(): boolean {
    return JSON.parse(window.localStorage.getItem('isTwa'));
  }

  get loadTime() {
    return JSON.parse(window.localStorage.getItem('loadTime'));
  }
  set conncectedBankAccInfo(value: any) {
    window.localStorage.setItem('conncectedBankAccInfo', JSON.stringify(value));
  }

  get conncectedBankAccInfo() {
    return JSON.parse(window.localStorage.getItem('conncectedBankAccInfo'));
  }

  set paymentsConfig(value: any) {
    window.localStorage.setItem('PAYMENTS_CONFIG', JSON.stringify(value));
  }
  get paymentsConfig() {
    return JSON.parse(window.localStorage.getItem('PAYMENTS_CONFIG'));
  }

  get isFeatureCalculationCompleted(): boolean {
    return JSON.parse(window.localStorage.getItem('isFeatureCalculationCompleted'));
  }

  set isFeatureCalculationCompleted(value: boolean) {
    window.localStorage.setItem('isFeatureCalculationCompleted', JSON.stringify(value));
  }

  get deviceId() {
    if (window['Native']) {
      return window.localStorage?.deviceId;
    }
  }

  get getDeviceDetails() {
    return JSON.parse(window?.localStorage?.getItem('deviceDetails'));
  }

  get clearTwaCache() {
    return JSON.parse(window?.localStorage?.getItem('twaCache')) === true;
  }

  set isAddTeamToggle(value: boolean) {
    window.localStorage.setItem('isAddTeamToggle', JSON.stringify(value));
  }
  get isAddTeamToggle(): boolean {
    return JSON.parse(window.localStorage.getItem('isAddTeamToggle'));
  }
  set isStaffView(value: boolean) {
    window.localStorage.setItem('isStaffView', JSON.stringify(value));
  }
  get isStaffView(): boolean {
    return JSON.parse(window.localStorage.getItem('isStaffView'));
  }

  set toggleSwitchOrg(value: boolean) {
    window.localStorage.setItem('toggleSwitchOrg', JSON.stringify(value));
  }
  get toggleSwitchOrg(): boolean {
    return JSON.parse(window.localStorage.getItem('toggleSwitchOrg'));
  }

  set reloadSelectOrg(value: boolean) {
    window.localStorage.setItem('reloadSelectOrg', JSON.stringify(value));
  }
  get reloadSelectOrg(): boolean {
    return JSON.parse(window.localStorage.getItem('reloadSelectOrg'));
  }

  get geolocation() {
    return window?.localStorage?.getItem('geolocation');
  }

  get isLocationGranted() {
    return JSON.parse(window?.localStorage?.getItem('location-permission-granted'));
  }

  get eSignDigioDocInfo() {
    return JSON.parse(window?.localStorage?.getItem('eSignDigioDocInfo'));
  }

  set eSignDigioDocInfo(value) {
    window?.localStorage?.setItem('eSignDigioDocInfo', JSON.stringify(value));
  }

  get eNachDigioDocInfo() {
    return JSON.parse(window?.localStorage?.getItem('eNachDigioDocInfo'));
  }

  set eNachDigioDocInfo(value) {
    window?.localStorage?.setItem('eNachDigioDocInfo', JSON.stringify(value));
  }

  get fireBaseToken() {
    return JSON.parse(window?.localStorage?.getItem('firebaseToken'));
  }

  set fireBaseToken(value) {
    window?.localStorage?.setItem('firebaseToken', JSON.stringify(value));
  }

  get rebootTime() {
    return JSON.parse(window?.localStorage?.getItem('rebootTime'));
  }

  set disableCheckForCA(value: any) {
    window?.localStorage?.setItem('disableCheck', value);
  }

  get notificationDeepLink() {
    return JSON.parse(window?.localStorage?.getItem('notificationDeepLink'));
  }
  get locationPermissionAskCountHomePage() {
    return JSON.parse(window?.localStorage?.getItem('locationPermissionAskCountHomePage'));
  }
  set locationPermissionAskCountHomePage(value: any) {
    window?.localStorage?.setItem('locationPermissionAskCountHomePage', value);
  }
  get lastTimeLocationRequested() {
    return JSON.parse(window?.localStorage?.getItem('lastTimeLocationRequested'));
  }
  set lastTimeLocationRequested(value: any) {
    window?.localStorage?.setItem('lastTimeLocationRequested', value);
  }
  get locationPermissionGranted() {
    return JSON.parse(window?.localStorage?.getItem('location-permission-granted'));
  }

  get appVersion() {
    return window.localStorage.getItem('app-build-version');
  }

  get redirectedFromDeepLink(): boolean {
    return JSON.parse(window.localStorage.getItem('redirectedFromDeepLink'));
  }

  set redirectedFromDeepLink(value: boolean) {
    window.localStorage.setItem('redirectedFromDeepLink', JSON.stringify(value));
  }
  get isSelfTransferSdkOpenedFromDashboard(): boolean {
    return JSON.parse(window.localStorage.getItem('isSelfTransferSdkOpenedFromDashboard'));
  }
  set isSelfTransferSdkOpenedFromDashboard(value: boolean) {
    window.localStorage.setItem('isSelfTransferSdkOpenedFromDashboard', JSON.stringify(value));
  }
  get isCollectAssureSdkOpenedFromDashboard(): boolean {
    return JSON.parse(window.localStorage.getItem('isCollectAssureSdkOpenedFromDashboard'));
  }
  set isCollectAssureSdkOpenedFromDashboard(value: boolean) {
    window.localStorage.setItem('isCollectAssureSdkOpenedFromDashboard', JSON.stringify(value));
  }

  set isEdiLoanSummary(value: boolean) {
    window.localStorage.setItem('isEdiLoanSummary', JSON.stringify(value));
  }
  get isEdiLoanSummary(): boolean {
    return JSON.parse(window.localStorage.getItem('isEdiLoanSummary'));
  }

  get newlyAddedParties() {
    return JSON.parse(window.localStorage.getItem('newlyAddedParties'));
  }

  set newlyAddedParties(value) {
    window.localStorage.setItem('newlyAddedParties', JSON.stringify(value));
  }

  get newlyUpdatedParties() {
    return JSON.parse(window.localStorage.getItem('newlyUpdatedParties'));
  }

  set newlyUpdatedParties(value) {
    window.localStorage.setItem('newlyUpdatedParties', JSON.stringify(value));
  }

  clearAll() {
    // Special keys, don't need to delete
    let locationPermissionAskCountHomePage = this.locationPermissionAskCountHomePage;
    let lastTimeLocationRequested = this.lastTimeLocationRequested;

    window.localStorage.clear();

    if (locationPermissionAskCountHomePage) {
      this.locationPermissionAskCountHomePage = locationPermissionAskCountHomePage;
    }
    if (lastTimeLocationRequested) {
      this.lastTimeLocationRequested = lastTimeLocationRequested;
    }
  }

  clear(key: StorageKey) {
    window.localStorage.removeItem(key);
  }
}

export type StorageKey =
  | 'state'
  | 'languageCode'
  | 'authData'
  | 'realmOwnerData'
  | 'isTradeCreated'
  | 'partyInfo'
  | 'screenImageAsBase64'
  | 'isVanRequest'
  | 'digioRequestId'
  | 'dgoCallbackResponse'
  | 'kycSummaryDetails'
  | 'cashTransactionId'
  | 'addSellingTrade'
  | 'multiBankingEnabled'
  | 'transHistLastUpdate'
  | 'deviceDetails'
  | 'geolocation'
  | 'eSignDigioDocInfo'
  | 'eNachDigioDocInfo'
  | 'firebaseToken'
  | 'rebootTime'
  | 'loadTime'
  | 'disableCheck'
  | 'notificationDeepLink'
  | 'ninja-trader-contact-list'
  | 'locationPermissionAskCountHomePage'
  | 'lastTimeLocationRequested'
  | 'redirectedFromDeepLink'
  | 'trackSdkUrl'
  | 'newlyAddedParties'
  | 'newlyUpdatedParties';
