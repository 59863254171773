import { Injectable } from '@angular/core';
import { environment } from './environments/environment';
import { AppHttpService } from './shared/services/app-http.service';
import { Observable } from "rxjs";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UiEngineService {

  // directNinja = `http://${environment.apiUrl.directNinja}`;
  // procurement = `http://${environment.apiUrl.procurement}`;
  // stagingAsgardOdin = `http://${environment.apiUrl.stagingAsgardOdin}`;
  // operationBaseUrl = `http://${environment.apiUrl.operationBaseUrl}`;

  constructor(private httpService: AppHttpService) { }

  getFormConfig(payload): Observable<any> {
    const url = `http://localhost/auctioneer/selfOnBoard/status`;
    return this.httpService.post(url, payload);
  }

}
