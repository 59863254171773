import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ninja-upload-file',
  templateUrl: './ninja-upload-file.component.html',
  styleUrls: ['./ninja-upload-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NinjaUploadFileComponent),
      multi: true
    }
  ]
})
export class NinjaUploadFileComponent implements OnInit, ControlValueAccessor {
  @Input() id;
  @Input() label;
  @Input() cssClass;
  @Input() cssProperties;
  @Input() labelForCssProperties;
  @Input() accept: string = ".jpeg,.jpg,.png";
  @Input() placeholder = '';
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() s3Link = "https://s3.amazonaws.com/qa-workflow-check-docs/workflow/1/1_1637407931582";
  @Output('change') change: EventEmitter<any> = new EventEmitter();
  fileInputLabelForProps = {
    "background": "#569b40",
    "border": "none",
    "border-radius": "5px",
    "color": "#fff",
    "cursor": "pointer",
    "padding": "0.2rem"
  };
  onChange: any = () => { };
  onTouched: any = () => { };
  files: any;

  constructor() { }

  ngOnInit(): void {
    this.fileInputLabelForProps = { ...this.fileInputLabelForProps, ...this.labelForCssProperties };
  }

  onChangeFile(e) {
    this.onChange(e.target.files);
    this.change.emit({
      event: e,
      files: e.target.files
    });
  }

  openDialog() {
    document.getElementById(`file-${this.id}`).click();
  }

  writeValue(value: any) {
    this.files = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
