import { Injectable } from '@angular/core';

declare global {
  interface Window {
    Hansel: any;
    smartech: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FeatureManagementService {
  userProfileUpdate(data) {
    window.smartech('contact', 'LIST IDENTIFIER', {
      'pk^customerid': data.userId,
      email: '',
      mobile: data.businessName?.traderDTO?.contactNumber,
      FIRST_NAME: data.businessName?.traderDTO?.name,
      LAST_NAME: '',
      COMPANY_NAME: '',
      DESIGNATION: '',
      PRODUCT: '',
    });
  }

  getLoanFeatureConfig() {
    return this.getProductVariable('Loans', 'Boolean', false);
  }

  getAddContractFeatureConfig() {
    return this.getProductVariable('Add_contract', 'Boolean', true);
  }

  isMultiBankingEnabled() {
    let payCollectFeatureConfig = this.getProductVariable('multiBankingEnabled', 'String', 'off');
    return payCollectFeatureConfig && payCollectFeatureConfig == 'on' ? true : false;
  }

  isWhatsAppChatEnabled() {
    return this.getProductVariable('WhatsAppChat', 'Boolean', false);
  }

  isCallUsEnabled() {
    return this.getProductVariable('callUsEnabled', 'Boolean', false);
  }

  getProductVariable(name, type, fallbackValue) {
    return window.Hansel[`get${type}`] && window.Hansel[`get${type}`](name, fallbackValue);
  }
}
