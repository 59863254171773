import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as dashbboard from '../reducers/Dashboard.reducer';
import * as orders from './Orders.reducer';
import * as parties from './parties.reducer';
import * as partiesv2 from './parties-v2.reducer';
import * as addmoney from './add-money.reducer';
import * as withdraw from './withdraw.reducer';
import * as addTrade from './AddTrade.reducer';
import * as sellerBuyer from './seller-buyer-info.reducer';
import * as returnUrl from './returnUrl.reducer';
import * as contact from './contact.reducer';
import * as transactions from './transactions.reducer';
import * as addTradeParent from './AddTradeParent.reducer';
import * as demandOtp from './demand-otp.reducer';
import { hydrationMetaReducer } from './hydration.reducer';
import { amountReducer } from './amount.reducer';
import { gamificationReducer } from './gamification.reducer';
import { qrCodeReducer } from './qrCode.reducer';
import { paymentModuleReducer } from './payment-module.reducer';
import * as contractDetails from './contract-details.reducer';
import * as transactionDetails from './transaction-details.reducer';

export interface AppState {
  dashboard: any;
  orders: any;
  parties: any;
  partiesv2: any;
  addmoney: any;
  withdraw: any;
  addTrade: any;
  sellerBuyerInfo: any;
  transactions: any;
  returnUrl: any;
  contact: any;
  amountDetails: any;
  gamificationDetails: any;
  addTradeParentPage: any;
  isDemandOtp: any;
  qrCode: any;
  paymentsModule: any;
  contracts: any;
  transactiondata: any;
}

export const reducers: ActionReducerMap<AppState> = {
  dashboard: dashbboard.homeReducer,
  orders: orders.ordersReducer,
  parties: parties.partiesReducer,
  partiesv2: partiesv2.partiesV2Reducer,
  addmoney: addmoney.addMoneyReducer,
  withdraw: withdraw.withdrawReducer,
  addTrade: addTrade.addTradeReducer,
  sellerBuyerInfo: sellerBuyer.sellerBuyerInfoReducer,
  transactions: transactions.transactionsReducer,
  returnUrl: returnUrl.returnUrlReducer,
  contact: contact.contactReducer,
  amountDetails: amountReducer,
  gamificationDetails: gamificationReducer,
  addTradeParentPage: addTradeParent.addTradeParentReducer,
  isDemandOtp: demandOtp.demandReducer,
  qrCode: qrCodeReducer,
  paymentsModule: paymentModuleReducer,
  contracts: contractDetails.contractDetailsReducer,
  transactiondata: transactionDetails.transactionsReducer,
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
