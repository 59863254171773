import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<ImageViewerComponent>
  ) {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass('image-previewer');
  }

  ngOnDestroy() {
    this.dialogRef.removePanelClass('image-previewer');
  }
}
