import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { AddMoneyService } from '../../app/_services/add-money.service';
import { AccountDetailVM } from '../../app/_modals/accountDetail';
import { AmountActionsTypes, AmountSuccessful, AmountFailed } from '../actions/amount.actions';
import { StorageService } from 'src/app/_handlers/storage.service';

@Injectable()
export class AmountEffects {
  @Effect()
  loadAccountDetail$ = this.dataPersistence.fetch(AmountActionsTypes.Get_Amount, {
    run: (action: any, state) => {
      return this.addMoneyService.fetchAccountDetails().pipe(
        map((res) => {
          const { _SELF = [] } = res?.data;
          // check for empty VAN details
          if (!_SELF || _SELF.length <= 0) {
            this.storage.isVanRequest = true;
          }
          return new AmountSuccessful(res?.data);
        })
      );
    },
    onError: (action, error) => {
      return new AmountFailed();
    },
  });
  constructor(
    private dataPersistence: DataPersistence<AccountDetailVM>,
    private addMoneyService: AddMoneyService,
    private storage: StorageService
  ) {}
}
