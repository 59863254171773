import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from "ngx-avatar";
import { NinjaLocalContactListComponent } from './ninja-local-contact-list.component';
import { NinjaContactListModule } from '../ninja-contact-list/ninja-contact-list.module';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaLocalContactListComponent],
  imports: [
    CommonModule,
    AvatarModule,
    NinjaContactListModule
  ],
  exports: [NinjaLocalContactListComponent]
})
export class NinjaLocalContactListModule { }
