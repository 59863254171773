import { Action } from '@ngrx/store';
import { PartiesResponseVM } from 'src/app/_modals/parties-response.modal';

export enum PartiesActionTypes {
  Parties_Loading = 'Parties_Loading',
  Parties_Fulfilled = 'Parties_Fulfilled',
  Parties_Failed = 'Parties_Failed',
  Reload_Parties = 'Reload_Parties',
}

export class PartiesLoading implements Action {
  readonly type = PartiesActionTypes.Parties_Loading;
  constructor(public userId: any, public useESApi?: boolean) {}
}

export class PartiesFulfilled implements Action {
  readonly type = PartiesActionTypes.Parties_Fulfilled;
  constructor(public payload: PartiesResponseVM[]) {}
}

export class PartiesFailed implements Action {
  readonly type = PartiesActionTypes.Parties_Failed;
  constructor() {}
}

export class ReloadParties implements Action {
  readonly type = PartiesActionTypes.Reload_Parties;
  constructor() {}
}

export type PartiesActions = PartiesLoading | PartiesFulfilled | PartiesFailed | ReloadParties;
