import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaHeaderComponent } from './ninja-header.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaHeaderComponent],
  imports: [
    CommonModule
  ],
  exports: [NinjaHeaderComponent]
})
export class NinjaHeaderModule { }
