import { HttpClient, HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppHttpService } from 'src/app/shared/services/app-http.service';
import { StorageService } from '../_handlers/storage.service';
import { ErrorHandlingService } from './error-handling.service';
import { NinjaSpinnerService } from 'ninja-modules';
import { SplitService } from '@splitsoftware/splitio-angular';
import { SplitIo } from '../_modals/splitIo.enum';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuaranteeService {
  private workflowUrl = environment.apiUrl.workflow;
  private pgUrl = environment.apiUrl.pg;
  private digioBaseUrl = environment.digioBaseUrl;
  private profileService = environment.apiUrl.profile;
  public pgFeesData = new BehaviorSubject([]);
  public pgFeesData$ = this.pgFeesData.asObservable();
  private finTrans = environment.apiUrl.fintrans;
  private documentGeneratorUrl = environment.apiUrl.document_generator;

  public pgProductList = null;

  constructor(
    private storage: StorageService,
    private appHttpService: AppHttpService,
    private httpHandler: HttpBackend,
    private error: ErrorHandlingService,
    private spinner: NinjaSpinnerService,
    private splitService: SplitService
  ) {}

  getPgVideos(): Observable<any> {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-videos-links`;
    return this.appHttpService.post(url, { input: {} });
  }

  getPGFeesTransactions(
    transactionType = 'DEBIT',
    status = 'REQUESTED',
    sendAdditionalInfo = 'true',
    transactionRefType = 'PG_FEES'
  ) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.finTrans}/${realmObj?.realmIdentifier}/${authData.systemUserId}/cashTransactions/getByTransactionState?transactionType=${transactionType}&status=${status}&sendAdditionalInfo=${sendAdditionalInfo}&transactionRefType=${transactionRefType}`;
    return this.appHttpService.get(url, {}).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  async checkIfValidPGContractExists(userId): Promise<boolean> {
    return new Promise((resolve) => {
      this.splitService.getClientSDKReady(userId).subscribe(
        async (res) => {
          console.log(userId, this.splitService.getTreatment(userId, SplitIo.PGEnachBlocker));
          if (this.splitService.getTreatment(userId, SplitIo.PGEnachBlocker) !== 'on') {
            resolve(false);
          } else {
            this.spinner.start();
            const res = await this.getValidPGContracts(userId).toPromise();
            this.spinner.end();
            resolve(res?.data?.isPresent);
          }
        },
        (err) => {
          this.splitService.initClient(userId).subscribe(async () => {
            console.log(userId, this.splitService.getTreatment(userId, SplitIo.PGEnachBlocker));
            if (this.splitService.getTreatment(userId, SplitIo.PGEnachBlocker) !== 'on') {
              resolve(false);
            } else {
              this.spinner.start();
              const res = await this.getValidPGContracts(userId).toPromise();
              this.spinner.end();
              resolve(res?.data?.isPresent);
            }
          });
        }
      );
    });
  }

  getPrimeSeller(): Observable<any> {
    const realmObj = this.storage.realmOwnerData;
    const authData = this.storage.authData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData.userId}/v1/execution/service/run/pg-prime-seller-check`;
    return this.appHttpService.post(url, {
      input: {
        userRealmId: realmObj.userRealmId,
      },
    });
  }

  async checkIfPrimeSeller() {
    this.spinner.start();
    let res = await this.getPrimeSeller().toPromise();
    this.spinner.end();
    if (!Array.isArray(res?.data?.roles)) return false;
    let roles = res?.data?.roles?.map((id) => id?.permissions?.some((action) => action.actionName === 'add_contract'));
    return roles.includes(true);
  }

  async pgFeesNavigationHandler() {
    this.spinner.start();
    const res = await this.getPGFeesTransactions().toPromise();
    this.pgFeesData.next(res?.data?.length ? res?.data : []);
    this.spinner.end();
    return res?.data?.length ? res?.data : [];
  }

  fetchPgDetail(userId, receive = false, rejectedState = false) {
    const realmObj = this.storage.realmOwnerData;
    const authData = this.storage.authData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData.userId}/v1/execution/service/run/fetch-pg-details`;
    let contactHttp = new HttpClient(this.httpHandler);
    if (receive) {
      return contactHttp
        .post(
          url,
          {
            input: { userId, status: [100, 110, 120, 200] },
          },
          {
            headers: {
              Authorization: `${authData?.token_type} ${authData?.token}`,
            },
          }
        )
        .pipe(
          map((m: any) => m),
          catchError(this.catchError.bind(this))
        );
    }
    return this.appHttpService
      .post(url, { input: { userId, status: rejectedState ? [100, 110, 120, 200, 500] : [100, 110, 120, 200] } })
      .pipe(map((m: any) => m));
  }

  getSubCategories(): Observable<any> {
    const authData = this.storage.authData;
    let url = `${this.profileService}/1/1/sn-category/child-categories?is_product=false`;
    return this.appHttpService.get(url, {
      headers: {
        Authorization: `${authData?.token_type} ${authData?.token}`,
      },
    });
  }

  fetchPgProductList() {
    const payload = {
      input: {
        isLineOfCredit: true,
        default_tag: [],
        loanProductIdDefaultList: [],
        userRealmId: this.storage.realmOwnerData.userRealmId,
      },
    };
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-products-fetch-apply`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getPGAnnexure(payload) {
    let url = `${this.documentGeneratorUrl}/temp/pdf-generate?templateName=PaymentAssuranceBuyerAnnexure`;
    return this.appHttpService.post(url, payload, {
      headers: {
        Authorization: 'Basic bWF5YW5rOmNyQHp5bUBydmVs',
      },
      responseType: 'arraybuffer',
    });
  }

  getPGKycDocStatus(productName) {
    const payload = { input: { productName: productName } };
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData.userId}/v1/execution/service/run/pg-kyc-doc-status`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  fetchUserBankDetails(userId) {
    const payload = { input: { userId } };
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData.userId}/v1/execution/service/run/fetch-bank-details-pg`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getPgProductForContract() {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-product-list`;
    return this.appHttpService.post(url, { input: {} }).pipe(map((m: any) => m));
  }

  applyForPg(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-program-approve`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  applyForPaymentGuarantee() {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/apply-for-payment-guarantee`;
    return this.appHttpService
      .post(url, {
        input: {
          isLineOfCredit: true,
        },
      })
      .pipe(map((m: any) => m));
  }

  initiateDigioRequest(documentId, transactionId, contact) {
    const redirectUrl = window.location.origin + window.location.pathname;
    const digioUrl = `${this.digioBaseUrl}/${documentId}/${transactionId}/${contact}?redirect_url=${redirectUrl}&logo=https://ninjacart.in/images/logo.png`;
    window.location.href = digioUrl;
  }

  updateLoanAdditionalInfo(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/create-loan-additional-info`;
    return this.appHttpService.post(url, payload);
  }

  raisePgMakerTicket(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-maker-ticket`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getValidPGContracts(userId) {
    let payload = { input: { userId } };
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-enach-contracts-check`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getFungibleLimit() {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.systemUserId}/v1/execution/service/run/get-fungible-limit`;
    return this.appHttpService.post(url, {}).pipe(map((m: any) => m));
  }

  cancelPendingContract(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.systemUserId}/v1/execution/service/run/consent-create-update`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  triggerESignDigio(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/lsp-create-loan-agreement`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  triggerENachDigio(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/lsp-create-mandate-save`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  updateCancelConsent(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/consent-create-update`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  catchError(error: HttpErrorResponse) {
    this.error.catchErrorHandling(error);
  }
}
