import { Component, OnInit } from '@angular/core';
import { WorkflowService } from './data/services/workflow.service';
import { AppStorageService } from "./shared/services/app-storage.service";
import { DataFilterService } from '../ui-engine/data/services/data-filter.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NinjaNotificationService } from '../ninja-notification/ninja-notification.service';
import { NinjaSpinnerService } from '../ninja-spinner/ninja-spinner.service';
declare var $: any;
@Component({
  selector: 'app-ui-engine',
  templateUrl: './ui-engine.component.html',
  styleUrls: ['./ui-engine.component.scss']
})
export class UiEngineComponent implements OnInit {
  formConfig;
  formElement;

  constructor(private workflow: WorkflowService,
    private spinner: NinjaSpinnerService,
    private ninjaNotify: NinjaNotificationService,
    private appStorageService: AppStorageService,
    private dataFilterService: DataFilterService,
    private router: Router) { }

  ngOnInit(): void {
    // this.formConfig = this.workflow.getData();
    this.appStorageService.remove('wizardPayload');
    //this.getFormConfig();
    window.addEventListener('message', (e) => {
      if (e?.data?.pageName) {
        if (e.data.pageName === 'createProfile') {
          this.formConfig = this.workflow.getCreateProfileWorkflow();
        }
      }
    });
  }


  getFormConfig() {
    let payload = {
      "workflowName": "getCreditWorkflow",
      "requestId": "5717-46975",
      "userId": "5717",
      "dataMap": {
        "getCreditWizardForm_getCredit": {
          "buyer": {
            "userName": "V K Traders",
            "id": 46975,
            "phoneNumber": "9751507729",
            "facility": {
              "city": {
                "id": 2,
                "name": "Bengaluru"
              },
              "facilityName": "Malur",
              "id": 9
            }
          }
        }
      }
    };
    this.spinner.start();
    this.workflow.getFormConfig(payload).subscribe(
      (response) => {
        this.spinner.end();
        if (response.success) {
          this.formConfig = response.data;
          this.getFormElement();
        } else {
          this.error(`Error ${response?.errorCode}`, `${response?.errorMessage}`);
        }
      }, (error: HttpErrorResponse) => {
        this.spinner.end();
        this.error(`${error?.status} ${error?.statusText}`, `${error?.message}`).then(res => {
          this.router.navigate(['/ninja']);
        });
      });
  }

  getFormElement() {
    if (this.formConfig?.payload?.formDefinition?.componentList) {
      let result = this.dataFilterService.getFormElementPayloadFormat(this.formConfig.payload.formDefinition.componentList);
      this.spinner.start();
      this.workflow.getFormElement(result).subscribe(
        (response) => {
          this.spinner.end();
          if (response.success) {
            this.formElement = response.data;
            // $.extend(true, this.formConfig.payload.formDefinition.componentList, this.formElement);
            this.formConfig.payload.formDefinition.componentList = this.mergeListOfJsons(this.formConfig.payload.formDefinition.componentList, this.formElement);
            this.formConfigChange({ "index": 0 });
          } else {
            this.error(`Error ${response?.errorCode}`, `${response?.errorMessage}`);
          }
        }, (error: HttpErrorResponse) => {
          this.spinner.end();
          this.error(`${error?.status} ${error?.statusText}`, `${error?.message}`);
        });
    }
  }

  formConfigChange(e) {
    this.formConfig.payload.formDefinition.componentList.forEach(element => {
      if (element.name == 'NinjacartWizard') {
        this.spinner.start();
        this.dataFilterService.getFormElement(element.componentList[e.index].componentList).subscribe(
          (response) => {
            this.spinner.end();
            if (response.success) {
              // $.extend(true, element.componentList[e.index].componentList, response.data);
              element.componentList[e.index].componentList = this.mergeListOfJsons(element.componentList[e.index].componentList, response.data);
            } else {
              this.error(`Error ${response?.errorCode}`, `${response?.errorMessage}`);
            }
          }, (error: HttpErrorResponse) => {
            this.spinner.end();
            this.error(`${error?.status} ${error?.statusText}`, `${error?.message}`);
          });
      }
    })
  }

  mergeListOfJsons(list1, list2) {
    for (let i = 0; i < list1.length; i++) {
      let data1 = list1[i];
      for (let j = 0; j < list2.length; j++) {
        let data2 = list2[j];
        if (data1.name == data2.name && data1.version == data2.version) {
          $.extend(true, data1, data2);
        }
      }
    }
    return list1;
  }

  onFormActions(e) {

  }

  error(title?: string, text?: string) {
    return this.ninjaNotify.fire({
      title: title,
      text: text,
      showCancelButton: true,
      showDenyButton: true,
      denyButtonText: 'REPORT',
      cancelButtonText: 'CANCEL',
      confirmButtonText: 'RETRY'
    });
  }

  success(title?: string, text?: string) {
    this.ninjaNotify.toast({
      icon: 'success',
      position: 'top',
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 3500
    });
  }
}
