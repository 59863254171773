import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiEngineComponent } from './ui-engine.component';
import { UiEngineRoutingModule } from './ui-engine-routing.module';
import { NinjaFormModule } from '../ninja-form/ninja-form.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [UiEngineComponent],
  imports: [
    CommonModule,
    UiEngineRoutingModule,
    NinjaFormModule
  ]
})
export class UiEngineModule { }
