import { SellerBuyerInfoActionTypes } from '../actions/sellerBuyerInfo.actions';

export type SellerBuyerInfoState = any;

const initialState: any = {};

export const sellerBuyerInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SellerBuyerInfoActionTypes.SetSellerBuyerInfo: {
      return {
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
