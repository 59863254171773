import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from "ngx-avatar";
import { NinjaContactListComponent } from './ninja-contact-list.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaContactListComponent],
  imports: [
    CommonModule,
    AvatarModule
  ],
  exports: [NinjaContactListComponent]
})
export class NinjaContactListModule { }
