import { Pipe, PipeTransform } from '@angular/core';
import { DataFilterService } from '../services/data-filter.service';

@Pipe({
  name: 'textType'
})
export class TextTypePipe implements PipeTransform {

  constructor(private dataFilter: DataFilterService) { }

  transform(formDataModel: any, binding: string): any {
    let modalObj = this.dataFilter.getformDataModalObjBinding(binding, formDataModel);
    if (modalObj?.type === 'string') { return 'text'; }
    if (modalObj?.type === 'double') { return 'number'; }
    return null;
  }

}
