import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaFormComponent } from './ninja-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { FormConfigPipe } from './data/pipes/form-config.pipe';
import { TextTypePipe } from './data/pipes/text-type.pipe';
import { FormControlKeyPipe } from './data/pipes/form-control-key.pipe';
import { NinjaAutocompleteModule }  from '../ninja-autocomplete/ninja-autocomplete.module';
import { NinjaButtonModule }  from '../ninja-button/ninja-button.module';
import {  }  from '../ninja-datepicker/ninja-datepicker.module';
import { NinjaHeaderModule }  from '../ninja-header/ninja-header.module';
import { NinjaRadioModule }  from '../ninja-radio/ninja-radio.module';
import { NinjaSelectModule }  from '../ninja-select/ninja-select.module';
import { NinjaTextBoxModule }  from '../ninja-text-box/ninja-text-box.module';
import { NinjaTextAreaModule }  from '../ninja-text-area/ninja-text-area.module';
import { NinjaUploadFileModule }  from '../ninja-upload-file/ninja-upload-file.module';
import { NinjaTextViewModule }  from '../ninja-text-view/ninja-text-view.module';
import { NinjaContactPickerModule }  from '../ninja-contact-picker/ninja-contact-picker.module';
import { NinjaDatepickerModule }  from '../ninja-datepicker/ninja-datepicker.module';
import { NinjaLocalContactListModule } from '../ninja-local-contact-list/ninja-local-contact-list.module';



@NgModule({
  declarations: [NinjaFormComponent, FormConfigPipe, TextTypePipe, FormControlKeyPipe],
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    ReactiveFormsModule,
    NinjaTextBoxModule,
    NinjaTextAreaModule,
    NinjaUploadFileModule,
    NinjaSelectModule,
    NinjaRadioModule,
    NinjaButtonModule,
    NinjaTextViewModule,
    NinjaHeaderModule,
    NinjaContactPickerModule,
    NinjaDatepickerModule,
    NinjaLocalContactListModule
  ],
  exports: [NinjaFormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NinjaFormModule { }
