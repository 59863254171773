import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ninja-switch',
  templateUrl: './ninja-switch.component.html',
  styleUrls: ['./ninja-switch.component.css']
})
export class NinjaSwitchComponent implements OnInit {

  @Input() active = false;
  @Output('change') onChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  change(e) {
    this.onChange.emit(e);
  }
}
