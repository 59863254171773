import { Action } from '@ngrx/store';
export enum GamificationActionTypes {
  ShowModal = 'ShowModal',
  CloseModal = 'CloseModal',
  TriggerEvent = 'TriggerEvent',
}

export class ShowModal implements Action {
  readonly type = GamificationActionTypes.ShowModal;
  constructor(public payload: any) {}
}

export class CloseModal implements Action {
  readonly type = GamificationActionTypes.CloseModal;
  constructor() {}
}

export class TriggerEvent implements Action {
  readonly type = GamificationActionTypes.TriggerEvent;
  constructor(public eventType, public config) {}
}

export type GamificationActions = ShowModal | CloseModal | TriggerEvent;
