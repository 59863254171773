import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NinjaTabbarVM } from 'ninja-modules';
import { Observable, Subject } from 'rxjs';
import { NetworkReleaseToUser, PartyConfigUsers, ReleaseNetworkToAll } from 'src/app/_config/network-release-config';
import { ReleaseMultiBankToAll, MultiBankReleaseToUser } from 'src/app/_config/multi-bank-release-config';
import { environment } from 'src/environments/environment';
import { ContentHeader } from '../_modals/content-header.modal';
import { StorageService } from 'src/app/_handlers/storage.service';
import { ReleasePGToAll, PG_ENABLED_USERS } from '../_config/payment-guarantee.config';
import { GENERIC_PAY_ENABLED_USERS, ReleaseGenericPayToAll } from '../_config/generic-pay.config';
import { HttpClient } from '@angular/common/http';
import { EKycService } from '../_services/e-kyc.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  contactStore: Observable<any>;
  showPgStatus = true;
  openAnyApp: boolean = false;
  public render_new_network_comp: boolean = false;
  public render_new_party_comp: boolean = false;
  public iframeUrl;
  iscommerceSdk: boolean = false;
  openCommerceSdk: Subject<any> = new Subject<any>();
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private store: Store<any>,
    private ekyc: EKycService,
    private _storage: StorageService
  ) {
    this.contactStore = this.store.select('contact');
    this.contactStore.subscribe((res) => {
      // capture event after login.
      if (res) {
        this.recheckComponentRenderer();
      }
    });
  }

  recheckComponentRenderer() {
    const authData = window.localStorage.authData;
    if (authData) {
      const parsedData = JSON.parse(authData);
      const { userId } = parsedData;
      this.render_new_network_comp = ReleaseNetworkToAll || NetworkReleaseToUser.includes(userId);
      this.render_new_party_comp = PartyConfigUsers.includes(userId);
    }
  }

  isMultiBankEnabled() {
    const userId = this._storage.authData?.userId;
    return ReleaseMultiBankToAll || MultiBankReleaseToUser.includes(userId);
  }

  isUserPgEnabled() {
    const userId = this._storage.authData?.userId;
    return ReleasePGToAll || PG_ENABLED_USERS.includes(userId);
  }

  isUserGenericPayEnabled() {
    const userId = this._storage.authData?.userId;
    return ReleaseGenericPayToAll || GENERIC_PAY_ENABLED_USERS.includes(userId);
  }

  contentHeaderSubject: Subject<ContentHeader> = new Subject();
  setActiveFooterTab$: Subject<NinjaTabbarVM> = new Subject();
  openFintechApp$: Subject<boolean> = new Subject();
  openVehicleTrackApp$: Subject<boolean> = new Subject();
  openMandiNewsApp$: Subject<boolean> = new Subject();
  openMyBrandingApp$: Subject<boolean> = new Subject();
  async getPaymentMenus(): Promise<NinjaTabbarVM[]> {
    this.recheckComponentRenderer();
    const resource = await this.getResourceAsync();
    return new Promise((resolve) => {
      let tabs;
      if (this._storage.isStaffView) {
        tabs = [
          {
            identifier: 1,
            icon: `${environment.assetsPath}/icons/footer/${this.render_new_network_comp ? 'home-old' : 'home-old'}`,
            text: resource?.homeTxt,
            route: '/app/content/dashboard',
            active: true,
          },
          {
            identifier: 5,
            icon: `${environment.assetsPath}/icons/footer/transactions-old`,
            text: resource?.transactionsTxt,
            route: '/app/content/trans-history',
            active: false,
          },
        ];
      } else {
        tabs = [
          {
            identifier: 1,
            icon: `${environment.assetsPath}/icons/footer/${this.render_new_network_comp ? 'home-old' : 'home-old'}`,
            text: resource?.homeTxt,
            route: '/app/content/dashboard',
            active: true,
          },
          {
            identifier: 2,
            icon: `${environment.assetsPath}/icons/footer/parties-old`,
            text: resource?.partiesTxt,
            route: '/app/content/select-party',
            active: false,
          },
          {
            identifier: 3,
            icon: `${environment.assetsPath}/icons/footer/${this.render_new_network_comp ? 'commerce' : 'orders-old'}`,
            text: this.render_new_network_comp ? resource?.commerce : resource?.contractsText,
            route: this.render_new_network_comp ? '' : '/app/content/orders-v2',
            active: false,
          },
          {
            identifier: 4,
            icon: `${environment.assetsPath}/icons/footer/loans-old`,
            text: 'Loans',
            route: '/app/content/fintech',
            active: false,
          },
          {
            identifier: 5,
            icon: `${environment.assetsPath}/icons/footer/transactions-old`,
            text: resource?.transactionsTxt,
            route: '/app/content/trans-history',
            active: false,
          },
        ];
      }
      resolve(tabs);
    });
  }

  async getSideMenus(): Promise<NinjaTabbarVM[]> {
    const resource = await this.getResourceAsync();
    return new Promise((resolve) => {
      if (this._storage.isStaffView) {
        resolve([
          {
            identifier: 3,
            icon: `${environment.assetsPath}/icons/my-profile.png`,
            text: resource?.myProfileTxt,
            route: '/app/content/my-profile',
            active: true,
            show: true,
          },
          {
            identifier: 4,
            icon: `${environment.assetsPath}/icons/help.png`,
            text: resource?.helpTxt,
            route: '/app/content/help',
            active: true,
            show: true,
          },
          {
            identifier: 6,
            icon: `${environment.assetsPath}/icons/language.png`,
            text: resource?.languageTxt,
            route: '',
            active: true,
            show: true,
          },
          {
            identifier: 5,
            icon: `${environment.assetsPath}/icons/terms.png`,
            text: resource?.termsAndConditions,
            route: '/terms-and-conditions',
            active: true,
            show: true,
          },
          {
            identifier: 8,
            icon: `${environment.assetsPath}/icons/privacy.png`,
            text: resource?.privacyPolicy,
            route: '/privacy-policy',
            active: true,
            show: true,
          },
          {
            identifier: 13,
            icon: `${environment.assetsPath}/icons/settings.png`,
            text: resource?.settings,
            route: '/app/content/settings',
            active: true,
            show: true,
          },
          {
            identifier: 11,
            icon: `${environment.assetsPath}/team-flow/team-icon.png`,
            text: 'Switch Business',
            route: '/switch-team',
            active: true,
            show: true,
          },
          {
            identifier: 7,
            icon: `${environment.assetsPath}/icons/logout.png`,
            text: resource?.logoutTxt,
            route: '',
            active: true,
            show: true,
          },
        ]);
      } else {
        resolve([
          {
            identifier: 1,
            icon: `${environment.assetsPath}/icons/my-profile.png`,
            text: resource?.myProfileTxt,
            route: '',
            active: true,
            show: this.render_new_network_comp ? true : false,
          },
          {
            identifier: 2,
            icon: this.render_new_network_comp
              ? `${environment.assetsPath}/icons/bank-icon.png`
              : `${environment.assetsPath}/icons/my-profile.png`,
            text: this.render_new_network_comp ? resource?.bankAccountDetails : resource?.myProfileTxt,
            route: '/new-profile',
            active: true,
            show: true,
          },
          {
            identifier: 3,
            icon: `${environment.assetsPath}/icons/notifications.png`,
            text: resource?.notificationTxt,
            route: '/app/content/notifications',
            active: true,
            show: true,
          },
          {
            identifier: 10,
            icon: `${environment.assetsPath}/team-flow/team-icon.png`,
            text: 'Team',
            route: '/app/content/team',
            active: true,
            show: true,
          },
          {
            identifier: 4,
            icon: `${environment.assetsPath}/icons/help.png`,
            text: resource?.helpTxt,
            route: '/app/content/help',
            active: true,
            show: true,
          },
          {
            identifier: 9,
            icon: `${environment.assetsPath}/cibil-score/creditScore.png`,
            text: 'Check your Credit Score',
            route: '',
            active: true,
            show: true,
          },
          {
            identifier: 6,
            icon: `${environment.assetsPath}/icons/language.png`,
            text: resource?.languageTxt,
            route: '',
            active: true,
            show: true,
          },
          {
            identifier: 5,
            icon: `${environment.assetsPath}/icons/terms.png`,
            text: resource?.termsAndConditions,
            route: '/terms-and-conditions',
            active: true,
            show: true,
          },
          {
            identifier: 8,
            icon: `${environment.assetsPath}/icons/privacy.png`,
            text: resource?.privacyPolicy,
            route: '/privacy-policy',
            active: true,
            show: true,
          },
          {
            identifier: 13,
            icon: `${environment.assetsPath}/icons/settings.png`,
            text: resource?.settings,
            route: '/app/content/settings',
            active: true,
            show: true,
          },
          {
            identifier: 11,
            icon: `${environment.assetsPath}/team-flow/team-icon.png`,
            text: 'Switch Business',
            route: '/switch-team',
            active: true,
            show: this._storage.toggleSwitchOrg ? true : false,
          },
          {
            identifier: 7,
            icon: `${environment.assetsPath}/icons/logout.png`,
            text: resource?.logoutTxt,
            route: '',
            active: true,
            show: true,
          },
        ]);
      }
    });
  }

  async getResourceAsync() {
    return await this.translate.get('resource').toPromise();
  }

  getUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }
}
