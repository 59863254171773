import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ninja-select',
  templateUrl: `./ninja-select.component.html`,
  styleUrls: [`./ninja-select.component.scss`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NinjaSelectComponent),
      multi: true
    }
  ]
})
export class NinjaSelectComponent implements OnInit, ControlValueAccessor {
  @Input() id;
  @Input() cssClass;
  @Input() label;
  @Input() bindLabel;
  @Input() dataApi;
  @Input() options;
  @Input() disabled = false;
  @Output() itemSelect = new EventEmitter<any>();
  selectedItem;
  @Input() data;
  onChange: any = () => { };
  onTouched: any = () => { };
  value: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    if (this.dataApi) {
      const asgardApi = `http://app.ninjacart.in/asgard`;
      const url = `${asgardApi}${this.dataApi}`;
      this.http.get(url, { headers: { Authorization: "Basic ZGVyaWw6MTIzNDU2" } }).subscribe(res => {
        this.data = res;
      });
    }
  }

  onItemSelect() {
    this.onChange(this.selectedItem);
    this.itemSelect.emit(this.selectedItem);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
