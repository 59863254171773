import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'agreement', loadChildren: () => import('./agreement/agreement.module').then((m) => m.AgreementModule) },
  { path: 'intro', loadChildren: () => import('./intro-banner/intro-banner.module').then((m) => m.IntroBannerModule) },
  {
    path: 'intro-one',
    loadChildren: () => import('./intro-banner-one/intro-banner-one.module').then((m) => m.IntroBannerOneModule),
  },
  {
    path: 'intro-two',
    loadChildren: () => import('./intro-banner-two/intro-banner-two.module').then((m) => m.IntroBannerTwoModule),
  },
  {
    path: 'intro-three',
    loadChildren: () => import('./intro-banner-three/intro-banner-three.module').then((m) => m.IntroBannerThreeModule),
  },
  {
    path: 'intro-four',
    loadChildren: () => import('./intro-banner-four/intro-banner-four.module').then((m) => m.IntroBannerFourModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then((m) => m.TermsConditionModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'select-language',
    loadChildren: () => import('./select-language/select-language.module').then((m) => m.SelectLanguageModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./login/capture-contact/capture-contact.module').then((m) => m.CaptureContactModule),
  },
  { path: 'otp', loadChildren: () => import('./login/verify-otp/verify-otp.module').then((m) => m.VerifyOtpModule) },
  {
    path: 'consent/:id/:d',
    loadChildren: () => import('./order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationModule),
  },
  {
    path: 'consent',
    loadChildren: () => import('./order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationModule),
  },
  {
    path: 'thank-you',
    loadChildren: () => import('./thank-you-page/thank-you-page.module').then((m) => m.ThankYouPageModule),
  },
  {
    path: 'pay-money/:d',
    loadChildren: () => import('./pay-money-account/pay-money-account.module').then((m) => m.PayMoneyAccountModule),
  },
  {
    path: 'trader-account/:d',
    loadChildren: () => import('./trader-account/trader-account.module').then((m) => m.TraderAccountModule),
  },
  { path: 'app', loadChildren: () => import('./main/main.module').then((m) => m.MainModule) },
  { path: 'e-kyc', loadChildren: () => import('./e-kyc/e-kyc.module').then((m) => m.EKycModule) },
  {
    path: 'generic-pay',
    loadChildren: () => import('./main/content/payment/generic-pay/generic-pay.module').then((m) => m.GenericPayModule),
  },
  {
    path: 'self-transfer',
    loadChildren: () =>
      import('./main/content/payment/self-transfer/self-transfer.module').then((m) => m.SelfTransferModule),
  },
  {
    path: 'pay-flow',
    loadChildren: () => import('./main/content/payment/pay-flow/payFlow.module').then((m) => m.PayFlowModule),
  },
  {
    path: 'new-profile',
    loadChildren: () => import('./main/content/new-profile/new-profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'link-account/bank-detail',
    loadChildren: () => import('./main/content/link-account/link-account.module').then((m) => m.LinkAccountModule),
  },
  {
    path: 'link-account',
    loadChildren: () => import('./main/content/bank-list/bank-list.module').then((m) => m.BankListModule),
  },
  {
    path: 'settlement-settings',
    loadChildren: () =>
      import('./main/content/settlement-settings/settlement-settings.module').then((m) => m.SettlementSettingsModule),
  },
  {
    path: 'party-account-details',
    loadChildren: () =>
      import('./main/content/party-account-details/party-accounts-details.module').then(
        (m) => m.PartyAccountDetailsModule
      ),
  },
  {
    path: 'qr-segment',
    loadChildren: () => import('./main/content/decentro-qr/decentro-qr.module').then((m) => m.DecentroQrModule),
  },
  {
    path: 'qr-ninja-upi',
    loadChildren: () => import('./main/content/qr-ninja-upi/qr-ninja-upi.module').then((m) => m.QrNinjaUpiModule),
  },
  {
    path: 'signup/contact',
    loadChildren: () => import('./signup/capture-contact/capture-contact.module').then((m) => m.CaptureContactModule),
  },
  {
    path: 'signup/otp',
    loadChildren: () => import('./signup/verify-otp/verify-otp.module').then((m) => m.VerifyOtpModule),
  },
  {
    path: 'ninjapay-transaction',
    loadChildren: () =>
      import('./main/content/transactions/ninjapay-transaction-history/ninjapay-transaction-history.module').then(
        (m) => m.NinjapayTransactionHistoryModule
      ),
  },
  { path: 'qrcode', redirectTo: 'contact', pathMatch: 'full' },
  {
    path: 'credit-score',
    loadChildren: () => import('./credit-score/credit-score.module').then((m) => m.CreditScoreModule),
  },
  {
    path: 'pending-kyc-score',
    loadChildren: () =>
      import('./credit-score/non-kyc-view-credit-score/non-kyc-view-credit-score.module').then(
        (m) => m.NonKycViewCreditScoreModule
      ),
  },
  {
    path: 'switch-team',
    loadChildren: () => import('./switch-team/switch-team.module').then((m) => m.SwitchTeamModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
