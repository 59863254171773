import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ninja-button',
  templateUrl: './ninja-button.component.html',
  styleUrls: ['./ninja-button.component.scss']
})
export class NinjaButtonComponent implements OnInit {

  @Input() id;
  @Input() buttonType = 'button';
  @Input() cssClass;
  @Input() buttonText = 'Submit';
  @Input() callBackData;
  @Input() cssProperties;
  @Input() data;
  @Output() click: EventEmitter<any> = new EventEmitter();

  constructor(private router : Router) { }

  ngOnInit(): void {
  }

  onClick(e) {
    if(this.data && this.data.link){
      this.router.navigate([this.data.link]);
    }
    this.click.emit({
      event: e,
      value: this.callBackData
    });
  }
}
