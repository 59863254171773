import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StorageService } from 'src/app/_handlers/storage.service';
import { Observable } from 'rxjs';
import { HttpApiResponseVM } from '../_modals/http-api-response';
import { ErrorHandlingService } from './error-handling.service';
import { BusinessTypeMapEnum, KycTypeEnum, KYC_LITE_STATUS, KYC_STATUS } from '../_modals/e-kyc.modal';

@Injectable({
  providedIn: 'root',
})
export class EKycService {
  private workflowUrl: string;
  private kycUrl: string;

  public ekycStatus;
  public ekycLiteStatus;
  public isKycReupload: boolean = false;

  constructor(
    private handler: HttpBackend,
    private storage: StorageService,
    private http: HttpClient,
    private error: ErrorHandlingService
  ) {
    this.workflowUrl = environment.apiUrl.workflow;
    this.kycUrl = environment.apiUrl.kyc;
  }

  getHeaders() {
    return {
      Authorization: `Basic bWF5YW5rOmNyQHp5bUBydmVs`,
      Application: 'biFrost',
    };
  }

  getSummary(payload: any): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/kyc-user-action-summary`;
    return this.http.post(url, payload).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  fetchDocumentStatus(postObj): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/kyc-fetch-document-status`;
    return this.http.post(url, postObj).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }
  sendDocument(postObj): Observable<HttpApiResponseVM> {
    let promise: HttpClient = new HttpClient(this.handler);
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.kycUrl}/realm/${realmObj?.realmIdentifier}/user/${authData?.systemUserId}/karza/upload`;
    return promise
      .post(url, postObj, { headers: { Authorization: `Bearer ${authData.token}` } })
      .pipe(map((m: any) => m));
  }

  sendAadhaarOtp(postObj): Observable<HttpApiResponseVM> {
    let promise: HttpClient = new HttpClient(this.handler);
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.kycUrl}/realm/${realmObj?.realmIdentifier}/user/${authData?.systemUserId}/karza/aadhaar/trigger-aadhaar-otp`;
    return promise
      .post(url, postObj, { headers: { Authorization: `Bearer ${authData.token}` } })
      .pipe(map((m: any) => m));
  }

  verifyAadhaarOtp(postBody): Observable<HttpApiResponseVM> {
    let promise: HttpClient = new HttpClient(this.handler);
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.kycUrl}/realm/${realmObj?.realmIdentifier}/user/${authData?.systemUserId}/karza/aadhaar/aadhaar-xml-download`;
    return promise.post(url, postBody, { headers: { Authorization: `Bearer ${authData.token}` } }).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }
  getResourcesPermissionDetails(bodyData) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/generic-aggregator-get`;
    return this.http.post(url, bodyData).pipe(map((m: any) => m));
  }
  acceptResourcesPermission(bodyData) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/generic-urii-create`;
    return this.http.post(url, bodyData).pipe(map((m: any) => m));
  }

  initiateDigio(postBody): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/kyc-digio-initiate`;
    return this.http.post(url, postBody).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  callBackStatusDigio(postBody): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/kyc-digio-callback-status-fetch`;
    return this.http.post(url, postBody).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  fetchAadhaarDocumentConsent(): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const _http = new HttpClient(this.handler);
    const url = `${this.kycUrl}/realm/${realmObj?.realmIdentifier}/user/${authData.systemUserId}/consent/consent-types?consentType=AADHAAR_DETAILS_CONSENT`;
    return _http
      .get(url, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  createSrmTicket(payload): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/kyc-ticket-creation`;
    let srmTicket: HttpClient = new HttpClient(this.handler);
    return srmTicket
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  submitKycWithoutSrmTicket(payload): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/kyc-submit`;
    let promise: HttpClient = new HttpClient(this.handler);
    return promise
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  checkConsentStatus(params): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/get-consent-string-for-kyc`;
    return this.http.post(url, params).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  postConsentStatus(params): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/create-consent-for-kyc`;
    return this.http.post(url, params).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  deleteDocument(params): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;

    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/remove-user-document?userId=${authData?.systemUserId}&kycRequestId=${params?.kycRequestId}&documentType=${params?.documentType}`;
    return this.http.post(url, params).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  fetchEntitySubTypes(params): Observable<HttpApiResponseVM> {
    const _http = new HttpClient(this.handler);
    const url = `${this.kycUrl}/actions/entitySubType`;
    return _http.get(url, { params, headers: this.getHeaders() }).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  fetchEntitySubTypesV2(params): Observable<HttpApiResponseVM> {
    const _http = new HttpClient(this.handler);
    const url = `${this.kycUrl}/actions/entitySubTypes`;
    return _http.get(url, { params, headers: this.getHeaders() }).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }
  fetchDocumentConsent(): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const _http = new HttpClient(this.handler);
    const url = `${this.kycUrl}/realm/${realmObj?.realmIdentifier}/user/${authData.systemUserId}/consent/id?id=1`;
    return _http
      .get(url, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }
  updateKycUserActionStatus(params): Observable<HttpApiResponseVM> {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${authData?.userId}/v1/execution/service/run/kyc-user-action-update-status`;
    return this.http.post(url, params).pipe(
      map((m: any) => m),
      catchError(this.catchError.bind(this))
    );
  }

  private async catchError(error: HttpErrorResponse | any) {
    this.error.catchErrorHandling(error);
  }

  showKycAlert(data): boolean {
    if (!data?.actionSummary || data?.actionSummary?.length === 0) {
      return true;
    }
    if (data?.isTicketPending) {
      return false;
    }
    if (data?.actionSummary?.length > 0) {
      const _verifiedSummaryList = data?.actionSummary.filter((m) => m.status === 'ALLOWED');
      if (_verifiedSummaryList?.length > 0) {
        return false;
      }
    }
    return true;
  }

  setEkycStatus(status) {
    this.ekycStatus = status;
  }

  getEkycStatus() {
    return this.ekycStatus;
  }

  setEkycLiteStatus(status) {
    this.ekycLiteStatus = status;
  }

  getEkycLiteStatus() {
    return this.ekycLiteStatus;
  }

  clearEntityTypeParams() {
    this.storage.entityTypeParams = { entityType: null, entitySubType: null };
  }

  checkForKyc() {
    const payload = {
      userTypeId: environment.userTypeId,
    };

    return this.getSummary(payload).pipe(
      tap((res) => {
        this.isKycReupload = false;
        if (!res?.success || !res?.data) return;

        const { actionSummary, isTicketPending, isReupload } = res.data[0];

        if (!actionSummary?.length) {
          this.setEkycStatus(KYC_STATUS.PENDING);
          this.clearEntityTypeParams();
          return;
        }

        if (isTicketPending) {
          this.setEkycStatus(KYC_STATUS.PROGRESS);
          return;
        }

        const isVerified = actionSummary.some((m) => m.status === 'ALLOWED');
        if (isVerified) {
          this.setEkycStatus(KYC_STATUS.VERIFIED);
          this.clearEntityTypeParams();
          return;
        }

        if (isReupload) {
          this.isKycReupload = true;
          this.setEkycStatus(KYC_STATUS.PROGRESS);
          return;
        }

        this.setEkycStatus(KYC_STATUS.PENDING);
        this.setEntityTypeParams(actionSummary);
      })
    );
  }

  checkForKycLite() {
    const payload = {
      userTypeId: environment.userTypeIdLite,
    };

    return this.getSummary(payload).pipe(
      tap((res) => {
        if (!res?.success || !res?.data) return;

        const { actionSummary, isTicketPending, isReupload } = res.data[0];

        if (!actionSummary?.length) {
          this.setEkycLiteStatus(KYC_LITE_STATUS.PENDING);
          return;
        }

        if (isTicketPending) {
          this.setEkycLiteStatus(KYC_LITE_STATUS.PROGRESS);
          return;
        }

        const isVerified = actionSummary.some((m) => m.status === 'ALLOWED');
        if (isVerified) {
          this.setEkycLiteStatus(KYC_LITE_STATUS.VERIFIED);
          return;
        }

        if (isReupload) {
          this.setEkycLiteStatus(KYC_LITE_STATUS.PENDING);
          return;
        }

        this.setEkycLiteStatus(KYC_LITE_STATUS.PENDING);
      })
    );
  }

  setEntityTypeParams(actionSummary) {
    let _pendingSummaryList = this.getPendingSummaryList(actionSummary);
    if (_pendingSummaryList?.length > 0) {
      const [pendingSummary = {}] = _pendingSummaryList;
      let entityType, entitySubType;
      if (pendingSummary?.action_name) {
        let actionNameAsArray = pendingSummary?.action_name?.split('_');
        const [app, kycType, businessType, subType] = actionNameAsArray;
        entityType = kycType;
        if (businessType && businessType.length > 0) {
          if (subType && subType.length > 0) {
            entitySubType = BusinessTypeMapEnum[businessType + '_' + subType];
          } else {
            entitySubType = BusinessTypeMapEnum[businessType];
          }
        }
      }
      this.storage.entityTypeParams = {
        entityType: entityType,
        entitySubType: entitySubType,
      };
    }
  }

  getPendingSummaryList(kycActions) {
    let _pendingSummaryList = [];
    let businessStatusList = [];
    let individualStatusList = [];
    kycActions.forEach((action) => {
      if (action.status === 'PENDING') {
        if (action.action_name && action.action_name?.includes(KycTypeEnum.Business)) {
          businessStatusList.push(action);
        } else {
          individualStatusList.push(action);
        }
      }
    });
    if (businessStatusList?.length > 0) {
      _pendingSummaryList = businessStatusList;
    } else {
      _pendingSummaryList = individualStatusList;
    }
    return _pendingSummaryList;
  }
}
