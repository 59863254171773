import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as ReturnUrlActions from '../actions/returnUrl.actions';

@Injectable({ providedIn: 'root' })
export class ReturnUrlFacade {
  constructor(private store: Store<any>) {}

  returnUrlStore = this.store.select('returnUrl').pipe(
    take(1),
    map((res: string) => res)
  );

  setReturnUrl(url: string) {
    this.store.dispatch(new ReturnUrlActions.SetReturnUrl(url));
  }
}
