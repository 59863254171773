import { Action, createAction, props } from '@ngrx/store';
import { TransactionResponseVM } from 'src/app/_modals/transaction-response.modal';

export enum TransactionActionTypes {
  Transaction_Loading = 'Transaction_Loading',
  Transaction_Fulfilled = 'Transaction_Fulfilled',
  Transaction_Failed = 'Transaction_Failed',
  Transaction_Reload = 'Transaction_Reload',
  Transaction_Selected = 'Transaction_Selected',
  Transaction_LoadNextSet = 'Transaction_LoadNextSet',
  Transaction_EmptyDataReceived = 'Transaction_EmptyDataReceived',
  Transaction_EmptyDataReceived_Reset = 'Transaction_EmptyDataReceived_Reset',
}

export class TransactionLoading implements Action {
  readonly type = TransactionActionTypes.Transaction_Loading;
  constructor(public userId: any) {}
}

export class TransactionFulfilled implements Action {
  readonly type = TransactionActionTypes.Transaction_Fulfilled;
  constructor(public payload: TransactionResponseVM[]) {}
}

export class TransactionFailed implements Action {
  readonly type = TransactionActionTypes.Transaction_Failed;
  constructor() {}
}

export class TransactionReload implements Action {
  readonly type = TransactionActionTypes.Transaction_Reload;
  constructor() {}
}

export class TransactionSelected implements Action {
  readonly type = TransactionActionTypes.Transaction_Selected;
  constructor(public payload: TransactionResponseVM[]) {}
}

export class TransactionLoadNextSet implements Action {
  readonly type = TransactionActionTypes.Transaction_LoadNextSet;
  constructor(public payload: TransactionResponseVM[]) {}
}

export class TransactionEmptyDataReceived implements Action {
  readonly type = TransactionActionTypes.Transaction_EmptyDataReceived;
  constructor(public payload: TransactionResponseVM[]) {}
}

export class TransactionEmptyDataReceivedReset implements Action {
  readonly type = TransactionActionTypes.Transaction_EmptyDataReceived_Reset;
  constructor(public payload: TransactionResponseVM[]) {}
}

export type TransactionActions =
  | TransactionLoading
  | TransactionFulfilled
  | TransactionFailed
  | TransactionReload
  | TransactionSelected
  | TransactionLoadNextSet
  | TransactionEmptyDataReceived
  | TransactionEmptyDataReceivedReset;
