import { Action, createReducer, on } from '@ngrx/store';
import { ContractsActionTypes } from '../actions/contract-details.actions';

export const contractDetailsFeatureKey = 'contractDetails';

interface ContractDocuments {
  id: number;
  orderId: number;
  type: string;
  value: string;
  description: string;
}

interface ContractStatusTrackerDTO {
  orderId: number;
  statusTypeId: number;
  statusTypeText: string;
  statusDateTime: string;
  statusModifiedBy: number;
  statusComment: string;
  active: boolean;
}

export interface ContractDetails {
  id: number;
  orderId: number;
  cartId: any;
  sellerId: number;
  buyerId: number;
  sellerName: string;
  buyerName: string;
  pickUpAddress: any;
  deliveryAddress: any;
  orderDate: string;
  deliveryDate: any;
  paymentDate: string;
  paymentModeId: any;
  deliveryCharge: any;
  orderTotal: number;
  discountCode: any;
  status: number;
  type: number;
  subType: number;
  orderChannelId: any;
  costCenterId: any;
  consignmentId: any;
  outstandingAmount: number;
  refType: string;
  refId: number;
  facilityId: any;
  ownerId: number;
  consentId: number;
  orderAdditionalInfoId: number;
  invoiceImageUrl: any;
  invoiceImageUploadedAt: any;
  invoiceImageUploadedBy: any;
  destinationFacilityId: any;
  otpStatus: any;
  groupingType: any;
  sellShootQuantity: any;
  sellShootPrice: any;
  destinationCommissionAmount: any;
  realmId: string;
  sellerContactNumber: string;
  buyerContactNumber: string;
  dontDeleteOtherItems: boolean;
  persistSkuName: boolean;
  orderItems: [];
  paymentDTO: any;
  orderDocuments: ContractDocuments[];
  orderStatusTrackerDTOS: ContractStatusTrackerDTO[];
  statusText: string;
  paymentStatus: number;
  paymentStatusText: string;
}

export type ContractState = ContractDetails;

const initialState = {
  contractDetails: [],
  isContractFailed: false,
  isContractLoading: false,
  isContractFullFilled: false,
  reloadContracts: false,
};

export const contractDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ContractsActionTypes.Contract_Loading: {
      return {
        ...state,
        isContractFailed: false,
        isContractLoading: true,
        isContractFullFilled: false,
        reloadContracts: false,
      };
    }
    case ContractsActionTypes.Contract_Fulfilled: {
      return {
        ...state,
        contractDetails: action.payload,
        isContractFailed: false,
        isContractLoading: false,
        isContractFullFilled: true,
        reloadContracts: true,
      };
    }
    case ContractsActionTypes.Contract_Failed: {
      return {
        ...state,
        contractDetails: {},
        isContractFailed: true,
        isContractLoading: false,
        isContractFullFilled: false,
        reloadContracts: false,
      };
    }
    case ContractsActionTypes.Contract_Reload:
      return {
        ...state,
        reloadContracts: true,
      };
    default: {
      return state;
    }
  }
};
