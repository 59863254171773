import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadDashboard, DashboardCollectAssure, DashboardFulfilled } from '../actions/Dashboard.actions';

@Injectable({ providedIn: 'root' })
export class DashboardFacade {
  constructor(private store: Store<any>) {}

  dashboardInfo$ = this.store.select('dashboard');

  loadDashboard(payload) {
    this.store.dispatch(new LoadDashboard(payload));
  }

  toggleCollectAssure(payload: {
    isCollectAssure: boolean;
    collectAssureProduct?: string;
    isSellingTrade?: boolean;
    backNavSellingTrade?: boolean;
    sellingTradeData?: any;
    contractData?: any;
    placeholderToggle?: boolean;
    isKnowMyTrader?: boolean;
    knowMyTraderProduct?: string;
  }) {
    this.store.dispatch(new DashboardCollectAssure(payload));
  }

  triggerDashBoardFullFilled(payload) {
    this.store.dispatch(new DashboardFulfilled(payload));
  }
}
