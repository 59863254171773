import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as QrCodeActions from '../actions/qrCode.actions';

@Injectable({ providedIn: 'root' })
export class QrCodeFacade {
  constructor(private store: Store<any>) {}

  partiesStore = this.store.select('parties');

  showQrCode(upiId, collectFrom) {
    this.store.dispatch(new QrCodeActions.ShowQrCode(upiId, collectFrom));
  }
  hideQrCode() {
    this.store.dispatch(new QrCodeActions.HideQrCode());
  }

  showQrScanner() {
    this.store.dispatch(new QrCodeActions.ShowQrScanner());
  }
  hideQrScanner() {
    this.store.dispatch(new QrCodeActions.HideQrScanner());
  }
  showQrAmountValidation() {
    console.log('showQrAmountValidation ---');

    this.store.dispatch(new QrCodeActions.ShowQrAmountValidation());
  }
  hideQrAmountValidation() {
    console.log('hideQrAmountValidation ---');

    this.store.dispatch(new QrCodeActions.HideQrAmountValidation());
  }
  showGenericPay() {
    this.store.dispatch(new QrCodeActions.ShowGenericPay());
  }
  hideGenericPay() {
    this.store.dispatch(new QrCodeActions.HideGenericPay());
  }
  showSelfTransfer() {
    this.store.dispatch(new QrCodeActions.ShowSelfTransfer());
  }
  hideSelfTransfer() {
    this.store.dispatch(new QrCodeActions.HideSelfTransfer());
  }

  resetState() {
    this.store.dispatch(new QrCodeActions.ResetState());
  }
}
