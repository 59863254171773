import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AppConstants {
  partiesExpiryKey = 'PARTIESDATA_LOCALSTORAGE_TIME';
  transactionExpiryKey = 'TRANSACTIONDATA_LOCALSTORAGE_TIME';
  contractsExpiryKey = 'CONTRACTS_LOCALSTORAGE_TIME';
  dashboardExpiryKey = 'DASHBOARD_LOCALSTORAGE_TIME';
}

export const TRANSACTION_TYPES = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
  RECHARGE: 'RECHARGE',
  WITHDRAW: 'WITHDRAW',
};

export const PAYMENT_TYPES = {
  PAY_LATER: 'PAY_LATER',
  CASHBACK: 'CASHBACK',
  CASH: 'CASH',
  RTGS: 'RTGS',
};

export const PAYMENT_STATUS = {
  INIT: 'INIT',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  REQUESTED: 'REQUESTED',
  FAILED: 'FAILED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const TRANSACTION_REF_TYPE = {
  B2S2: 'B2S2',
  D2S: 'D2S',
};

export const LoanHelpline = '08035018270';
