import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppHttpService } from '../../shared/services/app-http.service';
import { Observable, of } from "rxjs";
declare var $: any;
import { WorkFlow } from '../view-models/workflow.modal';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  // directNinja = `http://${environment.apiUrl.directNinja}`;
  // procurement = `http://${environment.apiUrl.procurement}`;
  // stagingAsgardOdin = `http://${environment.apiUrl.stagingAsgardOdin}`;
  // operationBaseUrl = `http://${environment.apiUrl.operationBaseUrl}`;

  constructor(private httpService: AppHttpService) { }

  getFormConfig(payload): Observable<any> {
    let url = `http://qa.ninjacart.in:7205/workflowEngine/v1/execution/1/request`;
    return this.httpService.post(url, payload);
  }

  getFormElement(payload): Observable<any> {
    let url = `http://qa.ninjacart.in:7205/workflowEngine/form-element/findByIdNameVersion`;
    return this.httpService.post(url, payload);
  }

  getWorkFlowData(): Observable<WorkFlow[]> {
    return of<WorkFlow[]>([
      { pageType: 'header' },
      { pageType: 'tabs' }
    ])
  }

  getData() {
    return {
      "workflowInstanceId": "8991",
      "action": "FORM",
      "nextUrl": null,
      "payload": {
        "id": "8411000",
        "name": "getCreditForm",
        "version": 2,
        "formDefinition": {
          "validationUrl": null,
          "submitUrl": "https://localhost:8450/workflowEngine/test/submit/getCreditWorkflow/getCreditApplicationFormTask/getCredit_3/8991",
          "formModel": {
            "id": "841001",
            "name": "getCredit",
            "version": 3
          },
          "formDataModel": {
            "id": "8411000",
            "name": "getCredit",
            "version": 2,
            "modelDefinition": {
              "type": "object",
              "properties": {
                "buyer": {
                  "required": true,
                  "key": true,
                  "type": "object",
                  "properties": {
                    "id": {
                      "type": "integer",
                      "key": true,
                      "required": true
                    },
                    "userName": {
                      "type": "string",
                      "required": true,
                      "key": true
                    }
                  }
                },
                "tradeType": {
                  "required": true,
                  "key": true,
                  "type": "object",
                  "properties": {
                    "name": {
                      "required": true,
                      "key": true,
                      "type": "string"
                    },
                    "id": {
                      "required": true,
                      "key": true,
                      "type": "integer"
                    }
                  }
                },
                "tradeValue": {
                  "type": "double",
                  "required": true,
                  "key": true
                },
                "pattiUrl": {
                  "type": "string",
                  "required": true,
                  "key": true
                }
              }
            },
            "active": false,
            "status": null,
            "createdAt": null,
            "updatedAt": null
          },
          "formData": {
            "buyer": {
              "userName": "VK Traders"
            }
          },
          "componentList": [
            {
              "name": "NinjacartHeader",
              "version": 1,
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "Get Credit",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                  "cssProperties": {
                    "color": "white",
                    "background-color": "#569b40",
                    "padding": "0.5rem"
                  }
                }
              }
            },
            {
              "name": "NinjacartTextView",
              "version": 1,
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "Availing credit has never been this easy before! Follow 4 simple steps and get your money in account:",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                  "cssProperties": {
                    "color": "black",
                    "background-color": "white"
                  }
                }
              }
            },
            {
              "name": "NinjacartWizard",
              "version": 1,
              "formElementDefinition": {
                "mandatoryProperties": {
                  "header": true
                }
              },
              "componentList": [
                {
                  "name": "NinjacartStep",
                  "version": 1,
                  "formElementDefinition": {
                    "mandatoryProperties": {
                      "label": "Trade Type",
                      "labelVisible": true,
                      "backAllowed": true,
                      "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    }
                  },
                  "componentList": [
                    {
                      "name": "NinjacartTextView",
                      "version": 1,
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Step 1: Select Trade Type",
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        },
                        "cssProperties": {
                          "color": "pink",
                          "background-color": "lightblue"
                        }
                      }
                    },
                    {
                      "name": "NinjacartRadioButton",
                      "binding": "tradeType.name",
                      "bindingType": "text",
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "orientation": "VERTICAL",
                          "possibleValues": [
                            {
                              "id": 1,
                              "name": "Commission (Sales) mode"
                            },
                            {
                              "id": 2,
                              "name": "Fixed order (Bilti) mode"
                            }
                          ],
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        }
                      }
                    },
                    {
                      "name": "NinjacartButton",
                      "version": 1,
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Proceed",
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center",
                          "cssProperties": {
                            "color": "white",
                            "background-color": "#569b40"
                          },
                          "forward": true,
                          "submit": false
                        }
                      }
                    }
                  ]
                },
                {
                  "name": "NinjacartStep",
                  "version": 1,
                  "formElementDefinition": {
                    "mandatoryProperties": {
                      "label": "Details",
                      "labelVisible": true,
                      "backAllowed": true,
                      "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    }
                  },
                  "componentList": [
                    {
                      "name": "NinjacartTextView",
                      "version": 1,
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Step 2: Add in the value of sales. For Commissions(sales) mode, you can add tentative Patti and sale value",
                          "editable": false,
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        },
                        "cssProperties": {
                          "color": "pink",
                          "background-color": "lightblue"
                        }
                      }
                    },
                    {
                      "name": "NinjacartTextBox",
                      "version": 1,
                      "binding": "buyer.userName",
                      "bindingType": "string",
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Buyer",
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          "showCross": true,
                          "disabled": true,
                          "placeHolder": "Enter Buyer ..."
                        },
                        "cssProperties": {
                          "border": "2px solid grey",
                          "border-radius": "10px"
                        },
                        "optionalProperties": {
                          "showCross": {
                            "font-size": "24px",
                            "position": "absolute",
                            "top": "15px",
                            "right": "15px",
                            "color": "#569b40"
                          }
                        }
                      }
                    },
                    {
                      "name": "NinjacartTextBox",
                      "version": 1,
                      "binding": "tradeValue",
                      "bindingType": "number",
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Trade Value",
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          "showCross": true,
                          "disabled": false,
                          "placeHolder": "Enter Trade Value ..."
                        },
                        "cssProperties": {
                          "border": "2px solid grey",
                          "border-radius": "10px"
                        },
                        "optionalProperties": {
                          "showCross": {
                            "font-size": "24px",
                            "position": "absolute",
                            "top": "15px",
                            "right": "15px",
                            "color": "#569b40"
                          }
                        }
                      }
                    },
                    {
                      "name": "NinjacartButton",
                      "version": 1,
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Cancel",
                          "layout": "col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center",
                          "cssProperties": {
                            "color": "white",
                            "background-color": "#569b40"
                          },
                          "forward": false,
                          "submit": false
                        }
                      }
                    },
                    {
                      "name": "NinjacartButton",
                      "version": 1,
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Proceed",
                          "layout": "col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center",
                          "cssProperties": {
                            "color": "white",
                            "background-color": "#569b40"
                          },
                          "forward": true,
                          "submit": false
                        }
                      }
                    }
                  ]
                },
                {
                  "name": "NinjacartStep",
                  "version": 1,
                  "formElementDefinition": {
                    "mandatoryProperties": {
                      "label": "Upload",
                      "labelVisible": true,
                      "backAllowed": true,
                      "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    },
                    "optionalProperties": {
                      "submittable": true
                    }
                  },
                  "componentList": [
                    {
                      "name": "NinjacartTextView",
                      "version": 1,
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Step 3: Upload your patti",
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        },
                        "cssProperties": {
                          "color": "pink",
                          "background-color": "lightblue"
                        }
                      }
                    },
                    {
                      "id": 123,
                      "name": "NinjacartUpload",
                      "version": 1,
                      "binding": "pattiUrl",
                      "bindingType": "string",
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Upload Patti",
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                          "placeHolder": "Choose a file...",
                          "cssProperties": {
                            "height": 0,
                            "overflow": "hidden",
                            "width": 0
                          }
                        },
                        "optionalProperties": {
                          "label": {
                            "background": "#569b40",
                            "border": "none",
                            "border-radius": "5px",
                            "color": "#fff",
                            "cursor": "pointer",
                            "padding": "0.2rem"
                          }
                        }
                      }
                    },
                    {
                      "name": "NinjacartButton",
                      "version": 1,
                      "formElementDefinition": {
                        "mandatoryProperties": {
                          "label": "Submit",
                          "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center",
                          "cssProperties": {
                            "color": "white",
                            "background-color": "#569b40",
                            "width": "80%"
                          },
                          "forward": true,
                          "submit": true
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ],
          "properties": {
            "formType": "WIZARD",
            "flowType": "HORIZONTAL"
          }
        }
      }
    };
  }

  getCreateProfileWorkflow() {
    return {
      "workflowInstanceId": "8991",
      "action": "FORM",
      "nextUrl": null,
      "payload": {
        "id": "8411000",
        "name": "getCreditForm",
        "version": 2,
        "formDefinition": {
          "validationUrl": null,
          "submitUrl": "https://localhost:8450/workflowEngine/test/submit/getCreditWorkflow/getCreditApplicationFormTask/getCredit_3/8991",
          "formModel": {
            "id": "841001",
            "name": "getCredit",
            "version": 3
          },
          "formDataModel": {
            "id": "8411000",
            "name": "getCredit",
            "version": 2,
            "modelDefinition": {
              "type": "object",
              "properties": {
                "businessName": {
                  "required": true,
                  "key": true,
                  "type": "string"
                },
                "acHolderName": {
                  "required": true,
                  "key": true,
                  "type": "string"
                },
                "ifsc": {
                  "type": "string",
                  "required": true,
                  "key": true
                },
                "accountNo": {
                  "type": "string",
                  "required": true,
                  "key": true
                },
                "upiID": {
                  "type": "string",
                  "required": true,
                  "key": true
                }
              }
            },
            "active": false,
            "status": null,
            "createdAt": null,
            "updatedAt": null
          },
          "formData": {
            "buyer": {
              "userName": "VK Traders"
            }
          },
          "componentList": [
            {
              "name": "NinjacartTextBox",
              "version": 1,
              "binding": "businessName",
              "bindingType": "string",
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "Your Business Name",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                  "showCross": true,
                  "disabled": false,
                  "placeHolder": "Raj Traders"
                },
                "cssProperties": {
                  "border": "2px solid grey",
                  "border-radius": "10px"
                },
                "optionalProperties": {
                  "showCross": {
                    "font-size": "24px",
                    "position": "absolute",
                    "top": "15px",
                    "right": "15px",
                    "color": "#569b40"
                  }
                }
              }
            },
            {
              "name": "NinjacartTextBox",
              "version": 1,
              "binding": "acHolderName",
              "bindingType": "string",
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "Enter Account Holder Name",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                  "showCross": true,
                  "disabled": false,
                  "placeHolder": "Raj Verma"
                },
                "cssProperties": {
                  "border": "2px solid grey",
                  "border-radius": "10px"
                },
                "optionalProperties": {
                  "showCross": {
                    "font-size": "24px",
                    "position": "absolute",
                    "top": "15px",
                    "right": "15px",
                    "color": "#569b40"
                  }
                }
              }
            },
            {
              "name": "NinjacartTextBox",
              "version": 1,
              "binding": "ifsc",
              "bindingType": "string",
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "Your IFSC Code",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                  "showCross": true,
                  "disabled": false,
                  "placeHolder": "ABCD1235"
                },
                "cssProperties": {
                  "border": "2px solid grey",
                  "border-radius": "10px"
                },
                "optionalProperties": {
                  "showCross": {
                    "font-size": "24px",
                    "position": "absolute",
                    "top": "15px",
                    "right": "15px",
                    "color": "#569b40"
                  }
                }
              }
            },
            {
              "name": "NinjacartTextBox",
              "version": 1,
              "binding": "accountNo",
              "bindingType": "string",
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "Account No",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                  "showCross": true,
                  "disabled": false,
                  "placeHolder": "00000000000000"
                },
                "cssProperties": {
                  "border": "2px solid grey",
                  "border-radius": "10px"
                },
                "optionalProperties": {
                  "showCross": {
                    "font-size": "24px",
                    "position": "absolute",
                    "top": "15px",
                    "right": "15px",
                    "color": "#569b40"
                  }
                }
              }
            },
            {
              "name": "NinjacartTextBox",
              "version": 1,
              "binding": "upiID",
              "bindingType": "string",
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "UPI ID",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12",
                  "showCross": true,
                  "disabled": false,
                  "placeHolder": "Enter UPI ID"
                },
                "cssProperties": {
                  "border": "2px solid grey",
                  "border-radius": "10px"
                },
                "optionalProperties": {
                  "showCross": {
                    "font-size": "24px",
                    "position": "absolute",
                    "top": "15px",
                    "right": "15px",
                    "color": "#569b40"
                  }
                }
              }
            },
            {
              "name": "NinjacartButton",
              "version": 1,
              "formElementDefinition": {
                "mandatoryProperties": {
                  "label": "Proceed",
                  "layout": "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center",
                  "cssProperties": {
                    "color": "white",
                    "background-color": "#569b40"
                  },
                  "forward": false,
                  "submit": true
                }
              }
            }
          ],
          "properties": {
            "formType": "WIZARD",
            "flowType": "HORIZONTAL"
          }
        }
      }
    };
  }
}
