import { Action } from '@ngrx/store';

export enum AddTradeActionTypes {
  Add_Trade_State = 'Add_Trade_State',
  Reset_Add_Trade_State = 'Reset_Add_Trade_State',
}

export class AddTradeState implements Action {
  readonly type = AddTradeActionTypes.Add_Trade_State;
  constructor(public payload: any) {}
}

export class ResetAddTradeState implements Action {
  readonly type = AddTradeActionTypes.Reset_Add_Trade_State;
  constructor() {}
}

export type AddTradeActions = AddTradeState | ResetAddTradeState;
