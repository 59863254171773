import { Action, createAction, props } from '@ngrx/store';
import { ContractDetailsVM } from 'src/app/_modals/ContractDetails.modal';

export enum ContractsActionTypes {
  Contract_Loading = 'Contract_Loading',
  Contract_Fulfilled = 'Contract_Fulfilled',
  Contract_Failed = 'Contract_Failed',
  Contract_Reload = 'Contract_Reload',
}

export class ContractLoading implements Action {
  readonly type = ContractsActionTypes.Contract_Loading;
  constructor(public userId: any) {}
}

export class ContractFulfilled implements Action {
  readonly type = ContractsActionTypes.Contract_Fulfilled;
  constructor(public payload: ContractDetailsVM[]) {}
}

export class ContractFailed implements Action {
  readonly type = ContractsActionTypes.Contract_Failed;
  constructor() {}
}

export class ContractReload implements Action {
  readonly type = ContractsActionTypes.Contract_Reload;
  constructor(public userId: any) {}
}

export type ContractsActions = ContractLoading | ContractFulfilled | ContractFailed | ContractReload;
