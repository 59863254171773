import { DemandOtpActionTypes } from '../actions/demand-otp.actions';

export type DemandOtpState = boolean;

const initialState: boolean = false;

export const demandReducer = (state = initialState, action) => {
  switch (action.type) {
    case DemandOtpActionTypes.SetIsDemandOTP: {
      return action.value;
    }
    default: {
      return state;
    }
  }
};
