import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as SellerBuyerActions from '../actions/sellerBuyerInfo.actions';

@Injectable({ providedIn: 'root' })
export class SellerBuyerFacade {
  constructor(private store: Store<any>) {}

  sellerBuyerStore = this.store.select('sellerBuyerInfo');

  setInfo(payload: any) {
    this.store.dispatch(new SellerBuyerActions.SetSellerBuyerInfo(payload));
  }
}
