import { Action } from '@ngrx/store';

export enum AmountActionsTypes {
  Get_Amount = 'Get_Amount',
  Amount_Successful = 'Amount_Successful',
  Amount_Failure = 'Amount_Failure',
  Set_Amount = 'Set_Amount',
  Amount_Reset = 'Amount_Reset',
}
export class GetAmount implements Action {
  readonly type = AmountActionsTypes.Get_Amount;
  constructor() {}
}
export class SetAmount implements Action {
  readonly type = AmountActionsTypes.Set_Amount;
  constructor(public payload: number) {}
}

export class ResetAmountReducer implements Action {
  readonly type = AmountActionsTypes.Amount_Reset;
  constructor() {}
}

export class AmountSuccessful implements Action {
  readonly type = AmountActionsTypes.Amount_Successful;
  constructor(public payload: any) {}
}

export class AmountFailed implements Action {
  readonly type = AmountActionsTypes.Amount_Failure;
  constructor() {}
}

export type AmountActions = GetAmount | AmountSuccessful | AmountFailed;
