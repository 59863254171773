import { Injectable } from '@angular/core';

declare global {
  interface Window {
    Hansel: any;
    smartech: any;
  }
}
interface nudgesInterface {
  completeKyc: number | string;
  addContractDashboard: number | string;
  addParties: number | string;
  youPayReceive: number | string;
  addMoney: number | string;
  clickUpi: number | string;
}
@Injectable({
  providedIn: 'root',
})
export class HanselService {
  private nudges: nudgesInterface;
  constructor() {
    this.nudges = {
      completeKyc: 0,
      addContractDashboard: 1,
      addParties: 2,
      youPayReceive: 3,
      addMoney: 4,
      clickUpi: 5,
    };
  }
  registerPageBrowseNudge() {
    if (window.Hansel && window.smartech) {
      window.smartech('dispatch', 'page_browse', {
        pageURL: window.location.href,
      });
    }
  }
  registerNonKycNudge(isKycDone: boolean) {
    const lastTriggeredNudge = localStorage.getItem('nudgeComplete');
    if (!lastTriggeredNudge || (lastTriggeredNudge && lastTriggeredNudge !== this.nudges.completeKyc.toString())) {
      if (window.Hansel && window.smartech) {
        window.smartech('dispatch', 'complete_kyc', {
          isKycDone,
        });
        if (!isKycDone) {
          localStorage.setItem('nudgeComplete', this.nudges.completeKyc.toString());
        }
      }
    }
  }
  registerAddContractNudge(isKycDone: boolean) {
    const lastTriggeredNudge = localStorage.getItem('nudgeComplete');
    if (lastTriggeredNudge && lastTriggeredNudge === this.nudges.completeKyc.toString()) {
      if (window.Hansel) {
        window.smartech('dispatch', 'add_contract_dashboard', {
          isKycDone,
        });
        if (isKycDone) {
          localStorage.setItem('nudgeComplete', this.nudges.addContractDashboard.toString());
        }
      }
    }
  }
  registerAddPartiesNudge(isKycDone: boolean) {
    const lastTriggeredNudge = localStorage.getItem('nudgeComplete');
    if (lastTriggeredNudge && lastTriggeredNudge === this.nudges.addContractDashboard.toString()) {
      if (window.Hansel) {
        window.smartech('dispatch', 'add_parties', { isKycDone });

        if (isKycDone) {
          localStorage.setItem('nudgeComplete', this.nudges.addParties.toString());
        }
      }
    }
  }
  registerYouPayReceiveNudge(isKycDone: boolean) {
    const lastTriggeredNudge = localStorage.getItem('nudgeComplete');
    const validLastNudges = [this.nudges.addContractDashboard, this.nudges.addParties];
    if (lastTriggeredNudge && validLastNudges.find((nudge) => nudge.toString() === lastTriggeredNudge)) {
      if (window.Hansel) {
        window.smartech('dispatch', 'add_contract_you_pay_receive', { isKycDone });

        if (isKycDone) {
          localStorage.setItem('nudgeComplete', this.nudges.youPayReceive.toString());
        }
      }
    }
  }
  registerAddMoneyNudge(isKycDone: boolean) {
    const lastTriggeredNudge = localStorage.getItem('nudgeComplete');
    const validLastNudges = [this.nudges.addContractDashboard, this.nudges.addParties, this.nudges.youPayReceive];
    if (lastTriggeredNudge && validLastNudges.find((nudge) => nudge.toString() === lastTriggeredNudge)) {
      if (window.Hansel) {
        window.smartech('dispatch', 'add_money_dashboard', { isKycDone });

        if (isKycDone) {
          localStorage.setItem('nudgeComplete', this.nudges.addMoney.toString());
        }
      }
    }
  }
  registerClickUpiNudge(isKycDone: boolean) {
    const lastTriggeredNudge = localStorage.getItem('nudgeComplete');
    if (lastTriggeredNudge && lastTriggeredNudge === this.nudges.addMoney.toString()) {
      if (window.Hansel) {
        window.smartech('dispatch', 'add_money_click_upi', { isKycDone });

        if (isKycDone) {
          localStorage.setItem('nudgeComplete', this.nudges.clickUpi.toString());
        }
      }
    }
  }

  registerCustomEvent(event, params) {
    if (!window.smartech) {
      console.log('Hansel failed');
      return;
    }
    if (params && params.eventName) {
      window.smartech('dispatch', params?.eventName, {});
    } else {
      window.smartech('dispatch', event, {});
    }
  }
}
