import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ninja-radio',
  templateUrl: './ninja-radio.component.html',
  styleUrls: ['./ninja-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NinjaRadioComponent),
      multi: true
    }
  ]
})
export class NinjaRadioComponent implements OnInit, ControlValueAccessor {
  @Input() id;
  @Input() cssClass;
  @Input() label;
  @Input() name;
  @Input() dataList;
  @Input() disabled = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  value: any;
  onChange: any = () => { };
  onTouched: any = () => { };

  constructor() { }

  ngOnInit(): void { }

  radioChange(e) {
    this.onChange(e.target.value);
    this.change.emit({
      event: e
    });
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}

