import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
// import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TraceService } from '@sentry/angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NinjaSpinnerModule } from 'ninja-modules';
import { MaterialModule } from 'src/app/main/material/material.module';
import { environment } from 'src/environments/environment';
import { IonicModule, TecxprtLibModule } from 'tecxprt-lib';
import { StateModule } from '../store/Store.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IFrameComponent } from './iframes/collect-assure-iframe/iframe.component';
import { IFrameComponent as TrackIframe } from './iframes/ninja-track/iframe.component';
import { IFrameComponent as MandiNews } from './iframes/mandi-news/iframe.component';
import { IFrameComponent as MyBrand } from './iframes/my-branding/iframe.component';
import { OfflinePageModule } from './offline-page/offline-page.module';
import { OrderConfirmationModule } from './order-confirmation/order-confirmation.module';
import { AuthModule } from './_auth/auth.module';
import { NinjaWhatsappModule } from './_common/whatsapp/ninja-whatsapp.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.resourcePath, '.json');
}

@NgModule({
  declarations: [AppComponent, IFrameComponent, TrackIframe, MandiNews, MyBrand],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    NinjaSpinnerModule,
    NinjaWhatsappModule,
    OfflinePageModule,
    OrderConfirmationModule,
    StateModule,
    MatDialogModule,
    MatDatepickerModule,
    CarouselModule,
    MatNativeDateModule,
    MatBottomSheetModule,
    MaterialModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    TecxprtLibModule.forRoot({
      assetsPath: environment?.assetsPath,
      webVersion: environment?.webVersion,
    }),
    IonicModule,
    provideFirebaseApp(() => initializeApp(environment?.firebase)),
    // AngularFirestoreModule,
    provideAuth(() => getAuth()),
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
