import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoDigitDecimaNumberDirective } from '../two-digit-decima-number.directive';



@NgModule({
  declarations: [TwoDigitDecimaNumberDirective],
  imports: [
    CommonModule
  ],
  exports: [TwoDigitDecimaNumberDirective]
})
export class SharedLibModule { }
