import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { NinjaNotificationService, NinjaSpinnerService } from 'ninja-modules';
import { EMPTY } from 'rxjs';
import { StorageService } from 'src/app/_handlers/storage.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from 'src/app/_services/error-handling.service';
@Injectable({
  providedIn: 'root',
})
export class ChoosePartyService {
  private recentContactsUrl = environment.apiUrl.recentContacts;
  private workflow = environment.apiUrl.workflow;
  private http: HttpClient;
  private authData = this.storage.authData;
  private profile_service_url = environment.apiUrl.profile;
  resource: any;
  constructor(
    private handler: HttpBackend,
    private translate: TranslateService,
    private spinner: NinjaSpinnerService,
    private router: Router,
    private notify: NinjaNotificationService,
    private storage: StorageService,
    private error: ErrorHandlingService
  ) {
    this.http = new HttpClient(this.handler);
  }

  getRecentContacts(userId) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.recentContactsUrl}/${realmObj.realmIdentifier}/${userId}/party?pageNo=1&pageSize=5000`;
    return this.http
      .get(url, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  getPartiesES() {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    const payload = {
      input: {
        size: 5000,
        offset: 0,
        must: [
          {
            terms: {
              'Party.userId': [authData.systemUserId],
            },
          },
        ],
      },
    };
    let url = `${this.workflow}/${realmObj.realmIdentifier}/${authData.systemUserId}/v1/execution/service/run/fetch-party-es`;
    return this.http
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  postAddParty(payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = '';
    if (payload.input.partyId == null) {
      url = `${this.workflow}/${realmObj.realmIdentifier}/${authData?.userId}/v1/execution/service/run/save-party-service-config`;
    } else {
      url = `${this.workflow}/${realmObj.realmIdentifier}/${authData?.userId}/v1/execution/service/run/update-party-service-config`;
    }
    return this.http
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  postUpdateParty(payload) {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflow}/${realmObj.realmIdentifier}/${authData?.userId}/v1/execution/service/run/update-party-service-config`;
    return this.http
      .post(url, payload, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }
  getPartieDataForBadge(input) {
    const authData = this.storage.authData;
    let url = `${this.profile_service_url}/asgardUserRealmMap/existenceOfRealmForUser`;

    return this.http
      .post(
        url,
        {
          asgardUserIds: input,
          throwErrorIfEmpty: false,
        },
        {
          headers: {
            Authorization: `${authData?.token_type} ${authData?.token}`,
          },
        }
      )
      .pipe(
        map((m: any) => m),
        catchError(this.catchError.bind(this))
      );
  }

  catchError(error: HttpErrorResponse) {
    // this.spinner.end();
    // this.error(
    //   error?.status?.toString(),
    //   error?.error?.message || error?.message
    // ).then((res) => {
    //   if (res.isConfirmed) {
    //     this.router.navigate(["/app/content/dashboard"]);
    //   }
    // });
    // return EMPTY;
    this.error.catchErrorHandling(error);
  }

  // async error(title?: string, text?: string) {
  //   this.resource = await this.getResourceAsync();
  //   return this.notify.fire({
  //     icon: "error",
  //     type: "failure",
  //     title: this.resource?.errorMsg,
  //     text: this.resource?.somethingWentWrong, //text
  //     // showCancelButton: true,
  //     // showDenyButton: true,
  //     // denyButtonText: 'REPORT',
  //     // cancelButtonText: 'CANCEL',
  //     confirmButtonText: this.resource?.gotToHome,
  //   });
  // }
  async getResourceAsync() {
    return await this.translate.get('resource').toPromise();
  }
}
