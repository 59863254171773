import { HttpClient, HttpBackend, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppHttpService } from 'src/app/shared/services/app-http.service';
import { StorageService } from '../_handlers/storage.service';
import { NinjaSpinnerService } from 'ninja-modules';
import { ErrorHandlingService } from './error-handling.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TradeService {
  private qaContacts = environment.apiUrl.recentContacts;
  private workflowUrl = environment.apiUrl.workflow;
  private orderManagementUrl = environment.apiUrl.orderManagement;
  showReconcileDialog: Subject<boolean> = new Subject();
  private fintransUrl = environment.apiUrl.fintrans;

  constructor(
    private storage: StorageService,
    private appHttpService: AppHttpService,
    private http: HttpClient,
    private handler: HttpBackend,
    private spinner: NinjaSpinnerService,
    private error: ErrorHandlingService,
    private router: Router
  ) {}

  uploadTripImage(fileUpload, userId): Observable<any> {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/uploadFile/request?ignoreContentType=true`;
    return this.appHttpService.post(url, fileUpload);
  }

  addTrade(payload, userId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-add-trader-buy-service-config`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  gamificationAddTrade(payload, userId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/collect-assure-create-ast`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  addTradeV2(payload, userId) {
    let input = {
      ...payload.input,
      inputTemplate: 'trader-app-create-order_3',
      outputTemplate: 'trader-app-fetch-order_6',
    };
    payload = { ...payload, input };
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-add-trader-buy-service-config-v2`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getParty(userId, search) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.qaContacts}/${realmObj?.realmIdentifier}/${userId}/party?searchTerm=${search}`;
    return this.http.get(url).pipe(map((m: any) => m));
  }

  getOrderDetails(userId, requestBody) {
    const realmObj = this.storage.realmOwnerData;
    requestBody.input.secondPartyIds = requestBody.input.secondPartyIds ? requestBody.input.secondPartyIds : null;
    requestBody.input.ids = requestBody.input.ids ? requestBody.input.ids : null;
    requestBody.input.ownerIds = requestBody.input.ownerIds ? requestBody.input.ownerIds : null;
    requestBody.input.fromDate = requestBody.input.fromDate ? requestBody.input.fromDate : null;
    requestBody.input.toDate = requestBody.input.toDate ? requestBody.input.toDate : null;
    requestBody.input.skipSubTypes = requestBody.input.skipSubTypes ? requestBody.input.skipSubTypes : null;
    requestBody.input.orderSubTypes = requestBody.input.orderSubTypes ? requestBody.input.orderSubTypes : null;
    requestBody.input.outputTemplate = 'trader-app-fetch-order-filter_1';
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-order-listing-service-config`;
    return this.appHttpService.post(url, requestBody).pipe(map((m: any) => m));
  }

  getOrderData(userId, requestBody, isContractPG = false, isParentOrder = false) {
    if (isContractPG) {
      requestBody.input.outputTemplate = 'trader-app-fetch-order-filter_6';
    } else {
      requestBody.input.outputTemplate = 'trader-app-fetch-order-filter_1';
    }
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-order-listing-by-id`;
    if (isParentOrder) {
      url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-parent-order-listing-by-id`;
    }
    return this.appHttpService.post(url, requestBody).pipe(map((m: any) => m));
  }

  getTransactionDetails(userId, partyId, pageNumber, pageSize, paymentType?: string) {
    const realmObj = this.storage.realmOwnerData;
    const currentDate = new Date().toISOString().slice(0, 10);
    let url = `${this.fintransUrl}/${realmObj?.realmIdentifier}/${userId}/cashTransactions?fromDate=1970-01-01&toDate=${currentDate}&pageSize=${pageSize}&pageNo=${pageNumber}&latestFirst=true&otherUserId=${partyId}`;
    const payload = {
      input: {
        otherUserId: partyId,
        pageNo: pageNumber,
        pageSize: pageSize,
        sendExtraDetails: true,
      },
    };
    if (paymentType) {
      payload.input['paymentType'] = paymentType;
    }

    return this.appHttpService.get(url).pipe(
      map((m: any) => m),
      tap((res) => {
        {
          let transactionRecords = res.data;
          if (transactionRecords && transactionRecords.length > 0) {
            transactionRecords = [...transactionRecords].reverse();
            res.data = transactionRecords;
            localStorage.setItem('Transaction_Details_Current_Page', pageNumber);
            if (transactionRecords.length < pageSize) {
              localStorage.setItem('STOP_LOADMORE', 'true');
            }
          } else {
            localStorage.setItem('STOP_LOADMORE', 'true');
          }
        }
      })
    );
    // } else{
    //   return new Observable((observer)=>{
    //     observer.next({data:transactionDetails});
    //   });
    // }
  }

  getConsentDetails(userId, consentId) {
    let userIdent = userId == undefined ? 1 : userId;
    const realmObj = this.storage.realmOwnerData;
    //realm id hardcoded for the no auth flow
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userIdent}/v1/execution/service/runWithNoAuth/free-flow-trader-app-order-details-consent-new`;
    return this.appHttpService
      .post(url, {
        input: {
          consentId: consentId,
          outputTemplate: 'trader-app-fetch-order_6',
          inputTemplate: 'trader-app-create-order_3',
        },
      })
      .pipe(map((m: any) => m));
  }

  approveConsent(payload, userId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/runWithNoAuth/free-flow-update-consent-accept-order-new`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  approveConsentV2(payload, userId) {
    let input = {
      ...payload.input,
      inputTemplate: 'trader-app-create-order_3',
      outputTemplate: 'trader-app-fetch-order_6',
    };
    payload = { ...payload, input };
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/runWithNoAuth/free-flow-update-consent-accept-order-3`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  rejectConsent(payload, userId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/runWithNoAuth/free-flow-update-consent-service-reject-order`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getShortenURL(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/url-shortner`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  createConsent(userId, order, consentUserId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-create-consent-order`;
    return this.appHttpService
      .post(url, {
        input: {
          userId: consentUserId,
          order,
          inputTemplate: 'trader-app-create-order_1',
          outputTemplate: 'trader-app-fetch-order_1',
        },
      })
      .pipe(map((m: any) => m));
  }

  createConsentV2(userId, order, consentUserId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-create-consent-order-v2`;
    return this.appHttpService
      .post(url, {
        input: {
          userId: consentUserId,
          order,
          inputTemplate: 'trader-app-create-order_3',
          outputTemplate: 'trader-app-fetch-order_6',
        },
      })
      .pipe(map((m: any) => m));
  }

  createConsentWithoutParent(userId, order, consentUserId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/create-consent-order-without-parent`;
    return this.appHttpService
      .post(url, {
        input: {
          userId: consentUserId,
          order,
        },
      })
      .pipe(map((m: any) => m));
  }

  updateStatus(payload, userId) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-update-order-status`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getFilteredOrderDetails(userId, payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-order-listing-service-config-owner`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getAllOrderDetails(userId) {
    const realmObj = this.storage.realmOwnerData;
    if (this.storage.authData.userId) {
      let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/free-flow-order-listing-service-config`;
      return this.appHttpService
        .post(url, { input: { outputTemplate: 'trader-app-fetch-order-filter_1' } })
        .pipe(map((m: any) => m));
    }
  }

  getMetaData() {
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.orderManagementUrl}/${realmObj?.realmIdentifier}/${this.storage.authData?.userId}/metaData/fetch`;
    return this.http
      .get(url, {
        headers: {
          Authorization: `${authData?.token_type} ${authData?.token}`,
        },
      })
      .pipe(map((m: any) => m));
  }
  getCollectionAccount(userId, payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/create-collection-account`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  reconcileOrder(userId, payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/add-entity-transaction-map`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getReconcileTransactions(userId, payload) {
    const realmObj = this.storage.realmOwnerData;
    if (this.storage.authData.userId) {
      let url = `${this.workflowUrl}/${realmObj?.realmIdentifier}/${userId}/v1/execution/service/run/cashtransactions-v1`;
      return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
    }
  }

  triggerConsentApprovalOtp(userId, payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${userId}/v1/execution/service/runWithNoAuth/free-flow-consent-otp-notification-service-config`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  triggerConsentApprovalOtpContracts(userId, payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${userId}/v1/execution/service/runWithNoAuth/free-flow-consent-otp-notification-service-config-test`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  verifyConsentApprovalOtp(userId, payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${userId}/v1/execution/service/runWithNoAuth/free-flow-verify-otp`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  closeContract(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/free-flow-close-contract-request`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  updateCloseContract(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/free-flow-update-close-contract-request`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  updateCloseContractV2(payload) {
    let input = {
      ...payload.input,
      inputTemplate: 'trader-app-create-order_3',
      outputTemplate: 'trader-app-fetch-order_6',
    };
    payload = { ...payload, input };
    let httpClient = new HttpClient(this.handler);
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/pg-program-closure`;
    return httpClient
      .post(url, payload, {
        headers: {
          Authorization: `${this.storage.authData?.token_type} ${this.storage.authData?.token}`,
        },
      })
      .pipe(
        map((m: any) => m),
        catchError((error) => {
          this.spinner.end();
          //put speacial error for pg-program-closure api, refer PG-1281
          const errorMsg = error?.error?.data?.metadata?.error;
          const loanId = errorMsg.split(':')[1].substring(1, 4);

          if (errorMsg.startsWith('Current Loan status:') && loanId >= 100) {
            this.error
              .errorGeneric(
                error?.status?.toString(),
                'This contract can be closed only after the buyer has repaid their obligations to Ninjacart. Please contact your Ninja support representative for any issues'
              )
              .then((res) => {
                if (res.isConfirmed) {
                  this.router.navigate(['/app/content/dashboard']);
                }
              });
            return EMPTY;
          } else {
            return this.catchError(error);
          }
        })
      );
  }

  catchError(error: HttpErrorResponse) {
    this.error.catchErrorHandling(error);
    return EMPTY;
  }

  getConsentStatus(payload) {
    const realmObj = this.storage.realmOwnerData;
    let url = `${this.workflowUrl}/${realmObj.realmIdentifier}/${this.storage.authData?.userId}/v1/execution/service/run/free-flow-fetch-consent-service-config-new`;
    return this.appHttpService.post(url, payload).pipe(map((m: any) => m));
  }

  getContract(id: string | number) {
    const url = `${this.workflowUrl}/dummy/${this.storage.authData?.userId}/v1/execution/service/run/free-flow-order-listing-for-buyer-procedure`;
    const payload = {
      input: {
        ids: [id],
        ownerIds: [1],
        statusList: [8, 10, 11, 12, 13],
        outputTemplate: 'trader-app-fetch-order-filter_8',
      },
    };
    const headers = new HttpHeaders().set('x-nc-system-user-id', `${this.storage.authData?.systemUserId}`);
    return this.appHttpService.post(url, payload, { headers }).pipe(map((res: any) => res?.data));
  }
}
