import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { MyBrandingService } from 'src/app/_services/branding-auth.service';
import { StorageService } from 'src/app/_handlers/storage.service';
import { GlobalService } from '../../_handlers/global.service';
import { NinjaNotificationService } from 'ninja-modules';
@Component({
  selector: 'iframe-brand',
  templateUrl: './iframe.component.html',
})
export class IFrameComponent {
  myBrandUrl: SafeResourceUrl;
  payload: any;
  userId: any;
  relmIdentifier: any;

  constructor(
    private sanitize: DomSanitizer,
    public global: GlobalService,
    private router: Router,
    private myBrandingService: MyBrandingService,
    public _storage: StorageService,
    private notify: NinjaNotificationService
  ) {}

  ngOnInit() {
    let url = environment.brandLive.url;
    this.myBrandUrl = this.sanitize.bypassSecurityTrustResourceUrl(url);
    this.payload = {
      input: {
        phone_number: this._storage?.realmOwnerData?.contactNumber,
        name: this._storage?.realmOwnerData?.realmName,
      },
    };
    this.userId = this._storage?.authData?.userId;
    this.relmIdentifier = this._storage?.realmOwnerData?.realmIdentifier;
    window.addEventListener('message', this.handleNativeCall);
  }

  handleNativeCall(e) {
    const { data = {} } = e;
    const { action } = data;
    const [identifier, value] = JSON.parse(action);
    if (identifier === 'downloadAndShare') {
      window['Android']?.downloadTransactionDocs(value);

      window['Native']?.downloadTransactionDocs(value);
    }
  }

  afterLoading() {
    this.payload &&
      this.userId &&
      this.relmIdentifier &&
      this.myBrandingService.thirdParty(this.payload, this.relmIdentifier, this.userId).subscribe(
        (res) => {
          const {
            success,
            data: { token },
          } = res;
          if (success) {
            window.parent.frames['myBranding'].contentWindow.postMessage(
              JSON.stringify([token, this._storage?.realmOwnerData?.systemUserId]),
              '*'
            );
          }
        },
        (err) => {
          this.fireErrorPopup();
        }
      );
  }

  fireErrorPopup() {
    this.notify.fire({
      icon: 'error',
      type: 'failure',
      title: 'Error',
      text: 'Failed to load My Branding',
    });
    this.global.openMyBrandingApp$.next(false);
    this.router.navigate(['/app/content/dashboard']);
  }

  back() {
    this.global.openMyBrandingApp$.next(false);
    this.router.navigate(['/app/content/dashboard']);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.handleNativeCall);
  }
}
