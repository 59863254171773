import { Action } from '@ngrx/store';

export enum SellerBuyerInfoActionTypes {
  SetSellerBuyerInfo = 'SET [Seller Buyer Info]',
}

export class SetSellerBuyerInfo implements Action {
  readonly type = SellerBuyerInfoActionTypes.SetSellerBuyerInfo;
  constructor(public payload: any) {}
}

export type SellerBuyerInfoActions = SetSellerBuyerInfo;
