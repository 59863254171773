import { AccountDetailVM } from '../../app/_modals/accountDetail';
import { AddMoneyActionTypes } from '../actions/add-money.actions';

export interface AddMoneyInitialState {
  accountDetails: AccountDetailVM;
  isLoading: boolean;
  isFulfilled: boolean;
  isFailed: boolean;
  reloading: boolean;
}

export type AddMoneyState = AddMoneyInitialState;

const initialState: AddMoneyInitialState = {
  accountDetails: {} as AccountDetailVM,
  isFailed: false,
  isFulfilled: false,
  isLoading: false,
  reloading: false,
};

export const addMoneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddMoneyActionTypes.AccountDetail_Loading: {
      return {
        ...state,
        accountDetails: {},
        isLoading: true,
        isFulfilled: false,
        isFailed: false,
        reloading: false,
      };
    }
    case AddMoneyActionTypes.AccountDetail_Fulfilled: {
      return {
        ...state,
        accountDetails: action.payload,
        isLoading: false,
        isFulfilled: true,
        isFailed: false,
        reloading: false,
      };
    }
    case AddMoneyActionTypes.AccountDetail_Failed: {
      return {
        ...state,
        accountDetails: {},
        isLoading: false,
        isFulfilled: false,
        isFailed: true,
        reloading: false,
      };
    }
    case AddMoneyActionTypes.AccountDetail_Reload: {
      return {
        ...state,
        accountDetails: {},
        isLoading: false,
        isFulfilled: false,
        isFailed: false,
        reloading: true,
      };
    }
    default: {
      return state;
    }
  }
};
