import { ContactActionTypes } from '../actions/contact.actions';

export type ContactNumberState = string;

const initialState: string = '';

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case ContactActionTypes.SetContactNumber: {
      return action.contactNumber;
    }
    default: {
      return state;
    }
  }
};
