import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaUploadFileComponent } from './ninja-upload-file.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaUploadFileComponent],
  imports: [
    CommonModule
  ],
  exports: [NinjaUploadFileComponent]
})
export class NinjaUploadFileModule { }
