import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { AddMoneyService } from '../../app/_services/add-money.service';
import { AccountDetailFailed, AccountDetailFulfilled, AddMoneyActionTypes } from '../actions/add-money.actions';
import { AccountDetailVM } from '../../app/_modals/accountDetail';

@Injectable()
export class AddMoneyEffects {
  @Effect()
  loadAccountDetail$ = this.dataPersistence.fetch(AddMoneyActionTypes.AccountDetail_Loading, {
    run: (action: any, state) => {
      return this.addMoneyService.fetchAccountDetails().pipe(map((res) => new AccountDetailFulfilled(res?.data)));
    },
    onError: (action, error) => {
      return new AccountDetailFailed();
    },
  });
  constructor(private dataPersistence: DataPersistence<AccountDetailVM>, private addMoneyService: AddMoneyService) {}
}
