export const SPL_REQ_HANDLING = [
  'free-flow-consent-listing-service-config',
  'kyc-user-action-summary',
  'trader-wallet-info',
  'trader-todays-payment-collection',
  'fetchAndUpdateTransactionStatus',
  'collect-assure-get-user-cover-status',
  'get-cb-report-all-agencies',
  'credits?latestFirst=true&sendExtraDetails=true',
];
