import { Component, OnInit } from '@angular/core';
import { NinjaGridColumnDef } from 'ninja-modules';
import { CONTENT_PAGES, ContentPage } from '../main/content/content-pages.config';
import { GlobalService } from '../_handlers/global.service';
import { NinjaNotificationService, NinjaSpinnerService } from 'ninja-modules';
import { StorageService } from 'src/app/_handlers/storage.service';
import { TradeService } from 'src/app/_services/trade.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ReturnUrlFacade } from 'src/store/facades/returnUrl.facade';
import { TranslateService } from '@ngx-translate/core';
import { NinjaTabbarVM } from 'ninja-modules';
import { StringEx } from 'src/app/_utility/string-ex';
import { ImageViewerComponent } from '../main/content/image-viewer/image-viewer.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements OnInit {
  resource: any;
  data;
  showHeader = false;
  columnDefs: NinjaGridColumnDef[] = [
    { headerText: 'Item', fieldName: 'itemDisplayName' },
    { headerText: 'Qty', fieldName: 'quantity' },
    { headerText: 'Unit', fieldName: 'enteredUnit' },
    { headerText: 'Rate', fieldName: 'sellPrice' },
    { headerText: 'Amount (in ₹)', fieldName: 'amount' },
  ];
  orderDetails;
  normalFlow = true;
  consentResponseData = null;
  buyerName = '';
  orderTotal = '';
  orderDate = '';
  userId;
  otp;
  otpTrigerred = false;
  androidWeb: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private _global: GlobalService,
    private _spinner: NinjaSpinnerService,
    private _notify: NinjaNotificationService,
    private tradeService: TradeService,
    private storage: StorageService,
    private route: ActivatedRoute,
    private loc: Location,
    private returnUrlFacade: ReturnUrlFacade,
    public dialog: MatDialog
  ) {
    this.translate.get('resource')?.subscribe((resource) => (this.resource = resource));
    this.showHeader = history?.state?.showHeader;
    // this._global.contentHeaderSubject.next(CONTENT_PAGES[ContentPage.consents]);
    let pageConfig = CONTENT_PAGES[ContentPage.consents];
    pageConfig.text = this.resource?.consentTxt;
    this._global.contentHeaderSubject.next(pageConfig);
  }

  ngOnInit(): void {
    let orderDataParam;
    if (window && (window['Android'] || window['Native'])) {
      this.androidWeb = true;
    }
    if (!this.storage.authData?.token && this.androidWeb) {
      this.returnUrlFacade.setReturnUrl(this.loc.path());
      this.router.navigate([`/contact`]);
      return;
    }
    orderDataParam = JSON.parse(atob(this.route.snapshot.params?.d));
    this.userId = this.androidWeb ? this.storage.authData?.userId : orderDataParam?.buyerId;
    this.getData();
  }

  reject(consentStatus, orderStatus) {
    this._spinner.start();
    let paymentDate = {};
    let payload = {
      input: {
        consentStatus: consentStatus,
        orderStatus: orderStatus,
        consent: this.consentResponseData,
        order: this.data,
        inputTemplate: 'trader-app-create-order_1',
        outputTemplate: 'trader-app-fetch-order_1',
      },
    };
    this.tradeService
      .rejectConsent(
        payload,
        this.storage.authData?.userId == undefined ? this.consentResponseData.toUserId : this.storage.authData?.userId
      )
      .subscribe((res) => {
        this._spinner.end();
        this.showModal(this.resource?.consent + ' ' + consentStatus);
        if (!this.androidWeb) {
          window.setTimeout(() => {
            this.router.navigate(['/thank-you']);
          }, 1000);
        } else {
          window.setTimeout(() => {
            this.router.navigate(['/app/content/dashboard']);
          }, 1000);
        }
        // console.log('addTradeResponse', res);
      });
  }

  confirm() {
    this._spinner.start();
    const data = {
      input: {
        contactNumber: this.data.subType == 1 ? this.data.sellerContactNumber : this.data.buyerContactNumber,
        otp: StringEx.toNumber(this.otp),
      },
    };
    return this.tradeService.verifyConsentApprovalOtp(history?.state?.userId, data).subscribe((res) => {
      this._spinner.end();
      if (res?.success) {
        this.processConsentApproval();
      } else {
        this._notify.fire({ icon: 'error', type: 'failure', title: res?.errorCode, text: res?.errorMessage });
      }
    });
  }

  processConsentApproval() {
    let paymentDate = {};

    let partyData = {
      userId: null,
      name: null,
      contactNumber: null,
    };

    if (this.consentResponseData.fromUserId == this.data.sellerId) {
      partyData.userId = this.data.sellerId;
      partyData.name = this.data.sellerName;
      partyData.contactNumber = this.data.sellerContactNumber;
    } else {
      partyData.userId = this.data.buyerId;
      partyData.name = this.data.buyerName;
      partyData.contactNumber = this.data.buyerContactNumber;
    }

    let payload = {
      input: {
        consentStatus: 'APPROVED',
        orderStatus: 8,
        consent: this.consentResponseData,
        order: this.data,
        party: partyData,
        inputTemplate: 'trader-app-create-order_1',
        outputTemplate: 'trader-app-fetch-order_1',
        contactNumber: this.data.subType == 1 ? this.data.sellerContactNumber : this.data.buyerContactNumber,
        otp: this.otp,
      },
    };
    this.tradeService
      .approveConsent(
        payload,
        this.storage.authData?.systemUserId == undefined
          ? this.consentResponseData.toUserId
          : this.storage.authData?.systemUserId
      )
      .subscribe((res) => {
        this._spinner.end();
        this.showModal(this.resource?.consentApproved);
        this._spinner.end();
        this.showModal(this.resource?.consentApproved);
        if (!this.androidWeb) {
          window.setTimeout(() => {
            this.router.navigate(['/thank-you']);
          }, 1000);
        } else {
          window.setTimeout(() => {
            this.router.navigate(['/app/content/dashboard']);
          }, 1000);
        }
        // console.log('addTradeResponse', res);
      });
  }

  showModal(text) {
    this._notify
      .fire({
        icon: 'success',
        type: 'success',
        title: this.resource?.success,
        text: text,
        allowOutsideClick: false,
      })
      .then((res) => {
        if (res.isConfirmed) {
          //  this.router.navigate(['/app/content/to-collect'], { state: { partyId: this.selectedParty.partyUserId } });
        }
      });
  }

  showOrderConsentModal() {
    if (this.normalFlow) {
      // If there more than two orders child order is already present
      this._notify
        .fire({
          icon: 'warning',
          type: 'warning',
          title: this.resource?.warning,
          text: this.resource?.addTradeToAppNote,
          showCancelButton: true,
          confirmButtonText: this.resource?.yes,
          cancelButtonText: this.resource?.no,
          customClass: {
            confirmButton: 'btn-popup ninja-bg',
            cancelButton: 'btn-popup',
          },
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.confirm();
          } else {
            this.reject('APPROVED', 8);
          }
        });
    } else {
      this.reject('APPROVED', 8);
    }
  }

  viewUploadDocuments(item) {
    this.dialog.open(ImageViewerComponent, {
      maxWidth: '100vw',
      height: '100%',
      width: '100%',
      data: {
        imgUrl: item.value,
      },
    });
  }

  triggerOtp() {
    let payload = {
      input: {
        contactNumber: this.data.subType == 1 ? this.data.sellerContactNumber : this.data.buyerContactNumber,
      },
    };
    this.otpTrigerred = false;
    this._spinner.start();
    this.tradeService.triggerConsentApprovalOtp(this.userId, payload).subscribe((res) => {
      this.otpTrigerred = true;
      this.startOtpTimer();
      this._spinner.end();
    });
  }

  verifyOtp() {
    this.confirm();
  }

  getData() {
    this.normalFlow = true;
    this._spinner.start();
    this.tradeService.getConsentDetails(this.userId, this.route.snapshot.params?.id).subscribe((res) => {
      this._spinner.end();
      this.consentResponseData = res.data.consents[0];
      if (
        this.consentResponseData.consentStatus == 'APPROVED' ||
        this.consentResponseData.consentStatus == 'REJECTED'
      ) {
        this.router.navigate(['/thank-you']);
      }
      if (res.data.orders.length > 1) {
        this.normalFlow = false;
      }
      for (let i = 0; i < res.data.orders.length; i++) {
        if (res.data.orders[i].type == 1) {
          this.data = res.data.orders[i];
          if (this.data?.orderItems && this.data?.orderItems.length > 0) {
            this.data?.orderItems.forEach((m) => {
              if (m?.quantity && m?.sellPrice) m.amount = m.quantity * m.sellPrice;
            });
          }
          this.showViewMode(this.data, null);
          break;
        }
      }
      this.setOrderDetails();
    });
  }

  initialSeconds;
  startOtpTimer() {
    this.initialSeconds = 40;
    let $interval = setInterval(() => {
      if (this.initialSeconds > 0) {
        this.initialSeconds = this.initialSeconds - 1;
      } else {
        clearInterval($interval);
      }
    }, 1000);
  }

  setOrderDetails() {
    this.buyerName = this.data?.buyerName;
    this.orderTotal = this.data?.orderTotal;
    this.orderDate = this.data?.orderDate;
  }

  applyStatusStyling(checked: boolean) {
    return {
      'border-color': this.borderColor(checked),
      color: this.buttonTextColor(checked),
    };
  }

  borderColor(checked: boolean): String {
    if (this.selectedTab) {
      if (!checked) {
        return '#D3D3D3';
      } else if (checked) {
        return '#5FA744';
      }
    } else {
      return '#D3D3D3';
    }
  }

  buttonTextColor(checked: boolean): String {
    if (this.selectedTab) {
      if (!checked) {
        return '#808080';
      } else if (checked) {
        return '#5FA744';
      }
    } else {
      return '#808080';
    }
  }
  selectedTrade;
  viewTabDisable;

  tabberList: NinjaTabbarVM[] = [
    { text: 'Buying', active: false },
    { text: 'Selling', active: false },
  ];

  selectedTab: NinjaTabbarVM;
  sellingTypeChecked: boolean = false;
  selectedParty;
  transactionList = [];
  changeTraderType(sellingTypeChecked: boolean) {
    this.sellingTypeChecked = sellingTypeChecked;
    if (!this.sellingTypeChecked) {
      this.selectedTab = this.tabberList[0];
    } else {
      this.selectedTab = this.tabberList[1];
    }
  }
  viewMode = false;
  showViewMode(selectedTrade, cacheData) {
    this.selectedTrade = selectedTrade;
    const trade = selectedTrade;
    this.viewTabDisable = false;
    if (selectedTrade.subType == 2) {
      this.changeTraderType(false);
    } else {
      this.changeTraderType(true);
    }

    let partyName = trade.buyerName;
    if (selectedTrade.subType == 2) {
      partyName = trade.sellerName;
    }
    this.selectedParty = partyName;
    this.transactionList = cacheData?.transactionData;

    if (!cacheData) {
      this.setSkuTableData(trade.orderItems);
    }
    selectedTrade.paymentStatus = selectedTrade.paymentStatus + '';
    //  this.paymentStatusManual = trade?.paymentStatus + "";

    this.viewMode = true;

    // this.addTradeForm.controls['partyContactNew']?.disable();
    // this.addTradeForm.controls['businessName']?.disable();
    // this.addTradeForm.controls['amount']?.disable();
    // this.addTradeForm.controls['date']?.disable();

    this.selectedParty = partyName;
    // this.addTradeForm.disable();
  }
  adjustmentChargeList = [];
  setSkuTableData(orderItems) {
    orderItems.forEach((element) => {
      if (element.itemTypeId == 3) {
        let charge = {
          additionalText: element.itemName,
          value: element.unitSellPrice,
          buttonType: 'positive',
        };
        if (charge.value < 0) {
          charge.buttonType = 'negative';
          charge.value = Math.abs(charge.value);
        }
        this.adjustmentChargeList.push(charge);
      }
    });
  }
  isEdited;
  paymentStatusManual;
  getTooltipText(item) {}
}
