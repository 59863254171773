import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as DashboardActions from '../actions/Dashboard.actions';
import * as PartiesActions from '../actions/parties.actions';
import * as OrdersActions from '../actions/Orders.actions';
import * as TransactionsActions from '../actions/transactions.actions';
import * as AmountActions from '../actions/amount.actions';
import { AppState } from '../reducers/index';
import * as AuthActions from 'src/store/actions/auth.actions';
import * as GamificationActions from '../actions/Gamification.actions';
import { track } from '@ninjacart/nc-gamification-module-app';
import { ShowModal, CloseModal } from '../actions/Gamification.actions';
import * as PaymentModuleActions from '../actions/payment-modules.actions';
import * as QrCodeActions from '../actions/qrCode.actions';

@Injectable({ providedIn: 'root' })
export class StoreFacade {
  constructor(private store: Store<AppState>) {}
  dashboardInfo = this.store.select('dashboard');
  transactionsStore = this.store.select('transactions');
  ordersStore = this.store.select('orders');
  partiesStore = this.store.select('parties');
  amountStore = this.store.select('amountDetails');
  qrCodeStore = this.store.select('qrCode');
  loadDashboard(payload) {
    this.store.dispatch(new DashboardActions.LoadDashboard(payload));
  }

  loadOrders(payload) {
    this.store.dispatch(new OrdersActions.LoadOrders(payload));
  }

  loadTransactions(userId, payload) {
    this.store.dispatch(new TransactionsActions.GetTransactionsHistory(userId, payload));
  }

  loadParties(payload) {
    this.store.dispatch(new PartiesActions.PartiesLoading(payload));
  }

  reloadDashboard() {
    this.store.dispatch(new DashboardActions.ReloadDashboard());
  }

  reloadParties() {
    this.store.dispatch(new PartiesActions.ReloadParties());
  }

  reloadOrders() {
    this.store.dispatch(new OrdersActions.ReloadOrders());
  }

  reloadTransactions() {
    this.store.dispatch(new TransactionsActions.ReloadTransactions());
  }

  getAmount() {
    this.store.dispatch(new AmountActions.GetAmount());
  }

  resetStore() {
    this.store.dispatch(new AuthActions.Logout());
  }

  resetDashboard() {
    this.store.dispatch(new DashboardActions.ResetDashboard());
  }

  showPartyAccountDetails(flag: boolean, user: object) {
    this.store.dispatch(
      new PaymentModuleActions.ShowPartyAccountDetails({
        user,
        showPartyAccountDetails: flag,
      })
    );
  }
  hideGenericPay() {
    this.store.dispatch(new QrCodeActions.HideGenericPay());
  }
  triggerEvent(eventType, config) {
    //ENABLE GAMIFICATION AFTER JENKINS FIX
    this.store.dispatch(new GamificationActions.TriggerEvent(eventType, config));
    const result = track(eventType, config, { 'aws-events': true, event: false });

    if (result) {
      result
        .then((res: any) => {
          if (res && res.config) {
            this.store.dispatch(new ShowModal(res.config));
          } else {
            this.store.dispatch(new CloseModal());
          }
        })
        .catch(() => this.store.dispatch(new CloseModal()));
    }
  }
}
