import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TabActionType } from './data/view-models/ui-tabs.modal';
import * as _ from 'lodash';
import { MatTabGroup } from '@angular/material/tabs';
import { AppStorageService } from './shared/services/app-storage.service';
import { NinjaFormService } from "./ninja-form.service";
import { DataFilterService } from './data/services/data-filter.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NinjaSpinnerService } from '../ninja-spinner/ninja-spinner.service';
import { NinjaNotificationService } from '../ninja-notification/ninja-notification.service';

@Component({
  selector: 'ninja-form',
  templateUrl: './ninja-form.component.html',
  styleUrls: ['./ninja-form.component.scss']
})
export class NinjaFormComponent implements OnInit {
  @Input() tabIndex = 0;
  @Input() tabGroupRef: MatTabGroup;
  @Input() formConfig;
  @Input() formDataModel;
  @Input() formData;
  @Input() submitUrl;
  @Input() parentSubmit: boolean = false;
  @Input() showButtons: boolean = true;
  @Output() parentSubmitAsync: EventEmitter<any> = new EventEmitter();
  @Output() actions: EventEmitter<any> = new EventEmitter();
  @Output() formConfigChange: EventEmitter<any> = new EventEmitter();
  myForm: FormGroup;
  actionType = TabActionType;
  fileS3Link = null;
  tabLabel;

  constructor(private formBuilder: FormBuilder,
    private spinner: NinjaSpinnerService,
    private ninjaFormService: NinjaFormService,
    private storage: AppStorageService,
    private dataFilter: DataFilterService,
    private ninjaNotify: NinjaNotificationService) {
    this.myForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
  }

  selectedTabChange(event) {
    this.formConfigChange.emit(event);
  }

  ngOnChanges() {
    const formDefinition = this.formConfig?.payload?.formDefinition;
    if (formDefinition?.componentList?.length > 0) {
      formDefinition?.componentList.forEach(m => {
        if (m.binding) {
          const control = new FormControl();

          // binding value to the control
          const modalData = this.formData;
          const controlVal = _.get(modalData, m.displayBinding || m.binding);
          if (controlVal) {
            control.setValue(controlVal);
          }

          // set validators to the control
          let modalObj = this.dataFilter.getformDataModalObjBinding(m.binding, this.formDataModel);
          // set required validators
          if (modalObj?.required) {
            control.setValidators([Validators.required]);
            
          }
          // setting the numeric validators
          if (modalObj?.numeric) {
            control.setValidators([Validators.pattern(/^[0-9]*$/)]);
            
          }
          // setting the alphanumeric validators
          if (modalObj?.alphaNumeric) {
            control.setValidators([Validators.pattern(/^[a-zA-Z0-9]*$/)]);
            
          }
          // set compare validators 
          if (modalObj?.matchWith) {
            this.myForm.setValidators(this.matchWithValidator(m.binding, modalObj.matchWith));
          }

          // disable the control
          if (m.formElementDefinition?.mandatoryProperties?.disabled) {
            control.disable();
          }

          // logic for splitting form control key
          // let controlkey = this.dataFilter.getFormControlKeyByBinding(m.binding, this.formData);

          // add control to the formgroup
          this.myForm.addControl(m.binding, control);
        }
      });
    }
  }

  matchWithValidator(control: string, matchToControl: string) {
    return (form: FormGroup): ValidationErrors | null => {
      const controlValue: string = form.controls[control].value;
      const matchToControlValue: string = form.controls[matchToControl].value;
      if (controlValue?.toUpperCase() === matchToControlValue?.toUpperCase()) {
        return null;
      } else {
        return { notMatch: true }
      }
    }
  }

  getFileDetails(e) {
    if (e?.files?.length > 0) {
      this.uploadFile(e.files[0]);
    }
  }

  uploadFile(filePath) {
    const formData: FormData = new FormData();
    formData.append('file', filePath);
    this.spinner.start();
    this.ninjaFormService.uploadTripImage(formData).subscribe(response => {
      this.spinner.end();
      if (response.success) {
        this.success(`Successfully uploaded`);
        this.fileS3Link = response.data[0];
        const wizardPayload = this.storage.getWizardPayload() || {};
        let payload = {};
        const formValues = this.myForm.getRawValue();
        _.forIn(formValues, (v, key) => payload = _.set(wizardPayload, key, this.fileS3Link));
        this.storage.setWizardPayload(payload);
      } else {
        this.error(`Error ${response && response.status}`);
      }
    }, (error: HttpErrorResponse) => {
      this.spinner.end();
      this.error(`${error?.status} ${error?.statusText}`, `${error?.message}`);
    });
  }

  getTextDetails(e) {

  }

  changeRadio(e) {

  }

  onItemSelect(data) {
  }

  onActions(e, submitUrl) {
    this.actions.emit({
      submitUrl: submitUrl,
      payload: this.myForm.value
    });
  }

  onFormActions(e) {
  }

  onSubmit(e) {
    e.event.stopPropagation();

    this.myForm.markAllAsTouched();
    if (this.myForm.invalid) return;

    this.setWizardPayload();
    if (this.parentSubmit) {
      this.parentSubmitAsync.emit({
        event: e,
        data: this.storage.getWizardPayload(),
        submitUrl: this.submitUrl
      });
      return;
    }

    this.ninjaNotify.fire({
      title: 'CONFIRM',
      text: 'Are you sure to submit?',
      showCancelButton: true,
      confirmButtonText: 'CONFIRM',
      cancelButtonText: 'CANCEL'
    }).then(res => {
      if (res.isConfirmed) {
        this.spinner.start();
        this.ninjaFormService.genericSubmit(this.storage.getWizardPayload(), this.submitUrl).subscribe(response => {
          this.spinner.end();
          if (response.success) {
            this.success(`Successfully done`);
            this.storage.remove('wizardPayload');
            // sending response back to parent
            window.top.postMessage({ payload: this.storage.getWizardPayload() }, '*');
          } else {
            this.error(`Error ${response && response.status}`);
          }
        }, (error: HttpErrorResponse) => {
          this.spinner.end();
          this.error(`${error?.status} ${error?.statusText}`, `${error?.message}`).then(res => {
            if (res.isDenied) {
              // backend report issue
            }
          });
        });

      }
    });
  }

  onBack() {
    if (this.tabIndex > 0) {
      this.tabGroupRef.selectedIndex = this.tabIndex - 1;
    }
  }

  onNext() {
    this.myForm.markAllAsTouched();
    if (this.myForm.invalid) return;

    this.setWizardPayload();
    if ((this.tabIndex + 1) < this.tabGroupRef._allTabs.length) {
      this.tabGroupRef.selectedIndex = this.tabIndex + 1;
    }
  }

  setWizardPayload() {
    const wizardPayload = this.storage.getWizardPayload() || {};
    let payload = {};
    const formValues = this.myForm.getRawValue();
    _.forIn(formValues, (v, key) => {
      if (formValues && formValues[key]) {
        payload = _.set(wizardPayload, key, formValues[key]);
      } else {
        payload = _.set(wizardPayload, key, (this.formData && this.formData[key]));
      }
    });
    this.storage.setWizardPayload(payload);
  }

  error(title?: string, text?: string) {
    return this.ninjaNotify.fire({
      title: title,
      text: text,
      showCancelButton: true,
      showDenyButton: true,
      denyButtonText: 'REPORT',
      cancelButtonText: 'CANCEL',
      confirmButtonText: 'RETRY'
    });
  }

  success(title?: string, text?: string) {
    this.ninjaNotify.toast({
      icon: 'success',
      position: 'top',
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 3500
    });
  }
}