export class StringEx {
  static toNumber(value: string): number {
    let result = 0;
    if (value) {
      result = parseFloat(value);
    }
    return result;
  }

  static toJSONObject(value: string, reviver?: any): any {
    let result = null;
    if (value) {
      result = JSON.parse(value, reviver);
    }
    return result;
  }
}
