import { Action } from '@ngrx/store';

export enum DemandOtpActionTypes {
  SetIsDemandOTP = 'SetIsDemandOTP',
}

export class SetIsDemandOTP implements Action {
  readonly type = DemandOtpActionTypes.SetIsDemandOTP;
  constructor(public value: boolean) {}
}

export type DemandOtpActions = SetIsDemandOTP;
