import { QrCodeActionType, ShowQrCode, ShowQrAmountValidation } from '../actions/qrCode.actions';

const initialState = {
  showQrCode: false,
  showQrScanner: false,
  upiId: null,
  collectFrom: null,
  showQrAmountValidation: false,
  showGenericPay: false,
  showSelfTransfer: false,
};

export const qrCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case QrCodeActionType.Show_Qr_Code:
      return {
        ...state,
        showQrCode: true,
        upiId: action.upiId,
        collectFrom: action.collectFrom,
      };
    case QrCodeActionType.Hide_Qr_Code:
      return {
        ...state,
        showQrCode: false,
        upiId: null,
        collectFrom: null,
      };
    case QrCodeActionType.Show_Qr_Scanner:
      return {
        ...state,
        showQrScanner: true,
      };
    case QrCodeActionType.Hide_Qr_Scanner:
      return {
        ...state,
        showQrScanner: false,
        showQrAmountValidation: false,
      };
    case QrCodeActionType.Show_Qr_Amount_Validation:
      return {
        ...state,
        showQrAmountValidation: true,
      };
    case QrCodeActionType.Hide_Qr_Amount_Validation:
      return {
        ...state,
        showQrAmountValidation: false,
      };
    case QrCodeActionType.Show_Generic_Pay:
      return {
        ...state,
        showGenericPay: true,
      };
    case QrCodeActionType.Hide_Generic_Pay:
      return {
        ...state,
        showGenericPay: false,
      };
    case QrCodeActionType.Show_Self_Transfer:
      return {
        ...state,
        showSelfTransfer: true,
      };
    case QrCodeActionType.Hide_Self_Transfer:
      return {
        ...state,
        showSelfTransfer: false,
      };

    case QrCodeActionType.Reset_Qr_State:
      return initialState;
    default:
      return state;
  }
};
