import { Action } from '@ngrx/store';

export enum ReturnUrlActionTypes {
  SetReturnUrl = 'SetReturnUrl',
}

export class SetReturnUrl implements Action {
  readonly type = ReturnUrlActionTypes.SetReturnUrl;
  constructor(public url: string) {}
}

export type ReturnUrlActions = SetReturnUrl;
