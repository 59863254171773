import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaTabbarComponent } from './ninja-tabbar.component'



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaTabbarComponent],
  imports: [
    CommonModule
  ],
  exports: [NinjaTabbarComponent]
})
export class NinjaTabbarModule { }
