import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { TransactionsFailed, TransactionsSuccessful, TransactionActionTypes } from '../actions/transactions.actions';
import { UserService } from 'src/app/_services/user.service';
import { TransactionResponseVM } from 'src/app/_modals/transaction-response.modal';

@Injectable()
export class TransactionEffects {
  @Effect()
  getTtransactions$ = this.dataPersistence.fetch(TransactionActionTypes.Get_Transactions, {
    run: (action: any) => {
      return this._user
        .getTransHistory(action.userId, action.payload)
        .pipe(map((res: any) => new TransactionsSuccessful(res.data)));
    },
    onError: () => {
      return new TransactionsFailed();
    },
  });
  constructor(private dataPersistence: DataPersistence<TransactionResponseVM>, private _user: UserService) {}
}
