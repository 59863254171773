import { Action } from '@ngrx/store';
import { OnboardDashboardResponseVM } from '../../app/_modals/onboard-dashboard-response';
import { ContentPage } from '../../app/main/content/content-pages.config';

export enum DashboardActionTypes {
  Dashboard_Loading = 'Dashboard_Loading',
  Dashboard_Fulfilled = 'Dashboard_Fulfilled',
  Dashboard_Failed = 'Dashboard_Failed',
  Language = '[Selected] language',
  Contact = '[Contact] number',
  Reload_Dashboard = 'Reload_Dashboard',
  Reset_Dashboard = 'Reset_Dashboard',
  Is_CollectAssure = 'Is_CollectAssure',
  UserRealmDetails = 'User_Realm_Details',
}

export class DashboardFulfilled implements Action {
  readonly type = DashboardActionTypes.Dashboard_Fulfilled;
  constructor(public payload: OnboardDashboardResponseVM) {}
}

export class DashboardCollectAssure implements Action {
  readonly type = DashboardActionTypes.Is_CollectAssure;
  constructor(
    public payload: {
      isCollectAssure: boolean;
      collectAssureProduct?: string;
      isSellingTrade?: boolean;
      backNavSellingTrade?: boolean;
      sellingTradeData?: any;
      contractData?: any;
      placeholderToggle?: boolean;
      isKnowMyTrader?: boolean;
      knowMyTraderProduct?: string;
    }
  ) {}
}

export class LoadDashboard implements Action {
  readonly type = DashboardActionTypes.Dashboard_Loading;
  constructor(public payload: any) {}
}

export class DashboardFailed implements Action {
  readonly type = DashboardActionTypes.Dashboard_Failed;
  constructor() {}
}
export class Language implements Action {
  readonly type = DashboardActionTypes.Language;
  constructor(public payload: string) {}
}

export class UserRealmDetails implements Action {
  readonly type = DashboardActionTypes.UserRealmDetails;
  constructor(public payload: any) {}
}

export class Contact implements Action {
  readonly type = DashboardActionTypes.Contact;
  constructor(public payload: number) {}
}

export class ReloadDashboard implements Action {
  readonly type = DashboardActionTypes.Reload_Dashboard;
  constructor() {}
}

export class ResetDashboard implements Action {
  readonly type = DashboardActionTypes.Reset_Dashboard;
  constructor() {}
}
export type DashboardActions =
  | DashboardFulfilled
  | LoadDashboard
  | DashboardFailed
  | Language
  | ContentPage
  | ReloadDashboard
  | ResetDashboard
  | DashboardCollectAssure
  | UserRealmDetails;
