import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ninja-header',
  templateUrl: './ninja-header.component.html',
  styleUrls: ['./ninja-header.component.css']
})
export class NinjaHeaderComponent implements OnInit {

  @Input() id;
  @Input() label = '';
  @Input() toolbarStyle;
  @Input() backStyle;
  @Input() textStyle;
  @Input() showBack = false;
  @Output() back: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onback(e) {
    this.back.emit(e);
  }
}
