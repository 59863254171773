export interface UiTab {
    label: string
    index: number;
}

export enum TabActionType {
    cancel = 0,
    proceed = 1
}

export interface UiTabAction {
    actionType: TabActionType;
    tabIndex: number;
}