import { Action } from '@ngrx/store';

export enum ContactActionTypes {
  SetContactNumber = 'SetContactNumber',
}

export class SetContactNumber implements Action {
  readonly type = ContactActionTypes.SetContactNumber;
  constructor(public contactNumber: string) {}
}

export type ContactActions = SetContactNumber;
