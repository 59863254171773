import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NinjaSpinnerService, NinjaNotificationService, NinjaAlertOptions } from 'ninja-modules';
import { EMPTY, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { TraderEventType } from '../_config/event-stream.config';
import { StorageService } from '../_handlers/storage.service';
import { TraderEventVM } from '../_modals/event-stream';
import { AwsEventService } from '../_services/aws-event.service';
import { StoreFacade } from 'src/store/facades/store.facade';
import { TranslateService } from '@ngx-translate/core';
import { SPL_REQ_HANDLING } from '../_config/req-handler-config';
import { take, delay } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class AuthInterceptorService implements HttpInterceptor {
  resource: any;
  isSplHandling: boolean;
  counter: number = 0;

  constructor(
    private storage: StorageService,
    private translate: TranslateService,
    private spinner: NinjaSpinnerService,
    private notify: NinjaNotificationService,
    private _aws: AwsEventService,
    private router: Router,
    private storeFacade: StoreFacade
  ) {}
  splReqHandler(req) {
    let request = req.split('/');
    if (SPL_REQ_HANDLING.includes(request[request.length - 1])) {
      this.isSplHandling = true;
    } else {
      this.isSplHandling = false;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (
      req.url.includes('ap-south-1.aws.data.mongodb-api.com') ||
      req.url.includes('/accounts/') ||
      req.url.includes('/ninja-global/') ||
      req.url.includes('/profile-service/')
    )
      return next.handle(req);
    // if(req.url.includes("1648070/v1/execution/service/run")){
    //   req = req.clone({
    //     setHeaders: {
    //       Authorization: "Basic bWF5YW5rOmNyQHp5bUBydmVs",
    //     },
    //   });
    //   return next.handle(req)
    // }
    const authData = this.storage.authData;
    const realmObj = this.storage.realmOwnerData;
    let headers: any = {
      Accept: 'application/json, text/plain, */*',
    };
    if (this.storage.languageCode) {
      headers['language'] = this.storage.languageCode;
    }
    if (authData?.token) {
      headers.Authorization = `${authData?.token_type} ${authData?.token}`;
      headers['Auth-Type'] = 'token';
    }
    if (
      req.headers.keys().indexOf('Authorization') > -1 &&
      req.headers.get('Authorization') &&
      req.headers.get('Authorization').indexOf('Basic') > -1
    ) {
      headers.Authorization = req.headers.get('Authorization');
      headers['Auth-Type'] = '';
    }
    req = req.clone({
      setHeaders: headers,
    });

    if (req.headers.keys().indexOf('skip') > -1 && req.headers.get('skip')) return next.handle(req);

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        this.spinner.end();
        this.splReqHandler(req.url);
        if (err) {
          if (!this.isSplHandling) {
            if (err.status !== 0) {
              this._aws.triggerTraderEvent(
                TraderEventType.Generic_event,
                {
                  eventName: 'Error_popup',
                  refType1: 'PageUrl',
                  refType2: 'Api',
                  refType3: 'Status',
                  refType4: 'ErrorMessage',
                  refType5: 'RequestId',
                  refKey1: this.router.url,
                  refKey2: req?.url,
                  refKey3: JSON.stringify(err?.status),
                  refKey4: JSON.stringify(
                    err?.error?.message || err?.error?.data?.message || err?.error?.errorMessage || err?.message
                  ),
                  refKey5: JSON.stringify(err?.headers?.get('x-ninjacart-request-id')),
                },
                null
              );
            }
            if (err.status === 401) {
              this.error(err?.status?.toString(), `Your session expired, Please login again`, true).then((res) => {
                if (res.isConfirmed) {
                  this.storage.clear('authData');
                  this.router.navigate(['/agreement']);
                }
              });
            } else if (err.status === 400) {
              this.errorGeneric(
                err?.status?.toString(),
                err?.error?.message || err?.error?.data?.message || err?.error?.errorMessage || err?.message
              ).then((res) => {});
            } else if (err.status === 0) {
              return;
            }
            // else if (err.status === 499 && req.method === "GET") {
            //   if(this.counter++ >= 1) {
            //     this.counter = 0;
            //     this.error(
            //       err?.status?.toString(),
            //       `Something went wrong. Please try after sometime`
            //     ).then((res) => {
            //       if (res.isConfirmed) {
            //         this.router.navigate(['/app/content/dashboard']);
            //       }
            //     });
            //   } else {
            //     this.spinner.start();
            //     return throwError(() => err);
            //   }
            // }
            else {
              this.error(err?.status?.toString(), `Something went wrong. Please try after sometime`).then((res) => {
                if (res.isConfirmed) {
                  this.router.navigate(['/app/content/dashboard']);
                }
              });
            }
          }
        }
        this.triggerErrorEvent(err, req);
        return EMPTY;
      })
      // retryWhen((errors) => errors.pipe(delay(500), take(2)))
    );
  }

  async error(title?: string, text?: string, redirectToLogin: boolean = false) {
    this.spinner.end();
    this.resource = await this.getResourceAsync();
    let options: NinjaAlertOptions = {
      icon: 'error',
      type: 'failure',
      title: this.resource?.errorMsg,
      text: text || this.resource?.somethingWentWrong,
      allowOutsideClick: false,
    };
    if (redirectToLogin) {
      options.confirmButtonText = this.resource?.loginHere;
      this.storeFacade.resetStore();
    } else {
      this.storeFacade.resetDashboard();
      if (this.router.url !== '/app/content/dashboard') {
        options.confirmButtonText = this.resource?.gotToHome;
      } else {
        options.confirmButtonText = this.resource?.ok;
      }
    }
    return this.notify.fire(options, false);
  }

  async errorGeneric(title?: string, text?: string, redirectToLogin: boolean = false) {
    this.resource = await this.getResourceAsync();
    let options: NinjaAlertOptions = {
      icon: 'error',
      type: 'failure',
      title: this.resource?.errorMsg,
      text: text || this.resource?.somethingWentWrong,
    };
    if (redirectToLogin) {
      options.confirmButtonText = this.resource?.loginHere;
      this.storeFacade.resetStore();
    } else {
      this.storeFacade.resetDashboard();
      if (this.router.url !== '/app/content/dashboard') {
        options.confirmButtonText = this.resource?.gotToHome;
      } else {
        options.confirmButtonText = this.resource?.ok;
      }
    }
    return this.notify.fire(options, false);
  }

  triggerErrorEvent(error: HttpErrorResponse, req: HttpRequest<any>) {
    let errorObj = {
      // userId: urlParam && urlParam.a,
      errorMessage: error && error.message,
      requestBody: req.body,
      requestUrl: req.url,
      requestMethod: req.method,
      responseStatus: error.status,
      responseTime: new Date(Date.now()).getTime(),
      responseBody: error.error,
      errorType: 'SERVER',
    };
    this._aws.modal = this._aws.modal || ({} as TraderEventVM);
    this._aws.triggerTraderEvent(TraderEventType.ServerError, JSON.stringify(errorObj), null);
  }

  async getResourceAsync() {
    return await this.translate.get('resource').toPromise();
  }
}
