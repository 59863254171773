import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderConfirmationComponent } from './order-confirmation.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NinjaGridModule, NinjaTextBoxModule } from 'ninja-modules';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
const routes: Routes = [{ path: '', component: OrderConfirmationComponent }];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [OrderConfirmationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NinjaTextBoxModule,
    NinjaGridModule,
    MatTooltipModule,
    MatIconModule,
  ],
})
export class OrderConfirmationModule {}
