import { OrderActionTypes } from '../actions/Orders.actions';

interface OrderDocuments {
  id: number;
  orderId: number;
  type: string;
  value: string;
  description: string;
}

interface OrderStatusTrackerDTO {
  orderId: number;
  statusTypeId: number;
  statusTypeText: string;
  statusDateTime: string;
  statusModifiedBy: number;
  statusComment: string;
  active: boolean;
}
export interface Order {
  id: number;
  orderId: number;
  cartId: any;
  sellerId: number;
  buyerId: number;
  sellerName: string;
  buyerName: string;
  pickUpAddress: any;
  deliveryAddress: any;
  orderDate: string;
  deliveryDate: any;
  paymentDate: string;
  paymentModeId: any;
  deliveryCharge: any;
  orderTotal: number;
  discountCode: any;
  status: number;
  type: number;
  subType: number;
  orderChannelId: any;
  costCenterId: any;
  consignmentId: any;
  outstandingAmount: number;
  refType: string;
  refId: number;
  facilityId: any;
  ownerId: number;
  consentId: number;
  orderAdditionalInfoId: number;
  invoiceImageUrl: any;
  invoiceImageUploadedAt: any;
  invoiceImageUploadedBy: any;
  destinationFacilityId: any;
  otpStatus: any;
  groupingType: any;
  sellShootQuantity: any;
  sellShootPrice: any;
  destinationCommissionAmount: any;
  realmId: string;
  sellerContactNumber: string;
  buyerContactNumber: string;
  dontDeleteOtherItems: boolean;
  persistSkuName: boolean;
  orderItems: [];
  paymentDTO: any;
  orderDocuments: OrderDocuments[];
  orderStatusTrackerDTOS: OrderStatusTrackerDTO[];
  statusText: string;
  paymentStatus: number;
  paymentStatusText: string;
}

const initialState = {
  orders: [],
  isSuccess: false,
  isFailure: false,
  isLoading: false,
  selectedTrade: {},
  reloadOrders: false,
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrderActionTypes.Orders_Loading:
      return {
        orders: [],
        isSuccess: false,
        isFailure: false,
        isLoading: true,
        reloadOrders: false,
      };
    case OrderActionTypes.Orders_Successful:
      return {
        orders: action.payload,
        isSuccess: true,
        isFailure: false,
        isLoading: false,
        reloadOrders: false,
      };
    case OrderActionTypes.Orders_Failed:
      return {
        orders: [],
        isSuccess: false,
        isFailure: true,
        isLoading: false,
        reloadOrders: false,
      };
    case OrderActionTypes.Selected_Order:
      return {
        ...state,
        selectedTrade: action.payload,
      };
    case OrderActionTypes.ReloadOrders:
      return {
        ...state,
        reloadOrders: true,
      };
    default:
      return state;
  }
};
