import { Pipe, PipeTransform } from '@angular/core';
import { set } from 'lodash';

@Pipe({
  name: 'formConfig'
})
export class FormConfigPipe implements PipeTransform {

  transform(value: any): any {
    const result = set({}, 'payload.formDefinition', value);
    return result;
  }

}
