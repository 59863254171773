import { Action } from '@ngrx/store';
export enum TransactionActionTypes {
  Get_Transactions = 'Get_Transactions',
  Transactions_Successful = 'Transactions_Successful',
  Transactions_Failed = 'Transactions_Failed',
  Set_Selected_Transaction = 'Set_Selected_Transaction',
  Reload_Transactions = 'Reload_Transactions',
}

export class GetTransactionsHistory implements Action {
  readonly type = TransactionActionTypes.Get_Transactions;
  constructor(public userId: any, public payload: any) {}
}

export class TransactionsSuccessful implements Action {
  readonly type = TransactionActionTypes.Transactions_Successful;
  constructor(public payload: any) {}
}

export class TransactionsFailed implements Action {
  readonly type = TransactionActionTypes.Transactions_Failed;
  constructor() {}
}

export class SetSelectedTransaction implements Action {
  readonly type = TransactionActionTypes.Set_Selected_Transaction;
  constructor(public payload: any) {}
}

export class ReloadTransactions implements Action {
  readonly type = TransactionActionTypes.Reload_Transactions;
  constructor() {}
}
export type TransactionTypes =
  | GetTransactionsHistory
  | TransactionsSuccessful
  | TransactionsFailed
  | SetSelectedTransaction
  | ReloadTransactions;
