export enum SplitIo {
  COLLECT_PLUS = 'CollectPlus',
  GENERIC_PAY = 'GenericPay',
  MULTI_BANK = 'multibank',
  ConnectedBanking = 'ConnectedBanking',
  CollectAssureCPV = 'CollectAssureCPV',
  MultiBanking = 'MultiBanking',
  PAY_LATER = 'PayLater',
  KnowYourCibilScore = 'KnowYourCibilScore',
  DefalutersSegment = 'Defaulter',
  PrimaryVanTxnTagging = 'PrimaryVanTxnTagging',
  CBAccountUnLink = 'CBAccountUnLink',
  FIREBASE = 'Firebase',
  CrifQuestionerFlowForKyc = 'CrifQuestionerFlowForKyc',
  shareMainVan = 'shareMainVan',
  SoundboxSettings = 'SoundboxSettings',
  KycBusinessPanFlow = 'KycBusinessPanFlow',
  VehicleTrack = 'VehicleTrack',
  userSubUserFlow = 'userSubUserFlow',
  PgFeesException = 'PgFeesException',
  CAGamification = 'CAGamification',
  PGEnachBlocker = 'PGEnachBlocker',
  newKycDocUplodeFlow = 'newKycDocUplodeFlow',
  KnowYourTrader = 'KnowYourTrader',
  Mandi_News = 'Mandi_News',
  My_Branding = 'MyBranding',
  IS_SHOW_PG_CONTRACT_BTN = 'is_show_pg_contract_btn',
  showCollectionLink = 'showCollectionLink',
  allowNonKycWithdraw = 'allowNonKycWithdraw',
  Show_my_qr_split = 'Show_my_qr_split',
  PartiesTierBadge = 'PartiesTierBadge',
}

export enum SplitIoTreatmentStatus {
  ON = 'on',
  OFF = 'off',
  CONTROL = 'control',
}
