import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjaWidgetsComponent } from './ninja-widgets.component';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NinjaWidgetsComponent],
  imports: [
    CommonModule
  ],
  exports: [NinjaWidgetsComponent]
})
export class NinjaWidgetsModule { }
